// Vendors
import React from 'react';
// Components
import { FinancingInsurancesContractingLPIAdditionalComponent } from '../additional/financing-insurances-contracting-lpi-additional.component';
import { FinancingInsurancesContractingLPIStep2PlansComponent } from './components/plans/financing-insurances-contracting-lpi-step2-plans.component';
import { FinancingInsurancesHiringLegalComponent } from 'containers/financing/views/ppi/components/hiring-legal/financing-insurances-hiring-legal.component';
import { FinancingInsurancesContractingLPIBenefitsComponent } from '../benefits/financing-insurances-contracting-lpi-benefits.component';
import WizardStepFooterComponent from 'components/wizard-step-footer/wizard-step-footer.component';
// Constants
import { FINANCING_CONTRACTING_LPI_COMMON_CANCEL_PROPS } from '../../constants/financing-insurances-contracting-lpi.constants';
// Hooks
import { useFinancingInsurancesContractingLPIStep2 } from './hooks/financing-insurances-contracting-lpi-step2.hook';
// Styles
import { LayoutColumnFlexComponentStyled } from 'styles/app.styled';

const FinancingInsurancesContractingLPIStep2Component = (): React.ReactElement => {
  const { error, handleNextButtonClick } = useFinancingInsurancesContractingLPIStep2();

  return (
    <LayoutColumnFlexComponentStyled>
      <FinancingInsurancesContractingLPIBenefitsComponent />
      <FinancingInsurancesContractingLPIAdditionalComponent />
      <FinancingInsurancesContractingLPIStep2PlansComponent error={error} />
      <FinancingInsurancesHiringLegalComponent />
      <WizardStepFooterComponent
        {...FINANCING_CONTRACTING_LPI_COMMON_CANCEL_PROPS}
        rightButtonConfiguration={{ onClick: handleNextButtonClick }}
      />
    </LayoutColumnFlexComponentStyled>
  );
};

export { FinancingInsurancesContractingLPIStep2Component };
