// Vendors
import { useState } from 'react';
// Handlers
import { FinancingInsurancesContractingLPIStep4Handlers } from '../handlers/financing-insurances-contracting-lpi-step4.handler';
import { FinancingInsurancesContractingLPIStep4TrackingHandlers } from '../handlers/financing-insurances-contracting-lpi-step4.handler.tracking';
// Hooks
import { useInsurancesContextConsumerHook } from 'contexts/insurances/insurances.context';
import { useFinancingInsurancesContractingLPIContextConsumerHook } from 'containers/financing/views/ppi/components/contracting/lpi/contexts/financing-insurances-contracting-lpi.context';
import { useFinancingPPIContextConsumerHook } from 'containers/financing/views/ppi/contexts/financing-ppi.context';
import { useWizardFeedbackContextConsumerHook } from 'components/wizard-feedback/contexts/wizard-feedback.context';
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { UseFinancingInsurancesContractingLpiStep4ReturnType } from './types/financing-insurances-contracting-lpi-step4-return.hooks.type';
import { ChallengeType } from 'types/challenge.type';

const useFinancingInsurancesContractingLpiStep4 =
  (): UseFinancingInsurancesContractingLpiStep4ReturnType => {
    const [fetching, setFetching] = useState<boolean>(false);
    const [downloaded, setDownloaded] = useState<boolean>(false);
    const [downloadedError, setDownloadedError] = useState<boolean>(false);

    const [challenge, setChallenge] = useState<ChallengeType>();
    const [challengeError, setChallengeError] = useState<boolean>(false);

    const { plan, postalDocumentation, setPlan, setPostalDocumentation } =
      useFinancingInsurancesContractingLPIContextConsumerHook();

    const { setFetchPPI } = useFinancingPPIContextConsumerHook();
    const { insurancesInfo } = useInsurancesContextConsumerHook();
    const { setResult } = useWizardFeedbackContextConsumerHook();
    const insuranceDetail = insurancesInfo?.insurances?.LPI;
    const { productId, productType } = insuranceDetail || {};

    return {
      ...FinancingInsurancesContractingLPIStep4Handlers({
        ...AppTrackingHook(FinancingInsurancesContractingLPIStep4TrackingHandlers),
        challenge,
        downloaded,
        downloadedError,
        plan,
        postalDocumentation,
        productContractId: productId,
        productContractType: productType,
        setChallengeError,
        setDownloaded,
        setDownloadedError,
        setFetchPPI,
        setFetching,
        setResult,
        setPlan,
        setPostalDocumentation,
      }),
      challenge,
      challengeError,
      downloaded,
      downloadedError,
      fetching,
      setChallenge,
      setChallengeError,
      postalDocumentation,
    };
  };

export { useFinancingInsurancesContractingLpiStep4 };
