// Vendors
import styled from 'styled-components';

const FinancingInsurancesContractingLPIBenefitsComponentWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingXxs};
`;

const BenefitContainerStyled = styled.div``;

export { FinancingInsurancesContractingLPIBenefitsComponentWrapperStyled, BenefitContainerStyled };
