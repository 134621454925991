// Vendors
import React from 'react';
// Components
import { PersonalUploadDocumentationOrchestrator } from './components/orchestrator/personal-upload-documentation-orchestrator';
import { PersonalUploadDocumentsSidebarComponent } from './components/sidebar/personal-upload-documentation-sidebar.component';
// Hooks
import { usePersonalUploadDocumentationHook } from './hooks/personal-upload-documentation.hook';
// Styled
import {
  LayoutGridComponentStyled,
  LayoutGridContentComponentStyled,
  LayoutGridContentLeftComponentStyled,
  LayoutGridContentRightComponentStyled,
} from 'styles/app.styled';

const PersonalUploadDocumentationView = (): React.ReactElement => {
  const { flowId, setFlowId } = usePersonalUploadDocumentationHook();

  return (
    <LayoutGridComponentStyled>
      <LayoutGridContentComponentStyled>
        <LayoutGridContentLeftComponentStyled>
          <PersonalUploadDocumentationOrchestrator {...{ flowId, setFlowId }} />
        </LayoutGridContentLeftComponentStyled>
        <LayoutGridContentRightComponentStyled>
          <PersonalUploadDocumentsSidebarComponent />
        </LayoutGridContentRightComponentStyled>
      </LayoutGridContentComponentStyled>
    </LayoutGridComponentStyled>
  );
};

export default PersonalUploadDocumentationView;
