// Resolvers
import { getCountryConfigurationResolver } from 'resolvers/country/country.resolvers';
// Types
import {
  TravelPlusLinksBuilderHandlersType,
  TravelPlusLinksInstanceBuilderHandlersType,
  TravelPlusLinksMethodBuilderHandlersType,
  TravelPlusLinksMethodsHandlersType,
} from './types/travel-plus-links.handlers.type';
import { TravelPlusLinksType } from '../hooks/types/travel-plus-links.type';

const getTravelPlusLinksHandler = async ({
  countryOfResidence,
  setTravelPlusLinks,
}: Pick<
  TravelPlusLinksBuilderHandlersType,
  'countryOfResidence' | 'setTravelPlusLinks'
>): Promise<void> => {
  const [response] = await getCountryConfigurationResolver(countryOfResidence);

  if (response?.config) {
    setTravelPlusLinks(response.config as TravelPlusLinksType);
  }
};

const openTravelPlusModalLinkClickHandler = ({
  formatMessage,
  handleOpenTravelPlusModalLinkClickTracking,
  id,
  text,
  travelPlusLinks,
}: TravelPlusLinksMethodBuilderHandlersType): void => {
  const url = travelPlusLinks?.[id] || '';

  handleOpenTravelPlusModalLinkClickTracking(formatMessage({ id: text }));

  url && window.open(url);
};

const TravelPlusLinksHandlers = (
  props: TravelPlusLinksBuilderHandlersType
): TravelPlusLinksMethodsHandlersType => ({
  handleGetTravelPlusLinks: () => getTravelPlusLinksHandler({ ...props }),
  handleOpenTravelPlusModalLinkClick: (methodProps: TravelPlusLinksInstanceBuilderHandlersType) =>
    openTravelPlusModalLinkClickHandler({ ...props, ...methodProps }),
});

export { TravelPlusLinksHandlers };
