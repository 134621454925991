// Constants
import appConstants from 'constants/app.constants';
// Types
import { FinancingInsurancesContractingLPIStep3AdditionalHandlersType } from './types/financing-insurances-contracting-lpi-step3-additional.handlers.type';
import { FinancingInsurancesContractingLPIStep3AdditionalReturnHandlersType } from './types/financing-insurances-contracting-lpi-step3-additional-return.handlers.type';

const amazonLinkClickHandler = ({
  handleAmazonLinkClickTracking,
}: FinancingInsurancesContractingLPIStep3AdditionalHandlersType): void => {
  handleAmazonLinkClickTracking();
  window.open(appConstants.AMAZON_PUBLIC_SITE, '_blank');
};

export const FinancingInsurancesContractingLPIStep3AdditionalHandlers = (
  props: FinancingInsurancesContractingLPIStep3AdditionalHandlersType
): FinancingInsurancesContractingLPIStep3AdditionalReturnHandlersType => ({
  handleAmazonLinkClick: () => amazonLinkClickHandler(props),
});
