// Constants
import constants from './constants/dashboard.resolver.utils.constants';
// Types
import { DashboardComponentType } from '../../types/dashboard.component.type';

const complexObjectAssig = <T extends object, K>(target: T, source: K): T & K => {
  const entries = Object.entries(target).map(([key, value]) => {
    if (source?.[key]) {
      return [key, source?.[key]];
    }
    return [key, value];
  });

  return Object.fromEntries(entries);
};

const generateBasicDashboardSkeleton = (
  response: { resource: DashboardComponentType } | Record<string, any>
): DashboardComponentType =>
  complexObjectAssig(constants.DEFAULT_DASHBOARD_INFORMATION, response?.resource || {});

export { generateBasicDashboardSkeleton };
