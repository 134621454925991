// Vendors
import React from 'react';
// Components
import { TitleSectionMobileComponent } from './components/mobile/title-section-mobile.component';
import TitleSectionRwdComponent from './components/rwd/title-section-rwd.component';
// Hooks
import { useIsSecondLevelRoutePathAndMobileHook } from './hooks/title-section.hook';
// Types
import { TitleSectionRwdComponentType } from './components/rwd/types/title-section-rwd.component.type';

const TitleSectionComponent = ({
  showBreadcrumbs = true,
  assetConfiguration,
  leftArrowClick,
}: TitleSectionRwdComponentType): React.ReactElement =>
  useIsSecondLevelRoutePathAndMobileHook() ? (
    <TitleSectionMobileComponent {...{ leftArrowClick }} />
  ) : (
    <TitleSectionRwdComponent {...{ assetConfiguration, showBreadcrumbs }} />
  );

export default TitleSectionComponent;
