// Vendors
import React from 'react';
// Components
import { AddressModalInputsSearchComponent } from '../search/address-modal-inputs-search.component';
import { AddressModalInputsStreetComponentType } from './types/address-modal-inputs-street.component.type';
// Constants
import {
  STREET_INPUT_PROPS,
  STREET_PROPS,
} from './constants/address-modal-inputs-street.constants';
// Hooks
import { useAddressModalInputsStreetHook } from './hooks/address-modal-inputs-street.hook';

export const AddressModalInputsStreetComponent = ({
  control,
  country,
  setValue,
  ...rest
}: AddressModalInputsStreetComponentType): React.ReactElement => {
  const { isLoading, onElementClick, options } = useAddressModalInputsStreetHook({
    control,
    country,
    setValue,
  });

  return (
    <AddressModalInputsSearchComponent
      {...rest}
      {...STREET_PROPS}
      control={control}
      inputProps={{ ...STREET_INPUT_PROPS, isLoading, options, onElementClick }}
      setValue={setValue}
    />
  );
};
