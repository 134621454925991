// Vendors
import { createTagAndsDispatchFile } from '@openbank/cf-ui-framework';
import { messageConstants } from '@openbank/cb-ui-commons';
// Constants
import { CONTRACT_FILENAME } from './constants/financing-insurances-contracting-lpi-step4-download.handlers.constants';
import {
  DOWNLOAD_CONTRACT_ERROR_EVENT_ACTION,
  DOWNLOAD_CONTRACT_SUCCESS_EVENT_ACTION,
} from './constants/financing-insurances-contracting-lpi-step4-download.handlers.tracking.constants';
import { INSURANCE_TYPE } from 'containers/financing/views/ppi/components/contracting/lpi/constants/financing-insurances-contracting-lpi.constants';
// Resolvers
import { getInsuranceContractResolver } from 'containers/financing/views/ppi/resolvers/financing-insurances.resolvers';
// Translations
import { DOWNLOAD_DOCUMENT_ERROR_MESSAGE } from 'containers/financing/views/ppi/components/contracting/lpi/components/step3/components/download/translations/financing-insurances-contracting-lpi-step3-download.translations';
import { DOWNLOAD_CONTRACT_SUCCESS_MESSAGE } from '../translations/financing-insurances-contracting-lpi-step4-download.translations';
// Types
import { FinancingInsurancesContractingLPIStep4DownloadHandlersType } from './types/financing-insurances-contracting-lpi-step4-download.handlers.type';
import { FinancingInsurancesContractingLPIStep4DownloadReturnHandlersType } from './types/financing-insurances-contracting-lpi-step4-download-return.handlers.type';

const downloadLinkClickHandler = async ({
  handleCloseDownloadToastTracking,
  handleDownloadLinkClickTracking,
  handleDownloadToastTracking,
  handleToggleDownloaded,
  plan,
  productId,
  productType,
  setDownloading,
  setToastConfiguration,
}: FinancingInsurancesContractingLPIStep4DownloadHandlersType): Promise<void> => {
  try {
    handleDownloadLinkClickTracking();
    setDownloading(true);

    if (!productId || !productType) {
      throw new Error();
    }

    const [blob, error] = await getInsuranceContractResolver({
      insuranceType: INSURANCE_TYPE,
      planCode: plan,
      productContractId: productId,
      productContractType: productType,
    });

    if (error || !blob) {
      throw new Error();
    }

    createTagAndsDispatchFile({ blob, filename: CONTRACT_FILENAME });
    handleToggleDownloaded();
    handleDownloadToastTracking(DOWNLOAD_CONTRACT_SUCCESS_EVENT_ACTION);
    setToastConfiguration({
      type: messageConstants.TYPES.SUCCESS,
      description: DOWNLOAD_CONTRACT_SUCCESS_MESSAGE,
      onClose: () => handleCloseDownloadToastTracking(DOWNLOAD_CONTRACT_SUCCESS_EVENT_ACTION),
    });
  } catch (_) {
    handleDownloadToastTracking(DOWNLOAD_CONTRACT_ERROR_EVENT_ACTION);
    setToastConfiguration({
      type: messageConstants.TYPES.ERROR,
      description: DOWNLOAD_DOCUMENT_ERROR_MESSAGE,
      onClose: () => handleCloseDownloadToastTracking(DOWNLOAD_CONTRACT_ERROR_EVENT_ACTION),
    });
  } finally {
    setDownloading(false);
  }
};

export const FinancingInsurancesContractingLPIStep4DownloadHandlers = (
  props: FinancingInsurancesContractingLPIStep4DownloadHandlersType
): FinancingInsurancesContractingLPIStep4DownloadReturnHandlersType => ({
  handleDownloadLinkClick: () => downloadLinkClickHandler(props),
});
