// Components
import SecurityKeysAccessKeyChangeFirstStep from '../components/first-step/security-keys-access-key-change-first-step.component';
import SecurityKeysAccessKeyChangeSecondStepComponent from '../components/second-step/security-keys-access-key-change-second-step.component';
import SecurityKeysAccessKeyChangeThirdStepComponent from '../components/third-step/security-keys-access-key-change-third-step.component';
import translations from '../translations/security-keys-access-key-change.translations';

const ACCESS_KEYS_CHANGE_WIZARD_PROPS = {
  steps: [
    {
      component: SecurityKeysAccessKeyChangeFirstStep,
      title: translations.STEP_1,
    },
    {
      component: SecurityKeysAccessKeyChangeSecondStepComponent,
      title: translations.STEP_2,
    },
    {
      component: SecurityKeysAccessKeyChangeThirdStepComponent,
      title: translations.STEP_3,
    },
  ],
};

export { ACCESS_KEYS_CHANGE_WIZARD_PROPS };
