// Vendors
import React from 'react';
// Components
import ChallengeWrapperComponent from 'components/challenge-wrapper/challenge-wrapper.component';
import WizardStepFooterComponent from 'components/wizard-step-footer/wizard-step-footer.component';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import { DetailConfirmationComponent } from 'components/detail-confirmation/detail-confirmation.component';
// Constants
import {
  CHALLENGE_PROPS,
  REQUEST_NEW_CARD_PARAMS,
} from './constants/card-settings-new-card-confirmation.constants';
import { SECOND_STEP_TARGETS } from 'constants/wizard/wizard.constants';
// Hooks
import { CardSettingsNewCardConfirmationHook } from './hooks/card-settings-new-card-confirmation.hook';
// Styles
import { WizardStepContentStyled } from 'styles/app.styled';
// Translations
import { WIZARD_CONFIRM_BUTTON } from 'translations/wizard.translations';
import {
  CARD_DELIVERY_ADDRESS_LABEL,
  CARD_ID_LABEL,
} from './translations/card-settings-new-card-confirmation.translations';
// Utils
import { formatAddress, formatAddressByCountry } from 'utils/addresses/addresses.utils';

export const CardSettingsNewCardConfirmationComponent = (): React.ReactElement => {
  const {
    address,
    cardId,
    challenge,
    challengeError,
    formatMessage,
    handleChallengeRetryButtonClick,
    handleChallengeSuccess,
    handleNextButtonClick,
    pan,
  } = CardSettingsNewCardConfirmationHook();

  return (
    <WizardStepContentStyled>
      <DetailConfirmationComponent
        details={[
          {
            label: CARD_ID_LABEL,
            value: pan,
          },
          {
            label: CARD_DELIVERY_ADDRESS_LABEL,
            value: formatAddress({ address, formatMessage }),
          },
        ]}
      />
      <ChallengeWrapperComponent
        {...CHALLENGE_PROPS}
        baseServiceBuilder={{
          params: { ...REQUEST_NEW_CARD_PARAMS, address: formatAddressByCountry(address) },
          pathParams: { cardId },
        }}
        challengeError={challengeError}
        onRetry={handleChallengeRetryButtonClick}
        onSuccess={handleChallengeSuccess}
      />
      <WizardStepFooterComponent
        hasDefaultLeftButton
        rightButtonConfiguration={{
          assetConfiguration: {},
          children: <FormattedMessageComponent id={WIZARD_CONFIRM_BUTTON} />,
          disabled: !challenge || challengeError,
          onClick: handleNextButtonClick,
        }}
        targets={SECOND_STEP_TARGETS}
      />
    </WizardStepContentStyled>
  );
};
