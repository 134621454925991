// Vendors
import React from 'react';
// Components
import { AssetComponent, ParagraphComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import { INSURANCE_BENEFITS_CONSTANTS } from './constants/financing-insurances-contracting-lpi-benefits.constants';
// Hooks
import { useFinancingInsurancesContractingLPIBenefits } from './hooks/financing-insurances-contracting-lpi-benefits.hook';
import useCbIntl from 'hooks/useCbIntl';
// Styles
import { FinancingInsurancesContractingLPIBenefitsComponentWrapperStyled } from './financing-insurances-contracting-lpi-benefits.component.styled';
import { LayoutRowFlexComponentStyled } from 'styles/app.styled';
// Translations
import {
  FIRST_INSURANCE_BENEFIT_DESCRIPTION,
  FOURTH_INSURANCE_BENEFIT_DESCRIPTION,
  SECOND_INSURANCE_BENEFIT_DESCRIPTION,
  THIRD_INSURANCE_BENEFIT_DESCRIPTION,
} from './translations/financing-insurances-contracting-lpi-benefits.translations';
// Utils
import { makeChunkLinkIntlUtil } from 'hooks/useCbIntl/utils/useCbIntl.utils';

const FinancingInsurancesContractingLPIBenefitsComponent = (): React.ReactElement => {
  const { formatMessage } = useCbIntl();
  const { handleTncLinkClick } = useFinancingInsurancesContractingLPIBenefits();

  return (
    <FinancingInsurancesContractingLPIBenefitsComponentWrapperStyled>
      <LayoutRowFlexComponentStyled {...INSURANCE_BENEFITS_CONSTANTS.GAP}>
        <AssetComponent {...INSURANCE_BENEFITS_CONSTANTS.ASSETS.FIRST} />
        <ParagraphComponent>
          <FormattedMessageComponent id={FIRST_INSURANCE_BENEFIT_DESCRIPTION} />
        </ParagraphComponent>
      </LayoutRowFlexComponentStyled>
      <LayoutRowFlexComponentStyled {...INSURANCE_BENEFITS_CONSTANTS.GAP}>
        <AssetComponent {...INSURANCE_BENEFITS_CONSTANTS.ASSETS.SECOND} />
        <ParagraphComponent>
          {formatMessage(
            { id: SECOND_INSURANCE_BENEFIT_DESCRIPTION },
            {
              br: <br />,
              termsUrl: chunk =>
                makeChunkLinkIntlUtil({
                  chunk,
                  onClick: handleTncLinkClick,
                }),
            }
          )}
        </ParagraphComponent>
      </LayoutRowFlexComponentStyled>
      <LayoutRowFlexComponentStyled {...INSURANCE_BENEFITS_CONSTANTS.GAP}>
        <AssetComponent {...INSURANCE_BENEFITS_CONSTANTS.ASSETS.THIRD} />
        <ParagraphComponent>
          <FormattedMessageComponent id={THIRD_INSURANCE_BENEFIT_DESCRIPTION} />
        </ParagraphComponent>
      </LayoutRowFlexComponentStyled>
      <LayoutRowFlexComponentStyled {...INSURANCE_BENEFITS_CONSTANTS.GAP}>
        <AssetComponent {...INSURANCE_BENEFITS_CONSTANTS.ASSETS.FOURTH} />
        <ParagraphComponent>
          <FormattedMessageComponent id={FOURTH_INSURANCE_BENEFIT_DESCRIPTION} />
        </ParagraphComponent>
      </LayoutRowFlexComponentStyled>
    </FinancingInsurancesContractingLPIBenefitsComponentWrapperStyled>
  );
};

export { FinancingInsurancesContractingLPIBenefitsComponent };
