// Vendors
import React from 'react';
// Components
import { ParagraphComponent } from '@openbank/cb-ui-commons';
import { UploadDocumentCreditLimitIncreaseComponent } from 'containers/financing/views/credit-limit-increase/components/step4/upload-documentation/components/upload-document/upload-document-credit-limit-increase.component';
// Constants
import { NUMBER } from 'constants/number.constants';
import { PARAGRAPHS_L_SIZE } from 'containers/financing/views/credit-limit-increase/components/step4/upload-documentation/constants/step-4-upload-documentation.component.constants';
// Enumerations
import { ActivityTypeDeclaredEnumeration } from 'containers/financing/views/credit-limit-increase/components/step2/sections/employment-data/enumerations/employment-status.enumeration';
import { UploadDocumentIdsEnumeration } from 'containers/financing/views/credit-limit-increase/components/step4/upload-documentation/components/upload-document/enums/upload-document-ids.enumeration';
// Hooks
import { usePensionerSectionHook } from './hooks/pensioner-section.hook';
// Styles
import { DisplayGridStyled } from '../../upload-documents-section.component.styled';
// Types
import { PensionerSectionPropsType } from './types/pensioner-section.type';
// Translations
import { step4UploadDocumentationTranslations } from 'containers/financing/views/credit-limit-increase/components/step4/upload-documentation/translations/step-4-upload-documentation.translations';

export const PensionerSectionComponent = ({
  activityTypeDeclared,
  countryOfResidence,
  documentsRejected,
  formatMessage,
}: PensionerSectionPropsType): React.ReactElement | null => {
  const { hasDocumentsRejected, isAnnualPensionRejected, isAustrian } = usePensionerSectionHook({
    documentsRejected,
    countryOfResidence,
  });
  if (activityTypeDeclared !== ActivityTypeDeclaredEnumeration.PENSIONER) {
    return null;
  }
  if (hasDocumentsRejected && !isAnnualPensionRejected) {
    return null;
  }

  return (
    <>
      <ParagraphComponent bold size={PARAGRAPHS_L_SIZE}>
        {formatMessage({
          id: isAustrian
            ? step4UploadDocumentationTranslations.ANNUAL_PENSION_AUSTRIA
            : step4UploadDocumentationTranslations.ANNUAL_PENSION,
        })}
      </ParagraphComponent>
      <ParagraphComponent>
        {formatMessage({ id: step4UploadDocumentationTranslations.ANNUAL_PENSION_DESCRIPTION })}
      </ParagraphComponent>
      <DisplayGridStyled>
        <UploadDocumentCreditLimitIncreaseComponent
          id={UploadDocumentIdsEnumeration.ANNUAL_PENSION}
          isRejected={isAnnualPensionRejected}
        />
      </DisplayGridStyled>
    </>
  );
};
