// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
// Event handlers
import { FinancingInsurancesHiringLegalHandlers } from '../handlers/financing-insurances-hiring-legal.handlers';
import { FinancingInsurancesHiringLegalTrackingHandlers } from '../handlers/financing-insurances-hiring-legal.tracking.handlers';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { FinancingInsurancesHiringLegalMethodsHandlersType } from '../handlers/types/financing-insurances-hiring-legal.handlers.type';

const useFinancingInsurancesHiringLegalHook =
  (): FinancingInsurancesHiringLegalMethodsHandlersType => {
    const { localization } = AppContextConsumerHook(),
      { cfg_dgsfp_url: url } = localization?.config || {};

    return FinancingInsurancesHiringLegalHandlers({
      ...AppTrackingHook(FinancingInsurancesHiringLegalTrackingHandlers),
      url,
    });
  };

export { useFinancingInsurancesHiringLegalHook };
