// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
// Handlers
import { FinancingInsurancesContractingLPIBenefitsHandlers } from '../handlers/financing-insurances-contracting-lpi-benefits.handlers';
import { FinancingInsurancesContractingLPIBenefitsTrackingHandlers } from '../handlers/financing-insurances-contracting-lpi-benefits.handlers.tracking';
// Hooks
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { FinancingInsurancesContractingLPIBenefitsReturnHandlersType } from '../handlers/types/financing-insurances-contracting-lpi-benefits-return.handlers.type';

export const useFinancingInsurancesContractingLPIBenefits =
  (): FinancingInsurancesContractingLPIBenefitsReturnHandlersType => {
    const {
      localization: {
        config: { cfg_public_lpi_general_tnc },
      },
    } = AppContextConsumerHook();

    return FinancingInsurancesContractingLPIBenefitsHandlers({
      ...AppTrackingHook(FinancingInsurancesContractingLPIBenefitsTrackingHandlers),
      cfg_public_lpi_general_tnc,
    });
  };
