// Vendors
import React from 'react';
// Components
import { CardStatementTableRowDownloadComponent } from 'components/card-statement-download/card-statement-table-row-download.component';
import { FetchErrorComponent } from '@openbank/cb-ui-commons';
import { TransactionsDateComponent } from 'containers/transactions/components/date/transactions-date.component';
import { TransactionsLandingRowDetailsAmountComponent } from './components/amount/transactions-landing-row-details-amount.component';
import { TransactionsLandingRowDetailsInfoMessageComponent } from './components/info-message/transactions-landing-row-details-info-message.component';
import { TransactionsLandingRowDetailsTypeComponent } from './components/type/transactions-landing-row-details-type.component';
import { TransactionsLandingRowDetailsViewOrderComponent } from './components/view-order/transactions-landing-row-details-view-order.component';
import TransactionsLandingRowDetailsCategoryComponent from './components/category/transactions-landing-row-details-category.component';
import TransactionsLandingRowDetailsCreatePlanComponent from './components/create-plan/transactions-landing-row-details-create-plan.component';
import TransactionsLandingRowDetailsPointsComponent from './components/points/transactions-landing-row-details-points.component';
import TransactionsLandingRowDetailsReportComponent from './components/report/transactions-landing-row-details-report.component';
import TransactionsLandingRowDetailsSubcategoryComponent from './components/subcategory/transactions-landing-row-details-subcategory.component';
import TransactionsLandingRowDetailsViewInstallmentComponent from './components/view-installment/transactions-landing-row-details-view-installment.component';
// Configurations
import { getFetchErrorConfiguration } from 'configurations/app.configurations';
// Hooks
import { useTransactionsDetailsHook } from 'containers/transactions/hooks/details/transactions-details.hook';
// Resolvers
import { fetchCardsTransactionsDetailResolver } from 'containers/transactions/resolvers/transactions.resolvers';
// Styles
import { TransactionsLandingRowDetailsComponentStyled } from './transactions-landing-row-details.component.styled';
// Translations
import {
  ERROR_TRANSLATIONS,
  BUTTON_LABEL_ERROR,
} from 'containers/transactions/translations/transactions.translations';
// Types
import { TransactionsLandingRowDetailsComponentType } from './types/transactions-landing-row-details.component.type';
// Utils
import { showDetailComponent } from './utils/transactions-landing-row-details.utils';

const TransactionsLandingRowDetailsComponent = ({
  element: { id },
  open,
}: TransactionsLandingRowDetailsComponentType): React.ReactElement | any | null => {
  const {
    details,
    eppDetails,
    error,
    fetching,
    formatDate,
    formatMessage,
    handleFetchCardTransactionsDetail,
  } = useTransactionsDetailsHook({
    id,
    open,
    resolverDetail: fetchCardsTransactionsDetailResolver,
  });

  const {
    amounts,
    category,
    date,
    detailedAmount,
    documentId,
    eppStatusCode,
    isAMZ,
    report,
    reward,
    time,
    transactionLabel,
    version,
  } = details;

  return open ? (
    <FetchErrorComponent
      error={getFetchErrorConfiguration({
        error,
        formatMessage,
        translations: ERROR_TRANSLATIONS,
        buttonConfiguration: {
          children: formatMessage({ id: BUTTON_LABEL_ERROR }),
          onClick: handleFetchCardTransactionsDetail,
        },
      })}
      fetching={fetching}
    >
      {showDetailComponent(details) && (
        <TransactionsLandingRowDetailsComponentStyled>
          <TransactionsLandingRowDetailsCategoryComponent {...{ category, transactionLabel, id }} />
          <TransactionsLandingRowDetailsSubcategoryComponent
            {...{ category, id, transactionLabel }}
          />
          <TransactionsDateComponent {...{ date, formatDate, time }} />
          <TransactionsLandingRowDetailsTypeComponent isAMZ={isAMZ} />
          {detailedAmount && (
            <TransactionsLandingRowDetailsAmountComponent {...{ detailedAmount, amounts }} />
          )}
          <TransactionsLandingRowDetailsPointsComponent {...{ reward }} />
          <TransactionsLandingRowDetailsReportComponent {...{ id, report }} />
          <CardStatementTableRowDownloadComponent {...{ documentId, version }} />
          <TransactionsLandingRowDetailsViewOrderComponent isAMZ={isAMZ} />
          <TransactionsLandingRowDetailsViewInstallmentComponent {...{ eppStatusCode, id }} />
          <TransactionsLandingRowDetailsInfoMessageComponent
            instalmentStatus={eppDetails?.status}
            reportId={report?.id}
          />
          <TransactionsLandingRowDetailsCreatePlanComponent {...{ eppStatusCode, id, reward }} />
        </TransactionsLandingRowDetailsComponentStyled>
      )}
    </FetchErrorComponent>
  ) : null;
};
export { TransactionsLandingRowDetailsComponent };
