// Vendors
import { TrackingEventsEnum, TrackingViewEventReturnType } from '@openbank/cf-ui-static-data';
// Constants
import { ENTER_STEP_DEFAULT_PAYLOAD } from './constants/financing-insurances-contracting-lpi.handlers.tracking.constants';
// Types
import { TrackBuilderType } from 'types/tracking/track-builder.type';
import { FinancingInsurancesContractingLPIReturnTrackingHandlersType } from './types/financing-insurances-contracting-lpi-return.handlers.tracking.type';

const enterStepTrackingHandler = (step: number): TrackingViewEventReturnType => ({
  payload: {
    ...ENTER_STEP_DEFAULT_PAYLOAD,
    pageName: `/LPIContracting/step0${step}/insuranceInformation`,
    processStep: `step0${step}`,
  },
  type: TrackingEventsEnum.VIEW,
});

export const FinancingInsurancesContractingLPITrackingHandlers = (
  track: TrackBuilderType
): FinancingInsurancesContractingLPIReturnTrackingHandlersType => ({
  handleEnterStepTracking: step => track(enterStepTrackingHandler(step)),
});
