// Vendors
import React, { Fragment } from 'react';
// Components
import {
  LineSeparatorComponent,
  ParagraphComponent,
  TitleHeaderComponent,
} from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import WizardStepFooterComponent from 'components/wizard-step-footer/wizard-step-footer.component';
import ErrorWrapperComponent from 'components/error-wrapper/error-wrapper.component';
import { UploadDocumentsTipsComponent } from 'components/upload-doc-tips/upload-documents-tips.component';
import { UploadDocumentsListComponent } from './components/document-list/upload-documents-list.component';
// Constants
import { TITLE_PROPS } from './constants/personal-upload-documentation.constants';
// Hooks
import { usePersonalUploadDocumentationHook } from './hooks/personal-upload-documentation-props.hook';
// Resolvers
import {
  reRequestDocumentsResolver,
  updateDocumentOnDBResolver,
} from './resolvers/personal-upload-documentation.resolvers';
// Styled
import {
  PersonalUploadDocumentationComponentTipStyled,
  PersonalUploadDocumentationSeparatorStyled,
} from './personal-upload-documentation.component.styled';
import { PersonalUploadDocumentationComponentWrapperStyled } from '../../personal-upload-documentation.view.styled';
// Translations
import {
  SEND_DOCUMENTATION_BUTTON,
  UPLOAD_DOCUMENTATION_DESCRIPTION,
  UPLOAD_DOCUMENTATION_TITLE,
} from './translations/personal-upload-documentation.translations';
// Types
import { PersonalUploadDocumentationComponentPropType } from './types/personal-upload-documentation-props.component.type';
// Utilities
import { shouldDisableSendButton } from './utils/personal-upload-documentation.utils';

const PersonalUploadDocumentationComponent = ({
  onChangeFlow,
}: PersonalUploadDocumentationComponentPropType): React.ReactElement => {
  const { error, fetching, files, handleSendDocuments, setFiles, showCancelModal } =
    usePersonalUploadDocumentationHook({ onChangeFlow });

  return (
    <ErrorWrapperComponent {...{ error, fetching }}>
      <PersonalUploadDocumentationComponentWrapperStyled>
        <TitleHeaderComponent {...TITLE_PROPS}>
          <FormattedMessageComponent id={UPLOAD_DOCUMENTATION_TITLE} />
        </TitleHeaderComponent>
        <ParagraphComponent>
          <FormattedMessageComponent id={UPLOAD_DOCUMENTATION_DESCRIPTION} />
        </ParagraphComponent>
        <PersonalUploadDocumentationComponentTipStyled>
          <UploadDocumentsTipsComponent />
        </PersonalUploadDocumentationComponentTipStyled>
        <UploadDocumentsListComponent
          {...{
            files,
            onChangeFiles: setFiles,
            reRequestFiles: reRequestDocumentsResolver,
            updateFilesOnDB: updateDocumentOnDBResolver,
          }}
        />
        <WizardStepFooterComponent
          shouldShowModal={showCancelModal}
          rightButtonConfiguration={{
            disabled: shouldDisableSendButton(files),
            children: <FormattedMessageComponent id={SEND_DOCUMENTATION_BUTTON} />,
            onClick: handleSendDocuments,
          }}
        />
      </PersonalUploadDocumentationComponentWrapperStyled>
    </ErrorWrapperComponent>
  );
};

export { PersonalUploadDocumentationComponent };
