// Vendors
import React from 'react';
// Components
import { WizardComponent as CommonWizardComponent } from '@openbank/cb-ui-commons';
import WizardFeedbackComponent from 'components/wizard-feedback/wizard-feedback.component';
// Constants
import { WIZARD_CURRENT_STEP } from './constants/wizard-step-footer.constants';
// Contexts
import { WizardContextConsumerHOC } from './contexts/wizard.context';
// Hooks
import useWizardHook from './hooks/wizard.hook';
import useWizardStepsHook from 'hooks/wizard-steps.hook';
// Types
import { WizardComponentType } from './types/wizard.component.type';

const WizardComponent = ({
  analytics,
  feedbackConfiguration,
  steps,
  currentStep = WIZARD_CURRENT_STEP,
}: WizardComponentType): React.ReactElement => {
  const { currentStep: step, handleRetryButtonClick } = useWizardHook();

  return (
    <WizardFeedbackComponent
      {...{
        ...feedbackConfiguration,
        errorConfiguration: {
          buttonClick: () => handleRetryButtonClick(currentStep),
          ...feedbackConfiguration?.errorConfiguration,
        },
      }}
    >
      <CommonWizardComponent
        {...{ ...useWizardStepsHook({ steps, step: (step as number) - 1 }), analytics, step }}
      />
    </WizardFeedbackComponent>
  );
};

export default WizardContextConsumerHOC(WizardComponent);
