// Types
import { MobileConnectorHookReturnPropsType } from 'providers/mobile-connector/hooks/types/mobile-connector-hook-return.type';
// Utilities
import { isSecondLevelRoutePath } from 'utils/app-routing.utils';
import { isMobileDevice, isTabletDevice } from '@openbank/cf-ui-framework';
import { isCC } from 'utils/app.utils';

const isSecondLevelRoutePathAndMobileOrHybrid = (
  isHybrid: MobileConnectorHookReturnPropsType['isHybrid']
): boolean => {
  const isMobileOrTablet = isMobileDevice() || isTabletDevice();

  return isHybrid || (isSecondLevelRoutePath() && isMobileOrTablet && !isCC());
};

export { isSecondLevelRoutePathAndMobileOrHybrid };
