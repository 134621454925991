// Types
import { AddressType } from 'types/address.type';
import { CloseModalHandlerType } from './types/close-modal-handler.type';
import { ErrorModalHandlerType } from './types/error-modal-handler.type';
import { OpenModalHandlerType } from './types/open-modal-handler.type';
import { PersonalAddressStep1HandlersReturnType } from './types/personal-address-step1-return.handlers.type';
import { PersonalAddressStep1HandlersType } from './types/personal-address-step1.handlers.type';
import { SaveButtonClickHandlerType } from './types/save-button-click-handler.type';
// Utils
import { isAustrian } from 'utils/country.utils';

const openModalHandler = ({ setIsEditing }: OpenModalHandlerType): void => setIsEditing(true);

const closeModalHandler = ({ setIsEditing }: CloseModalHandlerType): void => setIsEditing(false);

const saveButtonClickHandler = ({
  address,
  formValues,
  setIsEditing,
  setNewAddress,
}: SaveButtonClickHandlerType): void => {
  const additionalFields = isAustrian(address.countryCode)
    ? { floor: formValues.staircase, department: formValues.doorNumber }
    : { additionalItems1: formValues.additional };

  setNewAddress({
    ...address,
    ...additionalFields,
    city: formValues.city,
    number: formValues.number,
    postalCode: formValues.zip,
    street: formValues.street,
  });

  setIsEditing(false);
};

const errorModalHandler = ({ setError }: ErrorModalHandlerType): void => setError(true);

export const PersonalAddressStep1Handlers = ({
  address,
  setError,
  setIsEditing,
  setNewAddress,
}: PersonalAddressStep1HandlersType): PersonalAddressStep1HandlersReturnType => ({
  handleErrorModal: () => errorModalHandler({ setError }),
  handleOpenModal: () => openModalHandler({ setIsEditing }),
  handleCloseModal: () => closeModalHandler({ setIsEditing }),
  handleSaveButtonClick: formValues =>
    saveButtonClickHandler({ address, formValues, setIsEditing, setNewAddress }),
});
