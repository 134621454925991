// Types
import { InterstitialCommercialCommonInfoTrackingType } from '../types/modal-interstitial-commercial-common-info.tracking.handlers.type';
import { GetCommonTrackingPayloadType } from './types/get-common-Tracking-payload.type';

export const getCommonTrackingPayload = ({
  activityId,
  creativityId,
  offerId,
  experienceId,
}: InterstitialCommercialCommonInfoTrackingType): GetCommonTrackingPayloadType => ({
  interstitialID: creativityId,
  atActivity: activityId,
  atOffer: offerId,
  atExperience: experienceId,
});
