// Constants
import {
  ADDRESS_FORM_DEFAULT_VALUES,
  AT_ADDRESS_FORM_DEFAULT_VALUES,
} from './constants/address-modal.utils.constants';
// Types
import { IsInvalidAddressType } from './types/is-invalid-address.type';
import { AddressFormValuesType } from '../types/address-form-values.type';
import { AddressType } from 'types/address.type';
// Utils
import { isAustrian } from 'utils/country.utils';

const isInvalidAddress = ({ street, number, zip, city }: IsInvalidAddressType): boolean =>
  !street || !number || !zip || !city;

const formatATAddress = (address: AddressType): AddressFormValuesType => ({
  city: address.city,
  doorNumber: address.department ?? AT_ADDRESS_FORM_DEFAULT_VALUES.doorNumber,
  number: address.number,
  staircase: address.floor ?? AT_ADDRESS_FORM_DEFAULT_VALUES.staircase,
  street: address.street,
  zip: address.postalCode,
});

const formatDefaultAddress = (address: AddressType): AddressFormValuesType => ({
  additional: address.additionalItems1 ?? ADDRESS_FORM_DEFAULT_VALUES.additional,
  city: address.city,
  number: address.number,
  street: address.street,
  zip: address.postalCode,
});

const formatAddressFormValues = (
  address: AddressType | null,
  country: string
): AddressFormValuesType => {
  const isAustrianValue = isAustrian(country);

  if (address) {
    return isAustrianValue ? formatATAddress(address) : formatDefaultAddress(address);
  }

  return isAustrianValue ? AT_ADDRESS_FORM_DEFAULT_VALUES : ADDRESS_FORM_DEFAULT_VALUES;
};

const trimValues = (values: AddressFormValuesType): AddressFormValuesType => {
  const trimmedValues: AddressFormValuesType = {} as AddressFormValuesType;

  for (const key in values) {
    trimmedValues[key] = values[key].trim();
  }

  return trimmedValues;
};

export { formatAddressFormValues, isInvalidAddress, trimValues };
