// Vendors
import { useEffect } from 'react';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
import { CreditLimitIncreaseContextConsumerHook } from 'containers/financing/views/credit-limit-increase/contexts/credit-limit-increase.context';
// Handlers
import Step2CreditLimitIncreaseHandlers from '../handlers/step-2-credit-limit-increase.component.handler';
import { SetLaboralEconomicDataHandlers } from 'containers/financing/views/credit-limit-increase/handlers/step-2-set-laboral-economic-data.handlers';
// Hooks
import AppTrackingHook from 'tracking/app.tracking.hook';
import { useWizardContextConsumerHook } from 'components/wizard/contexts/wizard.context';
// Types
import { Step2CreditLimitIncreaseHookType } from './types/step-2-credit-limit-increase.component.hook.type';
// Trackings
import Step2CreditLimitChageTracking from '../trackings/step-2-credit-limit-increase.tracking';
import { MobileConnectorContextConsumerHook } from 'providers/mobile-connector/contexts/mobile-connector.provider.context';
// Utils
import { isAustrian } from 'utils/country.utils';

const Step2CreditLimitIncreaseHook = (): Step2CreditLimitIncreaseHookType => {
  const { currentCard, profile } = AppContextConsumerHook(),
    { id: cardId } = currentCard || {},
    { countryOfResidence } = profile?.userDetails || {};
  const creditLimitContext = CreditLimitIncreaseContextConsumerHook();
  const { step2, setStep2 } = creditLimitContext;
  const { handleUpdateNextButton } = Step2CreditLimitIncreaseHandlers({ step2, setStep2 });
  const { handleEnterViewTracking, handleClickPreviousButtonTracking } = AppTrackingHook(
    Step2CreditLimitChageTracking
  );
  const { setCurrentStep } = useWizardContextConsumerHook();
  const { handleSetLaboralEconomicData, handleSetLaboralEconomicDataBack } =
    SetLaboralEconomicDataHandlers({
      cardId,
      creditLimitContext,
      setCurrentStep,
      handleClickPreviousButtonTracking,
    });

  const { handleOpenExternalUrlOnHybridFlow } = MobileConnectorContextConsumerHook();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(handleUpdateNextButton, [step2]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(handleEnterViewTracking, []);

  return {
    isDisabled: step2.nextDisabled ?? true,
    isAustrian: isAustrian(countryOfResidence),
    handleSetLaboralEconomicData: () => handleSetLaboralEconomicData(step2.personalData),
    handleSetLaboralEconomicDataBack,
    handleOpenExternalUrlOnHybridFlow,
  };
};

export default Step2CreditLimitIncreaseHook;
