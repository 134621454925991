// Vendors
import { TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
// Constants
import { LEAFLET_LINK_CLICK_TRACKING_PROPS } from './constants/financing-insurances-contracting-lpi-additional-covered.handlers.tracking.constants';
// Types
import { FinancingInsurancesContractingLPIAdditionalCoveredReturnTrackingHandlersType } from './types/financing-insurances-contracting-lpi-additional-covered-return.handlers.tracking.type';
import { TrackBuilderType } from 'types/tracking/track-builder.type';

const leafletLinkClickTrackingHandler = (): TrackingLinkEventReturnType =>
  LEAFLET_LINK_CLICK_TRACKING_PROPS;

export const FinancingInsurancesContractingLPIAdditionalCoveredTrackingHandlers = (
  track: TrackBuilderType
): FinancingInsurancesContractingLPIAdditionalCoveredReturnTrackingHandlersType => ({
  handleLeafletLinkClickTracking: () => track(leafletLinkClickTrackingHandler()),
});
