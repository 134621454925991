// Vendors
import useCbIntl from 'hooks/useCbIntl';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
// Types
import { CardSettingsContractCurrentCreditHookType } from './types/card-settings-contract-current-credit.hook.type';

const useCardSettingsContractCurrentCreditHook = (): CardSettingsContractCurrentCreditHookType => {
  const { formatNumber } = useCbIntl();

  const { currentCard } = AppContextConsumerHook(),
    { currencyCode } = currentCard || {},
    { availableAmount, currentAmountAuthorized, limit } = currentCard?.creditDetails || {};

  return {
    availableAmount,
    currencyCode,
    currentAmountAuthorized,
    formatNumber,
    limit,
  };
};

export default useCardSettingsContractCurrentCreditHook;
