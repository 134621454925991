const SIDEBAR_INFO_PROPS = {
  testId: 'personal-upload-documentation',
};
const DESCRIPTION_PROPS = { testId: 'personal-upload-documentation-sidebar-description' };
const LINK_PROPS = {
  href: 'https://www.zinia.de/amazon/datenschutz-cookies',
  testId: 'personal-upload-documentation-sidebar-link',
};

export { LINK_PROPS, SIDEBAR_INFO_PROPS, DESCRIPTION_PROPS };
