// Vendors
import React from 'react';
// Components
import WizardStepFooterComponent from 'components/wizard-step-footer/wizard-step-footer.component';
// Hooks
import SupportCancelContractFirstStepHook from '../hooks/support-cancel-contract-first-step.hook';
// Utilities
import { modifyCancelContractStep1NextButtonIsDisabled } from './utils/support-cancel-contract-first-step-actions.utils';

const SupportCancelContractFirstStepActionsComponent = (): React.ReactElement => {
  const { reason, handleShowModalCancelContractEventHandler } =
    SupportCancelContractFirstStepHook();

  return (
    <WizardStepFooterComponent
      shouldShowModal={Boolean(reason)}
      rightButtonConfiguration={{
        disabled: modifyCancelContractStep1NextButtonIsDisabled({ reason }),
        onClick: handleShowModalCancelContractEventHandler,
      }}
    />
  );
};

export default SupportCancelContractFirstStepActionsComponent;
