// Enumerations
import { AddressTypesEnum } from '../../enumerations/address-types.enumeration';
import { FeatureFlagsEnumeration } from 'enumerations/feature-flags.enumeration';
// Types
import { BlockCardAddressType } from '../hooks/types/block-card-address.type';
import { GetAddressessReturnType } from './types/block-card-address-return.utils.type';
// Utils
import { getFeatureFlagInstance } from 'providers/feature-flags/utils/feature-flags.utils';
import { isAustrian } from 'utils/country.utils';

const shouldNotShowTemporalAddress = (countryOfResidence: string): boolean =>
  isAustrian(countryOfResidence) &&
  !getFeatureFlagInstance().isEnabled(FeatureFlagsEnumeration.TEMPORAL_ADDRESS);

const getAddressess = ({
  countryOfResidence,
  address,
  tempAddress,
}: GetAddressessReturnType): BlockCardAddressType[] =>
  shouldNotShowTemporalAddress(countryOfResidence)
    ? [{ address, type: AddressTypesEnum.HBTL }]
    : [
        { address, type: AddressTypesEnum.HBTL },
        { address: tempAddress, type: AddressTypesEnum.TEMP },
      ];

export { getAddressess };
