// Types
import { FinancingInsurancesContractingLPIStep2PlansReturnHandlersType } from './types/financing-insurances-contracting-lpi-step2-plans-return.handlers.type';
import { FinancingInsurancesContractingLPIStep2PlansHandlersType } from './types/financing-insurances-contracting-lpi-step2-plans.handlers.type';
import { SelectPlanHandlerType } from './types/select-plan-handler.type';

const selectPlanHandler = ({
  coverage,
  code,
  setPlan,
  handleSelectPlanTracking,
}: SelectPlanHandlerType): void => {
  handleSelectPlanTracking(coverage);
  setPlan(code);
};

export const FinancingInsurancesContractingLPIStep2PlansHandlers = ({
  handleSelectPlanTracking,
  setPlan,
}: FinancingInsurancesContractingLPIStep2PlansHandlersType): FinancingInsurancesContractingLPIStep2PlansReturnHandlersType => ({
  handleSelectPlan: ({ coverage, code }) =>
    selectPlanHandler({ coverage, code, setPlan, handleSelectPlanTracking }),
});
