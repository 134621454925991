// Enumerations
import { AddressTypesEnum } from 'components/block-card/components/enumerations/address-types.enumeration';
// Types
import { AddressType } from 'types/address.type';
import { AddressesUtilsType } from 'utils/addresses/types/addresses.utils.type';
import { CharactersEnum } from '@openbank/cf-ui-static-data';
import {
  ADDRESS_ATTRIBUTES_FOR_FILTER,
  ADDRESS_ATTRIBUTES_FOR_TRANSLATE,
} from './constants/addresses.utils.constants';
import { GetSelectedAddressType } from './types/get-selected-address.type';
// Translations
import { COUNTRIES_PREFFIX } from 'translations/locations.translations';
import { DOOR_LABEL, LABELS, STAIRCASE_LABEL } from './translations/addresses.translations';
// Types
import { FormatAddressType } from './types/format-address.type';
import { FormatAddressAdditionalFieldsType } from './types/format-address-additional-fields.type';
// Utils
import { isAustrian } from 'utils/country.utils';

const createProxiedAndOrderedStreet = ({
  address,
  formatMessage,
}: AddressesUtilsType): Record<string, string> =>
  Object.fromEntries(
    Object.entries(address)
      .filter(item => ADDRESS_ATTRIBUTES_FOR_FILTER.includes(item[0]))
      .map(item =>
        ADDRESS_ATTRIBUTES_FOR_TRANSLATE.includes(item[0])
          ? [item[0], formatMessage({ id: `${LABELS[item[0]]}${item[1]}` })]
          : item
      )
      .filter(item => item[1])
      .sort(
        (a, b) =>
          ADDRESS_ATTRIBUTES_FOR_FILTER.findIndex(e => e === a[0]) -
          ADDRESS_ATTRIBUTES_FOR_FILTER.findIndex(e => e === b[0])
      )
  );

const prettyRawCustomerAddress = (props: AddressesUtilsType): string =>
  Object.values(createProxiedAndOrderedStreet(props)).join(CharactersEnum.COMMA_SPACE);

const isTemporalAddress = (address: AddressTypesEnum): boolean =>
  address === AddressTypesEnum.TEMP && !!address;

const getSelectedAddress = ({
  selectedAddress,
  tempAddress,
  customerAddress,
}: GetSelectedAddressType): AddressType =>
  isTemporalAddress(selectedAddress) && tempAddress ? tempAddress : customerAddress;

const formatAddressAdditionalFields = ({
  additionalItems1,
  department,
  floor,
  formatMessage,
}: FormatAddressAdditionalFieldsType): string => {
  const fields = [
    floor && `${formatMessage({ id: STAIRCASE_LABEL })} ${floor}`,
    department && `${formatMessage({ id: DOOR_LABEL })} ${department}`,
    additionalItems1,
  ].filter(Boolean);

  return fields.length ? ` ${fields.join(', ')},` : '';
};

const formatAddress = ({ address, formatMessage }: FormatAddressType): string => {
  const additionalFields = formatAddressAdditionalFields({
    additionalItems1: address.additionalItems1,
    department: address.department,
    floor: address.floor,
    formatMessage,
  });

  const country = formatMessage({
    id: `${COUNTRIES_PREFFIX}${address.countryCode}`,
  });

  return `${address.street} ${address.number},${additionalFields}\n${address.postalCode}, ${address.city},\n${country}`;
};

const formatAddressByCountry = (address: AddressType): AddressType => {
  const formattedAddress = {
    street: address.street,
    postalCode: address.postalCode,
    city: address.city,
    countryCode: address.countryCode,
    addressType: address.addressType,
    number: address.number,
    enableConfirmation: address.enableConfirmation,
  };

  return isAustrian(address.countryCode)
    ? {
        ...formattedAddress,
        floor: address.floor,
        department: address.department,
      }
    : {
        ...formattedAddress,
        additionalItems1: address.additionalItems1,
      };
};

export {
  formatAddress,
  formatAddressAdditionalFields,
  formatAddressByCountry,
  getSelectedAddress,
  isTemporalAddress,
  prettyRawCustomerAddress,
};
