// Vendors
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
import { CreditLimitIncreaseContextConsumerHook } from 'containers/financing/views/credit-limit-increase/contexts/credit-limit-increase.context';
import { MobileConnectorContextConsumerHook } from 'providers/mobile-connector/contexts/mobile-connector.provider.context';
import { useWizardContextConsumerHook } from 'components/wizard/contexts/wizard.context';
// Handlers
import AccountAggregationHandlers from 'containers/financing/views/credit-limit-increase/handlers/step-4-account-aggregation.handler';
// Hooks
import useCbIntl from 'hooks/useCbIntl';
// Types
import { Step4AccountAggregationHookType } from './types/step-4-account-aggregation.component.hook.type';

const Step4AccountAggregationHook = (): Step4AccountAggregationHookType => {
  const { formatMessage } = useCbIntl();
  const [isLoadingGoToUploadDocument, setIsLoadingGoToUploadDocument] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const { currentCard } = AppContextConsumerHook(),
    { id: cardId } = currentCard || {};
  const creditLimitContext = CreditLimitIncreaseContextConsumerHook();
  const { step4 } = creditLimitContext;
  const { setCurrentStep } = useWizardContextConsumerHook(),
    { isHybrid, handleOpenWebViewBrowser } =
      MobileConnectorContextConsumerHook();

  const [showInvalidBankMessage, setShowInvalidBankMessage] = useState(false);
  const [showTinkErrorMessage, setShowTinkErrorMessage] = useState(false);

  const {
    handleSaveTinkDataFromParams,
    handleSendAccountAggregationRequest,
    handleOnClickAddBankButton,
    handleOnClickGoToUploadDocumentation,
    handleAccountAggregationGoNext,
    handleShowInvalidBankMessage,
    handleShowTinkErrorMessage,
    handleSendTinkError,
  } = AccountAggregationHandlers({
    cardId,
    creditLimitContext,
    searchParams,
    isHybrid,
    handleOpenWebViewBrowser,
    formatMessage,
    setCurrentStep,
    setSearchParams,
    setShowInvalidBankMessage,
    setShowTinkErrorMessage,
    setIsLoadingGoToUploadDocument,
  });

  useEffect(() => {
    handleSaveTinkDataFromParams();
    handleShowTinkErrorMessage();
    handleSendTinkError();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(handleShowInvalidBankMessage, [searchParams, step4.stepData?.bankData]);

  useEffect(() => {
    handleSendAccountAggregationRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step4.tinkData]);

  return {
    bankData: step4.stepData?.bankData || [],
    showTinkErrorMessage,
    showInvalidBankMessage,
    isLoadingNextRequest: step4.isLoadingNextRequest,
    isLoadingGoToUploadDocument,
    handleCloseInvalidBankMessage: () => setShowInvalidBankMessage(false),
    handleCloseTinkErrorMessage: () => setShowTinkErrorMessage(false),
    onClickAddBankButton: () => handleOnClickAddBankButton(step4.aggregationUrl),
    onClickGoToUploadDocumentation: handleOnClickGoToUploadDocumentation,
    onAccountAggregationGoNext: handleAccountAggregationGoNext,
  };
};

export default Step4AccountAggregationHook;
