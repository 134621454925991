// Vendors
import React from 'react';
import useCbIntl from 'hooks/useCbIntl';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import { LineSeparatorComponent, ListComponent, ParagraphComponent } from '@openbank/cb-ui-commons';
// Constants
import {
  FINANCING_PPI_CONTRACTING_STEP_2_COVERAGE_COVERED_DESCRIPTION_01_PROPS,
  FINANCING_PPI_CONTRACTING_STEP_2_COVERAGE_COVERED_DESCRIPTION_02_PROPS,
  FINANCING_PPI_CONTRACTING_STEP_2_COVERAGE_COVERED_DESCRIPTION_03_PROPS,
  FINANCING_PPI_CONTRACTING_STEP_2_COVERAGE_COVERED_DESCRIPTION_04_PROPS,
  FINANCING_PPI_CONTRACTING_STEP_2_COVERAGE_COVERED_LAYOUT_PROPS,
  FINANCING_PPI_CONTRACTING_STEP_2_COVERAGE_COVERED_LIST_ITEMS,
  FINANCING_PPI_CONTRACTING_STEP_2_COVERAGE_COVERED_LIST_PROPS,
} from './constants/financing-ppi-contracting-step2-expandable-covered.constants';
import { FINANCING_PPI_CONTRACTING_STEP_2_BOLD_M_SIZE } from 'containers/financing/views/ppi/components/contracting/cpi/components/step2/constants/financing-ppi-contracting-step2.constants';
// Styles
import { LayoutColumnFlexComponentStyled } from 'styles/app.styled';
// Translations
import {
  DESCRIPTION_01,
  DESCRIPTION_02,
  DESCRIPTION_03,
  DESCRIPTION_04,
  TITLE,
} from './translations/financing-ppi-contracting-step2-expandable-covered.translations';
// Utilities
import { mapAndTranslateListItems } from 'utils/map-list-items';

const FinancingPPIContractingStep2ExpandableCoveredComponent = (): React.ReactElement => {
  const { formatMessage } = useCbIntl();

  return (
    <>
      <ParagraphComponent {...FINANCING_PPI_CONTRACTING_STEP_2_BOLD_M_SIZE}>
        <FormattedMessageComponent id={TITLE} />
      </ParagraphComponent>

      <LayoutColumnFlexComponentStyled
        {...FINANCING_PPI_CONTRACTING_STEP_2_COVERAGE_COVERED_LAYOUT_PROPS}
      >
        <ParagraphComponent
          {...FINANCING_PPI_CONTRACTING_STEP_2_COVERAGE_COVERED_DESCRIPTION_01_PROPS}
        >
          <FormattedMessageComponent id={DESCRIPTION_01} />
        </ParagraphComponent>
        <ListComponent
          {...FINANCING_PPI_CONTRACTING_STEP_2_COVERAGE_COVERED_LIST_PROPS}
          items={mapAndTranslateListItems({
            ...FINANCING_PPI_CONTRACTING_STEP_2_COVERAGE_COVERED_LIST_ITEMS,
            formatMessage,
          })}
        />
        <ParagraphComponent
          {...FINANCING_PPI_CONTRACTING_STEP_2_COVERAGE_COVERED_DESCRIPTION_02_PROPS}
        >
          <FormattedMessageComponent id={DESCRIPTION_02} />
        </ParagraphComponent>
        <ParagraphComponent
          {...FINANCING_PPI_CONTRACTING_STEP_2_COVERAGE_COVERED_DESCRIPTION_03_PROPS}
        >
          <FormattedMessageComponent id={DESCRIPTION_03} />
        </ParagraphComponent>
        <ParagraphComponent
          {...FINANCING_PPI_CONTRACTING_STEP_2_COVERAGE_COVERED_DESCRIPTION_04_PROPS}
        >
          <FormattedMessageComponent id={DESCRIPTION_04} />
        </ParagraphComponent>
      </LayoutColumnFlexComponentStyled>
      <LineSeparatorComponent />
    </>
  );
};

export { FinancingPPIContractingStep2ExpandableCoveredComponent };
