// Types
import { FinancingInsurancesContractingLPIBenefitsHandlersType } from './types/financing-insurances-contracting-lpi-benefits.handlers.type';
import { FinancingInsurancesContractingLPIBenefitsReturnHandlersType } from './types/financing-insurances-contracting-lpi-benefits-return.handlers.type';

const tncLinkClickHandler = ({
  cfg_public_lpi_general_tnc,
  handleTncLinkClickTracking,
}: FinancingInsurancesContractingLPIBenefitsHandlersType): void => {
  handleTncLinkClickTracking();
  window.open(cfg_public_lpi_general_tnc, '_blank');
};

export const FinancingInsurancesContractingLPIBenefitsHandlers = (
  props: FinancingInsurancesContractingLPIBenefitsHandlersType
): FinancingInsurancesContractingLPIBenefitsReturnHandlersType => ({
  handleTncLinkClick: () => tncLinkClickHandler(props),
});
