// Types
import { FinancingInsurancesContractingLPIAdditionalCoveredHandlersType } from './types/financing-insurances-contracting-lpi-additional-covered.handlers.type';
import { FinancingInsurancesContractingLPIAdditionalCoveredReturnHandlersType } from './types/financing-insurances-contracting-lpi-additional-covered-return.handlers.type';

const leafletLinkClickHandler = ({
  cfg_public_lpi_info_leaflet,
  handleLeafletLinkClickTracking,
}: FinancingInsurancesContractingLPIAdditionalCoveredHandlersType): void => {
  handleLeafletLinkClickTracking();
  window.open(cfg_public_lpi_info_leaflet, '_blank');
};

export const FinancingInsurancesContractingLPIAdditionalCoveredHandlers = (
  props: FinancingInsurancesContractingLPIAdditionalCoveredHandlersType
): FinancingInsurancesContractingLPIAdditionalCoveredReturnHandlersType => ({
  handleLeafletLinkClick: () => leafletLinkClickHandler(props),
});
