// Vendors
import React from 'react';
// Components
import { FinancingInsurancesContractingLPISidebarComponent } from './components/sidebar/financing-insurances-contracting-lpi-sidebar.component';
import { OperativeLayoutComponent } from 'components/operative-layout/operative-layout.component';
import WizardComponent from 'components/wizard/wizard.component';
// Constants
import { FINANCING_CONTRACTING_LPI_WIZARD_PROPS } from './constants/financing-insurances-contracting-lpi.constants';
// Contexts
import { FinancingInsurancesContractingLPIContextConsumerHOC } from './contexts/financing-insurances-contracting-lpi.context';
// Hooks
import { useFinancingInsurancesContractingLPI } from './hooks/financing-insurances-contracting-lpi.hook';

const FinancingInsurancesContractingLPIBaseComponent = (): React.ReactElement => {
  const analytics = useFinancingInsurancesContractingLPI();

  return (
    <OperativeLayoutComponent sidebarComponent={FinancingInsurancesContractingLPISidebarComponent}>
      <WizardComponent {...FINANCING_CONTRACTING_LPI_WIZARD_PROPS} analytics={analytics} />
    </OperativeLayoutComponent>
  );
};

const FinancingInsurancesContractingLPIComponent =
  FinancingInsurancesContractingLPIContextConsumerHOC(
    FinancingInsurancesContractingLPIBaseComponent
  );

export { FinancingInsurancesContractingLPIComponent };
