// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import { TitleComponent } from '@openbank/cb-ui-commons';
import TitleSectionRwdAssetComponent from 'components/title-section/components/rwd/components/asset/title-section-rwd-asset.component';
import TitleSectionRwdBreadcrumbsComponent from 'components/title-section/components/rwd/components/breadcrumbs/title-section-rwd-breadcrumbs.component';
import TitleSectionStatusComponent from './components/status/title-section-status.component';
// Constants
import constants from './constants/title-section.constants';
// Styles
import {
  TitleSectionRwdComponentStyled,
  TitleSectionTopComponentStyled,
  TitleSectionTopContentComponentStyled,
} from './title-section-rwd.component.styled';
// Types
import { TitleSectionRwdComponentType } from 'components/title-section/components/rwd/types/title-section-rwd.component.type';
// Utilities
import { getBreadCrumbCurrentItemTitle } from 'components/title-section/components/rwd/utils/title-section-rwd.utils';

const TitleSectionRwdComponent = ({
  showBreadcrumbs = true,
  assetConfiguration,
}: TitleSectionRwdComponentType): React.ReactElement => (
  <TitleSectionRwdComponentStyled>
    {showBreadcrumbs && <TitleSectionRwdBreadcrumbsComponent />}
    <TitleSectionTopContentComponentStyled>
      <TitleSectionTopComponentStyled>
        <TitleSectionRwdAssetComponent {...{ assetConfiguration }} />
        <TitleComponent {...constants.TITLE_SECTION_DESCRIPTION_PROPS}>
          <FormattedMessageComponent id={getBreadCrumbCurrentItemTitle()} />
        </TitleComponent>
      </TitleSectionTopComponentStyled>
      <TitleSectionStatusComponent />
    </TitleSectionTopContentComponentStyled>
  </TitleSectionRwdComponentStyled>
);

export default TitleSectionRwdComponent;
