// Vendors
import React from 'react';
// Components
import { CheckboxComponent } from '@openbank/cb-ui-commons';
import { FinancingPPIContractingDescriptionLinkComponent } from 'containers/financing/views/ppi/components/contracting/cpi/components/description-link/financing-ppi-contracting-description-link.component';
// Constants
import { FINANCING_PPI_CONTRACTING_STEP_2_CONSENT_TEST_ID } from './constants/financing-ppi-contracting-step2-consent.constants';
// Hooks
import { useFinancingPPIContractingStep2ConsentHook } from './hooks/financing-ppi-contracting-step2-consent.hook';
// Types
import { FinancingPPIContractingStep2ConsentComponentType } from './types/financing-ppi-contracting-step2-consent.component.type';
// Utilities
import {
  consentHasBeenChecked,
  getIfConsentHasError,
} from './utils/financing-ppi-contracting-step2-consent.utils';

const FinancingPPIContractingStep2ConsentComponent = ({
  consentId,
  label: description,
  values,
  url,
}: FinancingPPIContractingStep2ConsentComponentType): React.ReactElement => {
  const {
    checksDone,
    errorChecksDone,
    handleChangePPIContractingConsentRadioChange: onChange,
  } = useFinancingPPIContractingStep2ConsentHook(consentId);

  return (
    <CheckboxComponent
      {...{ onChange }}
      error={getIfConsentHasError({ checksDone, consentId, errorChecksDone })}
      testing={{
        labelTestId: FINANCING_PPI_CONTRACTING_STEP_2_CONSENT_TEST_ID + consentId,
        elementTestId: FINANCING_PPI_CONTRACTING_STEP_2_CONSENT_TEST_ID + consentId,
      }}
      title={
        <FinancingPPIContractingDescriptionLinkComponent
          {...{ description, values, url }}
          testId={consentId}
        />
      }
      value={consentHasBeenChecked({ checksDone, consentId })}
    />
  );
};

export { FinancingPPIContractingStep2ConsentComponent };
