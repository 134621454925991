// Vendors
import { useEffect, useState } from 'react';
// Constants
import { DEFAULT_COUNTDOWN } from './constants/use-countdown.hook.constants';
// Handlers
import { CountdownHandlers } from '../handlers/countdown.handlers';
// Types
import { UseCountdownHookType } from './types/use-countdown.hook.types';

const useCountdown = ({
  countdown = DEFAULT_COUNTDOWN,
  fetching,
}: UseCountdownHookType): boolean => {
  const [countdownCompleted, setCountdownIsCompleted] = useState<boolean>(false);

  const { CountdownHandler } = CountdownHandlers({
    fetching,
    countdown,
    setCountdownIsCompleted,
  });

  useEffect(() => {
    return CountdownHandler();
  }, [fetching]);

  return countdownCompleted;
};

export { useCountdown };
