// Vendors
import React from 'react';
// Components
import { FinancingPPIContractingStep2FeaturesSectionComponent } from 'containers/financing/views/ppi/components/contracting/cpi/components/step2/components/features-section/financing-ppi-contracting-step2-features-section.component';
// Constants
import { FINANCING_PPI_CONTRACTING_STEP_2_EXPANDABLE_DETAILS_PROPS } from './constants/financing-ppi-contracting-step2-expandable-details.constants';

const FinancingPPIContractingStep2ExpandableDetailsComponent = (): React.ReactElement => (
  <FinancingPPIContractingStep2FeaturesSectionComponent
    {...FINANCING_PPI_CONTRACTING_STEP_2_EXPANDABLE_DETAILS_PROPS}
  />
);

export { FinancingPPIContractingStep2ExpandableDetailsComponent };
