// Enumerations
import { ConsentPPIStep2IdsEnumeration } from 'containers/financing/views/ppi/components/contracting/cpi/components/step2/enumerations/consent-ppi-step2-ids.enumeration';
// Translations
import { TNC_CONSENT } from '../translations/financing-ppi-contracting-step2-tnc.translations';

const FINANCING_PPI_CONTRACTING_STEP_2_TNC_PROPS = {
  consentId: ConsentPPIStep2IdsEnumeration.TNC,
  label: TNC_CONSENT,
};

export { FINANCING_PPI_CONTRACTING_STEP_2_TNC_PROPS };
