// Vendors
import React from 'react';
// Components
import { AddressModalInputsTextComponent } from '../text/address-modal-inputs-text.component';
import { AddressModalInputsStreetComponent } from '../street/address-modal-inputs-street.component';
// Constants
import { NUMBER_PROPS, ZIP_PROPS } from '../inputs/constants/address-modal-inputs.constants';
import {
  CITY_PROPS,
  DOOR_NUMBER_PROPS,
  STAIRCASE_PROPS,
} from './constants/address-modal-inputs-at.constants';
// Styles
import { AddressModalInputsStyled } from '../inputs/address-modal-inputs.component.styled';
// Types
import { AddressModalInputsComponentType } from '../inputs/types/address-modal-inputs.component.type';
// Utils
import { getATInputsRules } from '../../utils/address-modal-inputs.utils';

export const AddressModalInputsATComponent = ({
  addressValidators,
  country,
  errors: { street, number, zip, city, staircase, doorNumber },
  onError,
  ...rest
}: AddressModalInputsComponentType): React.ReactElement | null => {
  if (!addressValidators) {
    return null;
  }

  const rules = getATInputsRules({ addressValidators, onError });

  return (
    <AddressModalInputsStyled>
      <AddressModalInputsStreetComponent
        {...rest}
        country={country}
        errorMessage={street?.message}
        rules={rules.street}
      />
      <AddressModalInputsTextComponent
        {...{ ...NUMBER_PROPS, ...rest }}
        errorMessage={number?.message}
        rules={rules.number}
      />
      <AddressModalInputsTextComponent
        {...{ ...STAIRCASE_PROPS, ...rest }}
        errorMessage={staircase?.message}
        rules={rules.staircase}
      />
      <AddressModalInputsTextComponent
        {...{ ...DOOR_NUMBER_PROPS, ...rest }}
        errorMessage={doorNumber?.message}
        rules={rules.doorNumber}
      />
      <AddressModalInputsTextComponent
        {...{ ...ZIP_PROPS, ...rest }}
        rules={rules.zip}
        errorMessage={zip?.message}
      />
      <AddressModalInputsTextComponent
        {...{ ...CITY_PROPS, ...rest }}
        errorMessage={city?.message}
        rules={rules.city}
      />
    </AddressModalInputsStyled>
  );
};
