// Vendors
import React from 'react';
// Components
import { AddressModalInputsCityComponent } from '../city/address-modal-inputs-city.component';
import { AddressModalInputsTextComponent } from '../text/address-modal-inputs-text.component';
import { AddressModalInputsTextAreaComponent } from '../text-area/address-modal-inputs-text-area.component';
import { AddressModalInputsStreetComponent } from '../street/address-modal-inputs-street.component';
// Constants
import {
  ADDITIONAL_PROPS,
  NUMBER_PROPS,
  ZIP_PROPS,
} from './constants/address-modal-inputs.constants';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
// Styles
import { AddressModalInputsStyled } from './address-modal-inputs.component.styled';
// Types
import { AddressModalInputsComponentType } from './types/address-modal-inputs.component.type';
// Utils
import { getInputsRules } from '../../utils/address-modal-inputs.utils';

export const AddressModalInputsComponent = ({
  addressValidators,
  errors: { street, number, zip, city },
  onError,
  ...rest
}: AddressModalInputsComponentType): React.ReactElement | null => {
  if (!addressValidators) {
    return null;
  }

  const rules = getInputsRules({ addressValidators, onError });

  return (
    <AddressModalInputsStyled>
      <AddressModalInputsStreetComponent
        {...rest}
        errorMessage={street?.message}
        rules={rules.street}
      />
      <AddressModalInputsTextComponent
        {...{ ...NUMBER_PROPS, ...rest }}
        errorMessage={number?.message}
        rules={rules.number}
      />
      <AddressModalInputsTextAreaComponent
        {...{ ...ADDITIONAL_PROPS, ...rest }}
        rules={rules.additional}
      />
      <AddressModalInputsTextComponent
        {...{ ...ZIP_PROPS, ...rest }}
        rules={rules.zip}
        errorMessage={zip?.message}
      />
      <AddressModalInputsCityComponent {...rest} errorMessage={city?.message} rules={rules.city} />
    </AddressModalInputsStyled>
  );
};
