// Constants
import { SERVICE_METHOD } from 'constants/service-method.constants';
import { INSURANCE_CONTRACTS_ENDPOINT } from 'containers/financing/views/ppi/services/constants/financing-insurances.services.constants';

const CHALLENGE_PROPS = {
  baseService: INSURANCE_CONTRACTS_ENDPOINT,
  baseServiceMethod: SERVICE_METHOD.POST,
};

export { CHALLENGE_PROPS };
