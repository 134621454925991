// Components
import { FinancingInsurancesContractingLPIStep1Component } from '../components/step1/financing-insurances-contracting-lpi-step1.component';
import { FinancingInsurancesContractingLPIStep2Component } from '../components/step2/financing-insurances-contracting-lpi-step2.component';
import { FinancingInsurancesContractingLPIStep3Component } from '../components/step3/financing-insurances-contracting-lpi-step3.component';
import { FinancingInsurancesContractingLPIStep4Component } from '../components/step4/financing-insurances-contracting-lpi-step4.component';
// Enumerations
import { RoutesEnumeration } from 'enumerations/routes.enumeration';
// Translations
import {
  FIRST_STEP_TITLE,
  SECOND_STEP_TITLE,
  THIRD_STEP_TITLE,
  FOURTH_STEP_TITLE,
  ERROR_DESCRIPTION,
  ERROR_TITLE,
  SUCCESS_DESCRIPTION,
  SUCCESS_TITLE,
  SUCCESS_LINK_LABEL,
  CANCEL_MODAL_TRANSLATIONS,
} from '../translations/financing-insurances-contracting-lpi.translations';
// Utilities
import { generateAbsoluteRoute, generatePathRoute } from 'utils/app-routing.utils';

const FINANCING_CONTRACTING_LPI_WIZARD_PROPS = {
    feedbackConfiguration: {
      errorConfiguration: {
        description: ERROR_DESCRIPTION,
        title: ERROR_TITLE,
        exitRoute: generatePathRoute([RoutesEnumeration.FINANCING, RoutesEnumeration.INSURANCES]),
      },
      successConfiguration: {
        description: SUCCESS_DESCRIPTION,
        linkLabel: SUCCESS_LINK_LABEL,
        title: SUCCESS_TITLE,
        exitRoute: generatePathRoute([RoutesEnumeration.FINANCING, RoutesEnumeration.INSURANCES]),
      },
    },
    steps: [
      {
        component: FinancingInsurancesContractingLPIStep1Component,
        title: FIRST_STEP_TITLE,
      },
      {
        component: FinancingInsurancesContractingLPIStep2Component,
        title: SECOND_STEP_TITLE,
      },
      {
        component: FinancingInsurancesContractingLPIStep3Component,
        title: THIRD_STEP_TITLE,
      },
      {
        component: FinancingInsurancesContractingLPIStep4Component,
        title: FOURTH_STEP_TITLE,
      },
    ],
  },
  FINANCING_CONTRACTING_LPI_COMMON_CANCEL_PROPS = {
    cancelModal: {
      cancelUrl: generateAbsoluteRoute(
        generatePathRoute([RoutesEnumeration.FINANCING, RoutesEnumeration.INSURANCES])
      ),
      translations: CANCEL_MODAL_TRANSLATIONS,
    },
  },
  INSURANCE_TYPE = 'LPI';

export {
  FINANCING_CONTRACTING_LPI_WIZARD_PROPS,
  FINANCING_CONTRACTING_LPI_COMMON_CANCEL_PROPS,
  INSURANCE_TYPE,
};
