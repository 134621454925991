// Vendors
import { apiUtilsCb } from '@openbank/cf-ui-architecture-core';
// Constants
import { PROPOSITION_AREAS_LATERAL_BANNER_URL } from './constants/banner.service.constants';
import { LATERAL_BANNER_QUERY_PARAMS } from './constants/query-params.contants';
// Enumerations
import { StorageLocalEnum } from '@openbank/cf-ui-static-data';
// Types
import { BannerServiceType } from './types/banner.service.type';

export const propositionAreasLateralBannerService = async (): Promise<
  [BannerServiceType | null, Response]
> =>
  apiUtilsCb.get({
    endpoint: PROPOSITION_AREAS_LATERAL_BANNER_URL,
    language: localStorage.getItem(StorageLocalEnum.LANGUAGE),
    queryParams: LATERAL_BANNER_QUERY_PARAMS,
  });
