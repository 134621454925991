export enum FeatureFlagsEnumeration {
  CAPCTHA_LOGIN = 'amazon_landing_captcha_login',
  CAPTCHA_CHANGE_ACCESS_KEY = 'amazon_landing_captcha_recover_access_key',
  CREATE_EPP = 'amazon_cards_create_installment_plan',
  OPEN_DISPUTE = 'amazon_cards_report_problem',
  DOWNLOAD_TRANSACTIONS = 'amazon_cards_download_transactions_file',
  NOTIFICATION_SETTING = 'amazon_account_notification_setting',
  THIRD_PARTY_DOWN = 'amazon_cards_third_party_down',
  INSURANCES_CPI = 'amazon_cards_insurances_cpi',
  INSURANCES_LPI = 'amazon_cards_insurances_lpi',
  TEMPORAL_ADDRESS = 'amazon_cards_temporal_address',
}
