// Vendors
import { DownloadDocumentStateEnumeration } from '@openbank/cb-ui-commons';
// Types
import { GetDownloadStateType } from './types/get-download-state.type';
import { GetPlanDetailType } from './types/get-plan-detail.type';
import { AvailablePlanType } from 'types/insurances/lpi-available-plan.type';

const getDownloadState = ({ downloading, downloadedError }: GetDownloadStateType): string => {
  if (downloading) {
    return DownloadDocumentStateEnumeration.DOWNLOADING;
  }

  if (downloadedError) {
    return DownloadDocumentStateEnumeration.RETRY_DOWNLOAD;
  }

  return DownloadDocumentStateEnumeration.DOWNLOAD;
};

const getPlanDetail = ({
  insurancesInfo,
  planId,
}: GetPlanDetailType): AvailablePlanType | undefined =>
  insurancesInfo?.insurances?.LPI?.availablePlans?.find(({ code }) => code === planId);

export { getDownloadState, getPlanDetail };
