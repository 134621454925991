// Vendors
import { useState } from 'react';
// Contexts
import { ToastContextConsumerHook } from 'contexts/toast.context';
import { useFinancingInsurancesContractingLPIContextConsumerHook } from 'containers/financing/views/ppi/components/contracting/lpi/contexts/financing-insurances-contracting-lpi.context';
import { useInsurancesContextConsumerHook } from 'contexts/insurances/insurances.context';
// Handlers
import { FinancingInsurancesContractingLPIStep3DownloadHandlers } from '../handlers/financing-insurances-contracting-lpi-step3-download.handlers';
import { FinancingInsurancesContractingLPIStep3DownloadTrackingHandlers } from '../handlers/financing-insurances-contracting-lpi-step3-download.handlers.tracking';
// Hooks
import { useAddressHook } from 'hooks/address.hook';
import AppTrackingHook from 'tracking/app.tracking.hook';
import useCbIntl from 'hooks/useCbIntl';
// Types
import { FinancingInsurancesContratingLPIStep3DownloadHookType } from './types/financing-insurances-contracting-lpi-step3-download.hook.type';
import { FinancingInsurancesContratingLPIStep3DownloadReturnHookType } from './types/financing-insurances-contracting-lpi-step3-download-return.hook.type';

export const useFinancingInsurancesContractingLPIStep3Download = ({
  handleToggleDownloaded,
}: FinancingInsurancesContratingLPIStep3DownloadHookType): FinancingInsurancesContratingLPIStep3DownloadReturnHookType => {
  const [downloading, setDownloading] = useState(false);

  const { formatMessage } = useCbIntl();
  const { address } = useAddressHook();

  const { setToastConfiguration } = ToastContextConsumerHook();
  const { insurancesInfo } = useInsurancesContextConsumerHook(),
    { productId, productType } = insurancesInfo?.insurances?.LPI || {};
  const { postalDocumentation, setPostalDocumentation, plan } =
    useFinancingInsurancesContractingLPIContextConsumerHook();

  return {
    ...FinancingInsurancesContractingLPIStep3DownloadHandlers({
      ...AppTrackingHook(FinancingInsurancesContractingLPIStep3DownloadTrackingHandlers),
      handleToggleDownloaded,
      plan,
      productId,
      productType,
      setDownloading,
      setToastConfiguration,
      setPostalDocumentation,
    }),
    address,
    downloading,
    formatMessage,
    postalDocumentation,
  };
};
