// Vendors
import { TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
// Constants
import { CREDIT_LIMIT_INCREASE_LINK_CLICK_TRACKING_PROPS } from './constants/financing-insurances-contracting-lpi-step2-plans-error.handlers.tracking.constants';
// Types
import { FinancingInsurancesContractingLPIStep2PlansErrorReturnTrackingHandlersType } from './types/financing-insurances-contracting-lpi-step2-plans-error-return.handlers.tracking.type';
import { TrackBuilderType } from 'types/tracking/track-builder.type';

const creditLimitIncreaseLinkClickTrackingHandler = (): TrackingLinkEventReturnType =>
  CREDIT_LIMIT_INCREASE_LINK_CLICK_TRACKING_PROPS;

export const FinancingInsurancesContractingLPIStep2PlansErrorTrackingHandlers = (
  track: TrackBuilderType
): FinancingInsurancesContractingLPIStep2PlansErrorReturnTrackingHandlersType => ({
  handleCreditLimitIncreaseLinkClickTracking: () =>
    track(creditLimitIncreaseLinkClickTrackingHandler()),
});
