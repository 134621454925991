// Constants
import { buttonConstants } from '@openbank/cb-ui-commons';
import { ALLOWED_PIXEL_RATIO } from './constants/banner.component.handler.utils.constants';
import { NUMBER } from 'constants/number.constants';
// Translations
import translations from './../../translations/banner.translations';
// Types
import { GetBannerPropsUtilType } from './types/banner.component.handler.utils.type';
import { AppBannerPropsType } from 'components/banner/hooks/types/banner.component.hook.types';
import { GetBannerPropsFromResponseType } from './types/get-banner-props-from-response.type';

const getDevicePixelRatio = (): number => {
  const devicePixelRatio = Math.trunc(window.devicePixelRatio ?? NUMBER.TWO);
  return ALLOWED_PIXEL_RATIO.includes(devicePixelRatio) ? devicePixelRatio : NUMBER.TWO;
};

const onClickCtaRedirection = ({ formatMessage, navigate, url }: GetBannerPropsUtilType) => {
  const redirectUrl = formatMessage({ id: translations.CTA_LINK });
  if (url || redirectUrl.includes('http')) {
    window.open(url || redirectUrl);
  } else {
    navigate(redirectUrl);
  }
};

const getButtonConfiguration = ({ formatMessage, navigate }: GetBannerPropsUtilType) => ({
  size: buttonConstants.SIZES.SMALL,
  children: formatMessage({ id: translations.CTA }),
  onClick: () => onClickCtaRedirection({ formatMessage, navigate }),
});

export const getDefaultBannerProps = ({
  formatMessage,
  navigate,
}: GetBannerPropsUtilType): AppBannerPropsType => ({
  backgroundImage: formatMessage(
    { id: translations.IMAGE_URL },
    {
      devicePixelRatio: getDevicePixelRatio(),
      serverAssetsPath: window.ASSETS_SERVER_PATH,
    }
  ),
  title: formatMessage({ id: translations.TITLE }),
  buttonConfiguration: getButtonConfiguration({ formatMessage, navigate }),
});

export const getBannerPropsFromResponse = ({
  channel,
  response,
  formatMessage,
  navigate,
  handleClickBannerButtonTracking,
}: GetBannerPropsFromResponseType): AppBannerPropsType => {
  const banner = response.propositions[0];
  const { content } = banner;
  const image = content.images.find(img => img.channel === channel) || content.images[0];

  return {
    backgroundImage: image.imageUrl,
    title: content.title,
    description: content.description,
    loading: false,
    testId: banner.locationId,
    buttonConfiguration: {
      size: buttonConstants.SIZES.SMALL,
      isInverted: content.button.inverted,
      children: content.button.text,
      onClick: () => {
        handleClickBannerButtonTracking(response);
        onClickCtaRedirection({ formatMessage, navigate, url: content.button.buttonUrl });
      },
    },
  };
};
