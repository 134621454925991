// Constants
import { VALID_STREET_TYPES } from './constants/use-address-predictions.handlers.utils.constants';

const filterPredictionsWithStreet = (
  predictions: google.maps.places.AutocompletePrediction[]
): google.maps.places.AutocompletePrediction[] =>
  predictions.filter(prediction =>
    prediction.types.some(type => VALID_STREET_TYPES.includes(type))
  );

export { filterPredictionsWithStreet };
