// Enumerations
import { InputNamesEnumeration } from 'components/address-modal/enums/input-names.enumeration';
// Translations
import { ERROR_MESSAGES } from '../../translations/address-modal-inputs.translations';

const VALIDATE_CITY_WITH_POSTCODE_DEBOUNCED_TIME = 300;

const ZIP_LENGTH = 5;

const RULES_REGEX_FLAGS = 'g';

const NUMBER_RULES = {
  required: ERROR_MESSAGES.REQUIRED,
  maxLength: {
    message: ERROR_MESSAGES.NUMBER_MAX_LENGTH,
    value: 8,
  },
};

const ZIP_RULES = {
  required: ERROR_MESSAGES.REQUIRED,
  maxLength: {
    message: ERROR_MESSAGES.ZIP_MAX_LENGTH,
    value: ZIP_LENGTH,
  },
  minLength: {
    message: ERROR_MESSAGES.ZIP_MAX_LENGTH,
    value: ZIP_LENGTH,
  },
};

const AT_ZIP_RULES = {
  required: ERROR_MESSAGES.REQUIRED,
  maxLength: {
    message: ERROR_MESSAGES.ZIP_MAX_LENGTH_AT,
    value: 4,
  },
  minLength: {
    message: ERROR_MESSAGES.ZIP_MAX_LENGTH_AT,
    value: 4,
  },
};

const STREET_RULES = {
  required: ERROR_MESSAGES.REQUIRED,
  maxLength: {
    message: ERROR_MESSAGES.STREET_MAX_LENGTH,
    value: 50,
  },
};

const AT_STREET_RULES = {
  required: ERROR_MESSAGES.REQUIRED,
  maxLength: {
    message: ERROR_MESSAGES.STREET_MAX_LENGTH_AT,
    value: 40,
  },
};

const CITY_RULES = {
  deps: [InputNamesEnumeration.ZIP],
  required: ERROR_MESSAGES.REQUIRED,
};

const STAIRCASE_RULES = {
  maxLength: {
    message: ERROR_MESSAGES.STAIRCASE_MAX_LENGTH,
    value: 3,
  },
};

const DOOR_NUMBER_RULES = {
  maxLength: {
    message: ERROR_MESSAGES.DOOR_NUMBER_MAX_LENGTH,
    value: 6,
  },
};

const AT_CITY_RULES = {
  required: ERROR_MESSAGES.REQUIRED,
  maxLength: {
    message: ERROR_MESSAGES.CITY_MAX_LENGTH_AT,
    value: 35,
  },
};

export {
  AT_CITY_RULES,
  AT_STREET_RULES,
  AT_ZIP_RULES,
  CITY_RULES,
  DOOR_NUMBER_RULES,
  NUMBER_RULES,
  RULES_REGEX_FLAGS,
  STAIRCASE_RULES,
  STREET_RULES,
  VALIDATE_CITY_WITH_POSTCODE_DEBOUNCED_TIME,
  ZIP_RULES,
};
