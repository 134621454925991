// Vendors
import { TrackingEventsEnum, TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';

const TOGGLE_POSTAL_DOCUMENTATION_EVENT_ACTION = 'receiveDocumentationViaPostCheckBox';

const DOWNLOAD_TRACKING_PROPS: TrackingLinkEventReturnType = {
  payload: {
    eventAction: 'clickLink',
    eventLabel: 'downloadDocumentLink',
  },
  type: TrackingEventsEnum.LINK,
};

const DOWNLOAD_TOAST_EVENT_LABEL = 'view';

const CLOSE_DOWNLOAD_TOAST_EVENT_LABEL = 'close';

const DOWNLOAD_SUCCESS_EVENT_ACTION = 'successSnackbarDocumentationDownloaded';

const DOWNLOAD_ERROR_EVENT_ACTION = 'successSnackbarErrorDownloadingDocument';

export {
  CLOSE_DOWNLOAD_TOAST_EVENT_LABEL,
  DOWNLOAD_ERROR_EVENT_ACTION,
  DOWNLOAD_SUCCESS_EVENT_ACTION,
  DOWNLOAD_TOAST_EVENT_LABEL,
  DOWNLOAD_TRACKING_PROPS,
  TOGGLE_POSTAL_DOCUMENTATION_EVENT_ACTION,
};
