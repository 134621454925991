export enum UploadDocumentIdsEnumeration {
  MAIN_BANK_ACCOUNT = 'main-bank-account',
  LAST_SALARY = 'last-salary',
  PREVIOUS_SALARY = 'previous-salary',
  BEFORE_PREVIOUS_SALARY = 'before-previous-salary',
  ANNUAL_PENSION = 'annual-pension',
  LAST_CERTIFICATE_TAX = 'last-certificate-tax',
  LAST_BWA = 'last-bwa',
  LAST_INCOME_TAX = 'last-income-tax',
}
