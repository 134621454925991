// Translations
import { CITY } from 'components/address-modal/translations/address-modal-inputs.translations';

const DOMAIN_INPUTS_CITY =
  'cobranded.services.userSettings.privateArea.cbModalAddress.inputs.city.';

const CITIES_ERROR = {
  noResultsTitle: `${DOMAIN_INPUTS_CITY}apiError`,
  noResultsDescription: `${DOMAIN_INPUTS_CITY}apiErrorDescription`,
  noResultsLink: `${DOMAIN_INPUTS_CITY}apiErrorLink`,
};

const MIN_LENGTH_ERROR = {
  noResultsTitle: `${DOMAIN_INPUTS_CITY}minLength`,
};

const CITY_INPUT_TRANSLATIONS = {
  ...CITY,
  placeholder: `${DOMAIN_INPUTS_CITY}placeholder`,
};

export { CITY_INPUT_TRANSLATIONS, CITIES_ERROR, MIN_LENGTH_ERROR };
