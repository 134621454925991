const DOMAIN =
  'cobranded.services.userSettings.privateArea.cbGeneric.cbInsterstitial.docExpiredModal.';

const BODY_PARAGRAPH_1 = `${DOMAIN}bodyDescription1`;
const BODY_PARAGRAPH_2 = `${DOMAIN}bodyDescription2`;

const BODY_PARAGRAPH_3 = `${DOMAIN}bodyDescription3`;

const DOC_EXPIRED_TITLE = `${DOMAIN}title`;

const GOT_TO_PERSONAL_DATA = `${DOMAIN}buttonDescription`;

const NO_CONTINUE = `${DOMAIN}linkDescription`;

export {
  BODY_PARAGRAPH_1,
  BODY_PARAGRAPH_2,
  BODY_PARAGRAPH_3,
  DOC_EXPIRED_TITLE,
  GOT_TO_PERSONAL_DATA,
  NO_CONTINUE,
};
