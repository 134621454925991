// Vendors
import { TrackingEventsEnum, TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';

const NEXT_BUTTON_CLICK_DOWNLOAD_ERROR_TRACKING_PROPS: TrackingLinkEventReturnType = {
  payload: {
    eventAction: 'clickBox',
    eventLabel: 'box - error',
  },
  type: TrackingEventsEnum.LINK,
};

export { NEXT_BUTTON_CLICK_DOWNLOAD_ERROR_TRACKING_PROPS };
