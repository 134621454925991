// Vendors
import { TrackingEventsEnum, TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
// Constants
import { SELECT_PLAN_EVENT_ACTION } from './constants/financing-insurances-contracting-lpi-step2-plans.handlers.tracking.constants';
// Types
import { FinancingInsurancesContractingLPIStep2PlansReturnTrackingHandlersType } from './types/financing-insurances-contracting-lpi-step2-plans-return.handlers.tracking.type';
import { TrackBuilderType } from 'types/tracking/track-builder.type';

const selectPlanTrackingHandler = (coverage: number): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: SELECT_PLAN_EVENT_ACTION,
    eventLabel: `coverage${coverage}perMonthBox`,
  },
  type: TrackingEventsEnum.LINK,
});

export const FinancingInsurancesContractingLPIStep2PlansTrackingHandlers = (
  track: TrackBuilderType
): FinancingInsurancesContractingLPIStep2PlansReturnTrackingHandlersType => ({
  handleSelectPlanTracking: coverage => track(selectPlanTrackingHandler(coverage)),
});
