// Constants
import {
  ON_CLOSE_PAYLOAD,
  ON_EXIT_PAYLOAD,
  ON_GO_TO_PERSONAL_DATA_PAYLOAD,
  ON_LOAD_PAYLOAD,
} from './constants/modal-interstitial-doc-expired.handlers.tracking.constants';
// Enumerations
import { TrackingEventsEnum, TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
// Types
import { InterstitialDocExpiredTrackingHandlersType } from './types/modal-interstitial-doc-expired.handlers.tracking.type';
import { TrackBuilderType } from 'types/tracking/track-builder.type';

const onLoadDocExpiredModalTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: ON_LOAD_PAYLOAD,
  type: TrackingEventsEnum.LINK,
});

const onCloseDocExpiredModalTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: ON_CLOSE_PAYLOAD,
  type: TrackingEventsEnum.LINK,
});

const onExitDocExpiredModalTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: ON_EXIT_PAYLOAD,
  type: TrackingEventsEnum.LINK,
});

const onGoToPersonalDataButtonClickTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: ON_GO_TO_PERSONAL_DATA_PAYLOAD,
  type: TrackingEventsEnum.LINK,
});

export const InterstitialDocExpiredTrackingHandlers = (
  track: TrackBuilderType
): InterstitialDocExpiredTrackingHandlersType => ({
  handleOnLoadDocExpiredModalTracking: () => track(onLoadDocExpiredModalTrackingHandler()),
  handleOnCloseDocExpiredModalTracking: () => track(onCloseDocExpiredModalTrackingHandler()),
  handleOnExitDocExpiredModalTracking: () => track(onExitDocExpiredModalTrackingHandler()),
  handleOnGoToPersonalDataButtonClickTracking: () =>
    track(onGoToPersonalDataButtonClickTrackingHandler()),
});
