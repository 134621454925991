// Enumerations
import { RoutesEnumeration } from 'enumerations/routes.enumeration';
// Types
import { RouteType } from 'types/react-router-dom/route-configuration.type';
// Views
import CardSettingsActivateCardComponent from 'containers/card-settings/views/activate-card/card-settings-activate-card.component';
import CardSettingsLandingContainer from 'containers/card-settings/views/landing/card-settings-landing.container';
import CardSettingsBlockCardComponent from 'containers/card-settings/views/block-card/card-settings-block-card.component';
import SettingsCardControlView from 'containers/card-settings/views/card-control/settings-card-control.view';
import CardSettingsContractView from 'containers/card-settings/views/contract/card-settings-contract.view';
import { WorkInProgressComponent } from 'components/working-in-progress/work-in-progress.component';
import { CardSettingsStatementsView } from 'containers/card-settings/views/statements/card-settings-statements.view';
import CardSettingsRouterInactiveCardRoutesComponent from '../components/inactive-card-routes/card-settings-router-inactive-card-routes.component';
import { CardSettingsNewCardView } from 'containers/card-settings/views/new-card/card-settings-new-card.view';
import { CardSettingsBenefitsInsuranceComponent } from 'containers/card-settings/views/benefits/card-settings-benefits.component';

const CARD_SETTINGS_ROUTER_ROUTES: RouteType[] = [
  {
    element: CardSettingsActivateCardComponent,
    guard: CardSettingsRouterInactiveCardRoutesComponent,
    props: { path: RoutesEnumeration.ACTIVATE },
  },
  { element: CardSettingsBlockCardComponent, props: { path: RoutesEnumeration.BLOCK } },
  {
    element: CardSettingsBenefitsInsuranceComponent,
    props: { path: RoutesEnumeration.BENEFITS_INSURANCE },
  },
  { element: CardSettingsStatementsView, props: { path: RoutesEnumeration.CARD_STATEMENTS } },
  { element: SettingsCardControlView, props: { path: RoutesEnumeration.CARD_CONTROL } },
  { element: CardSettingsContractView, props: { path: RoutesEnumeration.CONTRACT } },
  { element: CardSettingsNewCardView, props: { path: RoutesEnumeration.REQUEST_NEW_CARD } },
  { element: CardSettingsLandingContainer, props: { index: true } },
];

export { CARD_SETTINGS_ROUTER_ROUTES };
