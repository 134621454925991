// Vendors
import { AmazonTheme } from '@openbank/cf-ui-static-data';

const INSURANCE_BENEFITS_CONSTANTS = {
  ASSETS: {
    FIRST: {
      id: 'security',
    },
    SECOND: {
      id: 'hands-giving',
    },
    THIRD: {
      id: 'waiting',
    },
    FOURTH: {
      id: 'customize',
    },
  },
  GAP: { gap: AmazonTheme.default.spaces.spacingXxs },
};

export { INSURANCE_BENEFITS_CONSTANTS };
