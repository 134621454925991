// Vendors
import { TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
// Constants
import { TNC_LINK_CLICK_TRACKING_PROPS } from './constants/financing-insurances-contracting-lpi-benefits.handlers.tracking.constants';
// Types
import { FinancingInsurancesContractingLPIBenefitsReturnTrackingHandlersType } from './types/financing-insurances-contracting-lpi-benefits-return.handlers.tracking.type';
import { TrackBuilderType } from 'types/tracking/track-builder.type';

const tncLinkClickTrackingHandler = (): TrackingLinkEventReturnType =>
  TNC_LINK_CLICK_TRACKING_PROPS;

export const FinancingInsurancesContractingLPIBenefitsTrackingHandlers = (
  track: TrackBuilderType
): FinancingInsurancesContractingLPIBenefitsReturnTrackingHandlersType => ({
  handleTncLinkClickTracking: () => track(tncLinkClickTrackingHandler()),
});
