// Vendors
import React from 'react';
// Components
import { FinancingPPIContractingStep2ExpandableDescriptionLinkComponent } from '../description-link/financing-ppi-contracting-step2-expandable-description-link.component';
// Constants
import { FINANCING_PPI_CONTRACTING_STEP_2_COVERAGE_CIRCUMSTANCES_PROPS } from 'containers/financing/views/ppi/components/contracting/cpi/components/step2/components/expandable/components/circumstances/constants/financing-ppi-contracting-step2-expandable-circumstances.constants';

const FinancingPpiContractingStep2ExpandableCircumstancesComponent = (): React.ReactElement => (
  <FinancingPPIContractingStep2ExpandableDescriptionLinkComponent
    {...FINANCING_PPI_CONTRACTING_STEP_2_COVERAGE_CIRCUMSTANCES_PROPS}
  />
);

export { FinancingPpiContractingStep2ExpandableCircumstancesComponent };
