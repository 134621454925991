// Vendors
import React from 'react';
// Components
import { ModalComponent, ParagraphComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import { PARAGRAPH_3_PROPS } from './constants/modal-interstitial-doc-expired.constants';
// Hooks
import { useInterstitialDocExpiredHook } from './hooks/modal-interstitial-doc-expired.hook';
// Styled
import { ModalInterstitialDocExpiredComponentStyled } from './modal-interstitial-doc-expired.component.styled';
// Translations
import {
  BODY_PARAGRAPH_1,
  BODY_PARAGRAPH_2,
  BODY_PARAGRAPH_3,
} from './translations/modal-interstitial-doc-expired.translations';
// Types
import { ModalInterstitialDocExpiredComponentPropsType } from './types/modal-interstitial-doc-expired.component.type';

const InterstitialDocExpiredComponent = ({
  onClose,
}: ModalInterstitialDocExpiredComponentPropsType): React.ReactElement | null => {
  const { modalConfiguration } = useInterstitialDocExpiredHook(onClose);

  return (
    <ModalComponent {...modalConfiguration}>
      <ModalInterstitialDocExpiredComponentStyled>
        <ParagraphComponent>
          <FormattedMessageComponent id={BODY_PARAGRAPH_1} />
        </ParagraphComponent>
        <ParagraphComponent>
          <FormattedMessageComponent id={BODY_PARAGRAPH_2} />
        </ParagraphComponent>
        <ParagraphComponent {...PARAGRAPH_3_PROPS}>
          <FormattedMessageComponent id={BODY_PARAGRAPH_3} />
        </ParagraphComponent>
      </ModalInterstitialDocExpiredComponentStyled>
    </ModalComponent>
  );
};

export { InterstitialDocExpiredComponent };
