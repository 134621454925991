// Vendors
import { useState } from 'react';
import useCbIntl from 'hooks/useCbIntl';
// Handlers
import { AppNoUserDataHeaderHandlers } from '../handlers/header.handlers';
import LogoutHandlers from 'handlers/logout.handlers';
// Hooks
import { MobileConnectorContextConsumerHook } from 'providers/mobile-connector/contexts/mobile-connector.provider.context';
// Types
import { AppNoUserDataHeaderHookReturnType } from './types/header.hook.return.type';

const AppNoUserDataHeaderHook = (): AppNoUserDataHeaderHookReturnType => {
  const { formatMessage } = useCbIntl();
  const [showLogOutModal, setShowLogOutModal] = useState<boolean>(false);
  const { isHybrid } = MobileConnectorContextConsumerHook();

  const { handleMakeUserLogoutClick } = AppNoUserDataHeaderHandlers({
    ...LogoutHandlers(),
  });

  return {
    isHybrid,
    formatMessage,
    showLogOutModal,
    handleMakeUserLogoutClick,
    handleMakeUserCancelClickHandler: () => setShowLogOutModal(false),
    handleMakeUserLogoutButtonClick: () => setShowLogOutModal(true),
  };
};

export { AppNoUserDataHeaderHook };
