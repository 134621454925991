// Vendors
import React from 'react';
// Contexts
import { usePersonalNumberStep1ContextConsumerHook } from 'containers/personal/views/number/components/step1/contexts/personal-number-step1.context';
// Components
import WizardStepFooterComponent from 'components/wizard-step-footer/wizard-step-footer.component';
// Constants
import { FIRST_STEP_TARGETS } from 'constants/wizard/wizard.constants';
// Utilities
import { modifyPersonalPhoneStep1NextButtonIsDisabled } from './utils/personal-number-step1-actions.utils';

const PersonalNumberStep1ActionsComponent = (): React.ReactElement => {
  const { phoneNumber, repeatPhoneNumber, prefix, repeatPrefix } =
    usePersonalNumberStep1ContextConsumerHook();

  return (
    <WizardStepFooterComponent
      shouldShowModal={Boolean(phoneNumber)}
      rightButtonConfiguration={{
        disabled: modifyPersonalPhoneStep1NextButtonIsDisabled({
          phoneNumber,
          repeatPhoneNumber,
          prefix,
          repeatPrefix,
        }),
      }}
      targets={FIRST_STEP_TARGETS}
    />
  );
};

export default PersonalNumberStep1ActionsComponent;
