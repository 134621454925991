// Translations
import { COUNTRIES_PREFFIX } from 'translations/locations.translations';
// Types
import { ProfilePersonalDataAddressUtilsType } from './types/profile-personal-data-address.utils.type';
import { formatAddressAdditionalFields } from 'utils/addresses/addresses.utils';

const createMappedPrimaryResidentialAddress = ({
  formatMessage,
  address: {
    countryCode,
    number,
    postCode,
    streetLine1,
    town,
    floor,
    department,
    additionalItems1,
  },
}: ProfilePersonalDataAddressUtilsType): string =>
  `${streetLine1} ${number},${formatAddressAdditionalFields({
    additionalItems1,
    department,
    floor,
    formatMessage,
  })}\n${postCode} ${town}\n${formatMessage({
    id: `${COUNTRIES_PREFFIX}${countryCode}`,
  })}`;

export { createMappedPrimaryResidentialAddress };
