// Vendors
import styled from 'styled-components';
// Utils
import { mobileMediaQuery, tabletMediaQuery } from '@openbank/cf-ui-framework';

const TitleSectionRwdComponentStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingS};

  ${tabletMediaQuery} {
    padding: 0 ${({ theme }) => theme.spaces.spacingL};
  }

  ${mobileMediaQuery} {
    padding: ${({ theme }) => theme.spaces.spacingM} ${({ theme }) => theme.spaces.spacingS}
      ${({ theme }) => theme.spaces.spacingXs};
    width: 100%;
  }
`;

const TitleSectionTopComponentStyled = styled.header`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  gap: ${({ theme }) => theme.spaces.spacingS};
`;

const TitleSectionTopContentComponentStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingXxs};
`;

export {
  TitleSectionRwdComponentStyled,
  TitleSectionTopComponentStyled,
  TitleSectionTopContentComponentStyled,
};
