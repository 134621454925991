// Vendors
import React, { lazy } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
// Components
import { AppNoUserDataHeaderComponent } from './components/header/header.component';
import TitleSectionComponent from 'components/title-section/title-section.component';
// Constants
import {
  ACCESS_KEY_ROUTE_PROPS,
  ANY_PROSPECT_ROUTE_PROPS,
  ANY_PROSPECT_NAVIGATE_ROUTE_PROPS,
} from './constants/app-no-user-data.constants';
// Styles
import {
  AppNoUserDataContainerStyled,
  AppNoUserDataContentContainerStyled,
} from './app-no-user-data.component.styled';
// Hooks
import { AppNoUserDataHook } from './hooks/app-no-user-data.hook';
// Containers
const ChangeAccessKeyContainer = lazy(
  () =>
    import('containers/security-keys/views/access-key-change/security-keys-access-key-change.view')
);

const AppNoUserDataComponent = (): React.ReactElement => {
  const {
    isMobileOrTablet,
    showExpiredAccessKeyModal,
    leftArrowClick,
    onAcceptExpiredAccessKeyModal,
  } = AppNoUserDataHook();

  return (
    <AppNoUserDataContainerStyled>
      <AppNoUserDataHeaderComponent
        {...{
          isMobileOrTablet,
          showExpiredAccessKeyModal,
          onAcceptExpiredAccessKeyModal,
        }}
      />
      <AppNoUserDataContentContainerStyled {...{ isMobileOrTablet }}>
        {!showExpiredAccessKeyModal && (
          <>
            <TitleSectionComponent {...{ leftArrowClick, showBreadcrumbs: false }} />
            <Routes>
              <Route {...ACCESS_KEY_ROUTE_PROPS} element={<ChangeAccessKeyContainer />} />
              <Route
                {...ANY_PROSPECT_ROUTE_PROPS}
                element={<Navigate {...ANY_PROSPECT_NAVIGATE_ROUTE_PROPS} />}
              />
            </Routes>
          </>
        )}
      </AppNoUserDataContentContainerStyled>
    </AppNoUserDataContainerStyled>
  );
};

export default AppNoUserDataComponent;
