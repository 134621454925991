// Vendors
import React from 'react';
// Components
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import { LinkComponent } from '@openbank/cb-ui-commons';
// Constants
import { TRAVEL_PLUS_LINKS_ITEMS } from './constants/travel-plus-links.constants';
// Styles
import {
  TravelPlusLinksComponentDividerStyled,
  TravelPlusLinksComponentStyled,
} from './travel-plus-links.component.styled';
// Hooks
import { useTravelPlusMessageHook } from './hooks/travel-plus-links.hook';
// Types
import { TravelPlusLinksItemType } from './types/travel-plus-links-item.type';

const TravelPlusLinksComponent = (): React.ReactElement | null => {
  const { handleOpenTravelPlusModalLinkClick, status } = useTravelPlusMessageHook();
  
  return (
    <TravelPlusLinksComponentStyled>
      {TRAVEL_PLUS_LINKS_ITEMS[status].map(
        ({ id, text, ...props }: TravelPlusLinksItemType, key: number) => (
          <React.Fragment key={key}>
            <LinkComponent
              {...props}
              onClick={() => handleOpenTravelPlusModalLinkClick({ id, text })}
            >
              <FormattedMessageComponent id={text} />
            </LinkComponent>
            <TravelPlusLinksComponentDividerStyled
              $length={TRAVEL_PLUS_LINKS_ITEMS[status].length}
              $position={key}
            />
          </React.Fragment>
        )
      )}
    </TravelPlusLinksComponentStyled>
  );
};

export { TravelPlusLinksComponent };
