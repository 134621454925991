// Vendors
import { TrackingEventsEnum, TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';

const LEAFLET_LINK_CLICK_TRACKING_PROPS: TrackingLinkEventReturnType = {
  payload: {
    eventAction: 'clickLink',
    eventLabel: 'clickHereLink',
  },
  type: TrackingEventsEnum.LINK,
};

export { LEAFLET_LINK_CLICK_TRACKING_PROPS };
