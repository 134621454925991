// Constants
import { THIRD_STEP_TARGETS } from 'constants/wizard/wizard.constants';
// Types
import { FinancingInsurancesContractingLPIStep3ReturnHandlersType } from './types/financing-insurances-contracting-lpi-step3-return.handlers.type';
import { FinancingInsurancesContractingLPIStep3HandlersType } from './types/financing-insurances-contracting-lpi-step3.handlers.type';
import { ToggleReceiptHandlerType } from './types/toggle-receipt-handler.type';
import { ToggleDownloadedHandlerType } from './types/toggle-downloaded-handler.type';
import { NextButtonClickHandlerType } from './types/next-button-click-handler.type';

const toggleDownloadedHandler = ({
  setDownloaded,
  setDownloadedError,
}: ToggleDownloadedHandlerType): void => {
  setDownloaded(true);
  setDownloadedError(false);
};

const toggleReceiptHandler = ({
  handleToggleReceiptTracking,
  setDocsReceived,
}: ToggleReceiptHandlerType): void => {
  setDocsReceived(prev => {
    handleToggleReceiptTracking(!prev);
    return !prev;
  });
};

const nextButtonClickHandler = ({
  handleNextButtonClickDownloadErrorTracking,
  downloaded,
  docsReceived,
  setCurrentStep,
  setDocsReceivedError,
  setDownloadedError,
}: NextButtonClickHandlerType): void => {
  if (!docsReceived || !downloaded) {
    setDocsReceivedError(!docsReceived);
    setDownloadedError(!downloaded);
    !downloaded && handleNextButtonClickDownloadErrorTracking();
    return;
  }

  setCurrentStep(THIRD_STEP_TARGETS.next);
};

export const FinancingInsurancesContractingLPIStep3Handlers = ({
  handleToggleReceiptTracking,
  handleNextButtonClickDownloadErrorTracking,
  docsReceived,
  setCurrentStep,
  setDocsReceived,
  setDocsReceivedError,
  setDownloaded,
  downloaded,
  setDownloadedError,
}: FinancingInsurancesContractingLPIStep3HandlersType): FinancingInsurancesContractingLPIStep3ReturnHandlersType => ({
  handleToggleDownloaded: () => toggleDownloadedHandler({ setDownloaded, setDownloadedError }),
  handleToggleReceipt: () => toggleReceiptHandler({ handleToggleReceiptTracking, setDocsReceived }),
  handleNextButtonClick: () =>
    nextButtonClickHandler({
      docsReceived,
      downloaded,
      handleNextButtonClickDownloadErrorTracking,
      setCurrentStep,
      setDocsReceivedError,
      setDownloadedError,
    }),
});
