// Vendors
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
// Configurations
import { modalExpiredDocConfiguration } from '../configuration/modal-interstitial-doc-expired.configuration';
// Event Handlers
import { InterstitialDocExpiredHandlers } from '../handlers/modal-interstitial-doc-expired.handlers';
import { InterstitialDocExpiredTrackingHandlers } from '../handlers/tracking/modal-interstitial-doc-expired.handlers.tracking';
// Hooks
import useCbIntl from 'hooks/useCbIntl';
import { AppContextConsumerHook } from 'contexts/app.context';
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { UseInterstitialDocExpiredHookType } from './types/modal-interstitial-doc-expired.hook.type';

const useInterstitialDocExpiredHook = (
  onClose: VoidFunction
): UseInterstitialDocExpiredHookType => {
  const navigate = useNavigate();
  const { formatMessage } = useCbIntl();
  const {
    profile: {
      userDetails: { firstName },
    },
  } = AppContextConsumerHook();

  const { handleOnLoadDocExpiredModalTracking, ...restTrackingHandlers } = AppTrackingHook(
    InterstitialDocExpiredTrackingHandlers
  );

  useEffect(() => {
    handleOnLoadDocExpiredModalTracking();
  }, []);

  return {
    modalConfiguration: modalExpiredDocConfiguration({
      ...InterstitialDocExpiredHandlers({ ...restTrackingHandlers, navigate, onClose }),
      formatMessage,
      userName: firstName,
    }),
  };
};

export { useInterstitialDocExpiredHook };
