// Vendors
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
// Configurations
import { getWizardFeedbackConfigurations } from '../configurations/security-keys-access-key-change.configurations';
// Contexts
import { MobileConnectorContextConsumerHook } from 'providers/mobile-connector/contexts/mobile-connector.provider.context';
// Enumerations
import { RoutesEnumeration } from 'enumerations/routes.enumeration';
// Hooks
import { SecurityKeysAccessKeyChangeHook } from './security-keys-access-key-change.hook';
// Types
import { UseSecurityKeysAccessKeyChangeInitHookType } from './types/security-keys-access-key-change-init.hook.type';
// Utilities
import { isAccessKeyExpiredHybrid } from '../utils/security-keys-access-key-change-constraints.util';

export const useSecurityKeysAccessKeyChangeInitHook =
  (): UseSecurityKeysAccessKeyChangeInitHookType => {
    const { isHybrid, onNavigateHybridFlow, onHybridFlow } = MobileConnectorContextConsumerHook();
    const { accessKeyExpired, handleMakeUserLogoutEvent } = SecurityKeysAccessKeyChangeHook();
    const [searchParams] = useSearchParams();

    useEffect(() => {
      onHybridFlow(RoutesEnumeration.ACCESS_KEY);
    }, []);

    return {
      isHybrid,
      wizardFeedbackConfiguration: getWizardFeedbackConfigurations({
        accessKeyExpired: isHybrid ? isAccessKeyExpiredHybrid(searchParams) : accessKeyExpired,
        onLinkClick: isHybrid ? onNavigateHybridFlow : handleMakeUserLogoutEvent,
      }),
    };
  };
