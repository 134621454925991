// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
// Handlers
import { FinancingInsurancesContractingLPIAdditionalCoveredHandlers } from '../handlers/financing-insurances-contracting-lpi-additional-covered.handlers';
import { FinancingInsurancesContractingLPIAdditionalCoveredTrackingHandlers } from '../handlers/financing-insurances-contracting-lpi-additional-covered.handlers.tracking';
// Hooks
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { FinancingInsurancesContractingLPIAdditionalCoveredReturnHandlersType } from '../handlers/types/financing-insurances-contracting-lpi-additional-covered-return.handlers.type';

export const useFinancingInsurancesContractingLPIAdditionalCovered =
  (): FinancingInsurancesContractingLPIAdditionalCoveredReturnHandlersType => {
    const {
      localization: {
        config: { cfg_public_lpi_info_leaflet },
      },
    } = AppContextConsumerHook();

    return FinancingInsurancesContractingLPIAdditionalCoveredHandlers({
      ...AppTrackingHook(FinancingInsurancesContractingLPIAdditionalCoveredTrackingHandlers),
      cfg_public_lpi_info_leaflet,
    });
  };
