// Vendors
import { useState } from 'react';
// Contexts
import { BlockCardContextConsumerHook } from 'components/block-card/contexts/block-card.context';
// Handlers
import { BlockCardAddressHandlers } from '../handlers/block-card-address.handlers';
import { BlockCardAddressTrackingHandlers } from '../handlers/block-card-address.handlers.tracking';
// Hooks
import { useAddressHook } from 'hooks/address.hook';
import AppTrackingHook from 'tracking/app.tracking.hook';
import useCbIntl from 'hooks/useCbIntl';
// Utils
import { getAddressess } from '../utils/block-card-address.utils';
// Types
import { BlockCardAddressHookType } from './types/block-card-address.hook.type';

const BlockCardAddressHook = (): BlockCardAddressHookType => {
  const [showAddressModal, setShowAddressModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const { formatMessage } = useCbIntl();

  const { address, countryOfResidence } = useAddressHook();

  const { selectedAddress, setError, setSelectedAddress, setTempAddress, tempAddress } =
    BlockCardContextConsumerHook();

  const {
    handleCancelDeleteAddress,
    handleCloseAddressModal,
    handleCloseDeleteModal,
    handleDeleteAddress,
    handleOpenAddressModal,
    handleOpenDeleteModal,
    handleOpenEditAddressModal,
    handleSaveAddressButtonClick,
    handleSelectAddress,
  } = BlockCardAddressHandlers({
    ...AppTrackingHook(BlockCardAddressTrackingHandlers),
    address,
    setSelectedAddress,
    setShowAddressModal,
    setShowDeleteModal,
    setTempAddress,
  });
  return {
    addressCardActions: {
      handleSelectAddress,
      handleOpenAddressModal,
      handleOpenEditAddressModal,
      handleOpenDeleteModal,
    },
    addressModalActions: {
      onCancel: handleCloseAddressModal,
      onClose: handleCloseAddressModal,
      onError: () => setError(true),
      onSave: handleSaveAddressButtonClick,
    },
    deleteModalActions: {
      onCancel: handleCancelDeleteAddress,
      onClose: handleCloseDeleteModal,
      onDelete: handleDeleteAddress,
    },
    addresses: getAddressess({
      countryOfResidence,
      address,
      tempAddress,
    }),
    formatMessage,
    selectedAddress,
    showAddressModal,
    showDeleteModal,
    tempAddress,
  };
};

export default BlockCardAddressHook;
