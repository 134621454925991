// Enumerations
import { RoutesEnumeration } from 'enumerations/routes.enumeration';
// Translations
import {
  FAILED,
  SUCCESS,
} from '../translations/security-keys-access-key-change-step.configuration.translations';
// Types
import { WizardLinkConfigurationReturnType } from './types/security-keys-access-key-change.configurations.return.type';
import { WizardLinkConfigurationType } from './types/security-keys-access-key-change.configurations.type';
import { WizardFeedbackComponentType } from 'components/wizard-feedback/types/wizard-feedback.component.type';
import { GetWizardFeedbackPropsConfigurationsType } from './types/wizard-feedback-props.configurations.type';

const getWizardFeedbackConfigurations = ({
  onLinkClick,
  accessKeyExpired,
}: GetWizardFeedbackPropsConfigurationsType): Omit<WizardFeedbackComponentType, 'children'> => ({
  errorConfiguration: {
    description: FAILED.CONFIRMATION.CONTENT,
    title: FAILED.CONFIRMATION.TITLE,
    exitRoute: RoutesEnumeration.SECURITY,
    ...(accessKeyExpired ? { linkClick: onLinkClick } : {}),
  },
  successConfiguration: {
    description: SUCCESS.CONFIRMATION.CONTENT,
    title: SUCCESS.CONFIRMATION.TITLE,
    exitRoute: RoutesEnumeration.SECURITY,
    linkLabel: accessKeyExpired ? SUCCESS.EXPIRED_ACCESS_KEY : SUCCESS.BACK_BUTTTON,
    ...(accessKeyExpired ? { linkClick: onLinkClick } : {}),
  },
});

const getWizardLinkConfiguration = ({
  handleMakeUserLogoutEvent,
  accessKeyExpired,
}: WizardLinkConfigurationType): WizardLinkConfigurationReturnType => ({
  ...(accessKeyExpired
    ? {
        leftLinkConfiguration: {
          onClick: () => {
            handleMakeUserLogoutEvent();
            window.location.reload();
          },
        },
      }
    : {}),
});

export { getWizardFeedbackConfigurations, getWizardLinkConfiguration };
