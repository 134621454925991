// Types
import {
  FinancingInsurancesHiringLegalBuilderHandlersType,
  FinancingInsurancesHiringLegalMethodsHandlersType,
} from './types/financing-insurances-hiring-legal.handlers.type';

const openGermanBranchWebsiteLinkClickHandler = ({
  handleOpenGermanBranchWebsiteLinkClickTracking,
  url,
}: FinancingInsurancesHiringLegalBuilderHandlersType): void => {
  handleOpenGermanBranchWebsiteLinkClickTracking();
  window.open(url);
};

const FinancingInsurancesHiringLegalHandlers = (
  props: FinancingInsurancesHiringLegalBuilderHandlersType
): FinancingInsurancesHiringLegalMethodsHandlersType => ({
  handleOpenGermanBranchWebsiteLinkClick: () => openGermanBranchWebsiteLinkClickHandler(props),
});

export { FinancingInsurancesHiringLegalHandlers };
