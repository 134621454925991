const ON_LOAD_PAYLOAD = {
    action: 'open',
    format: 'modal',
    component: 'modal/personalInformationExpire60Days',
    element: 'full',
    eventCategory: '/personalInformationExpire60Days',
    eventAction: 'personalInformationExpire60Days',
    eventLabel: 'open',
  },
  ON_CLOSE_PAYLOAD = {
    action: 'close',
    format: 'cross',
    component: 'modal/personalInformationExpire60Days',
    element: 'cross',
    eventCategory: '/personalInformationExpire60Days',
    eventAction: 'personalInformationExpire60Days',
    eventLabel: 'close',
  },
  ON_EXIT_PAYLOAD = {
    action: 'exit',
    format: 'link',
    component: 'modal/personalInformationExpire60Days',
    element: 'noContinue',
    eventCategory: '/personalInformationExpire60Days',
    eventAction: 'personalInformationExpire60Days',
    eventLabel: 'navigationNoContinueLink',
  },
  ON_GO_TO_PERSONAL_DATA_PAYLOAD = {
    action: 'click',
    format: 'button',
    component: 'modal/personalInformationExpire60Days',
    element: 'goToPersonalData',
    eventCategory: '/personalInformationExpire60Days',
    eventAction: 'personalInformationExpire60Days',
    eventLabel: 'navigationGoToPersonalDataButton',
  };

export { ON_LOAD_PAYLOAD, ON_CLOSE_PAYLOAD, ON_EXIT_PAYLOAD, ON_GO_TO_PERSONAL_DATA_PAYLOAD };
