// Vendors
import { messageConstants } from '@openbank/cb-ui-commons';
import { createTagAndsDispatchFile } from '@openbank/cf-ui-framework';
// Constants
import { INSURANCE_TYPE } from 'containers/financing/views/ppi/components/contracting/lpi/constants/financing-insurances-contracting-lpi.constants';
import { PRE_CONTRACT_FILENAME } from './constants/financing-insurances-contracting-lpi-step3-download.handlers.constants';
import {
  DOWNLOAD_ERROR_EVENT_ACTION,
  DOWNLOAD_SUCCESS_EVENT_ACTION,
} from './constants/financing-insurances-contracting-lpi-step3-download.handlers.tracking.constants';
// Resolvers
import { createInsurancePrecontractResolver } from 'containers/financing/views/ppi/resolvers/financing-insurances.resolvers';
// Translations
import {
  DOWNLOAD_DOCUMENT_ERROR_MESSAGE,
  DOWNLOAD_DOCUMENT_SUCCESS_MESSAGE,
} from '../translations/financing-insurances-contracting-lpi-step3-download.translations';
// Types
import { FinancingInsurancesContractingLPIStep3DownloadHandlersType } from './types/financing-insurances-contracting-lpi-step3-download.handlers.type';
import { FinancingInsurancesContractingLPIStep3DownloadReturnHandlersType } from './types/financing-insurances-contracting-lpi-step3-download-return.handlers.type';

const downloadLinkClickHandler = async ({
  handleCloseDownloadToastTracking,
  handleDownloadToastTracking,
  handleDownloadLinkClickTracking,
  handleToggleDownloaded,
  plan,
  productId,
  productType,
  setDownloading,
  setToastConfiguration,
}: Omit<
  FinancingInsurancesContractingLPIStep3DownloadHandlersType,
  'setPostalDocumentation' | 'handleTogglePostalDocumentationTracking'
>): Promise<void> => {
  try {
    handleDownloadLinkClickTracking();
    setDownloading(true);

    if (!productId || !productType) {
      throw new Error();
    }

    const [blob, error] = await createInsurancePrecontractResolver({
      insuranceType: INSURANCE_TYPE,
      planCode: plan,
      productContractId: productId,
      productContractType: productType,
    });

    if (error || !blob) {
      throw new Error();
    }

    createTagAndsDispatchFile({ blob, filename: PRE_CONTRACT_FILENAME });
    handleToggleDownloaded();
    handleDownloadToastTracking(DOWNLOAD_SUCCESS_EVENT_ACTION);
    setToastConfiguration({
      type: messageConstants.TYPES.SUCCESS,
      description: DOWNLOAD_DOCUMENT_SUCCESS_MESSAGE,
      onClose: () => handleCloseDownloadToastTracking(DOWNLOAD_SUCCESS_EVENT_ACTION),
    });
  } catch (_) {
    handleDownloadToastTracking(DOWNLOAD_ERROR_EVENT_ACTION);
    setToastConfiguration({
      type: messageConstants.TYPES.ERROR,
      description: DOWNLOAD_DOCUMENT_ERROR_MESSAGE,
      onClose: () => handleCloseDownloadToastTracking(DOWNLOAD_ERROR_EVENT_ACTION),
    });
  } finally {
    setDownloading(false);
  }
};

const togglePostalDocumentationHandler = ({
  handleTogglePostalDocumentationTracking,
  setPostalDocumentation,
}: Pick<
  FinancingInsurancesContractingLPIStep3DownloadHandlersType,
  'handleTogglePostalDocumentationTracking' | 'setPostalDocumentation'
>) => {
  setPostalDocumentation(prev => {
    handleTogglePostalDocumentationTracking(!prev);
    return !prev;
  });
};

export const FinancingInsurancesContractingLPIStep3DownloadHandlers = ({
  handleCloseDownloadToastTracking,
  handleDownloadLinkClickTracking,
  handleDownloadToastTracking,
  handleToggleDownloaded,
  handleTogglePostalDocumentationTracking,
  plan,
  productId,
  productType,
  setDownloading,
  setPostalDocumentation,
  setToastConfiguration,
}: FinancingInsurancesContractingLPIStep3DownloadHandlersType): FinancingInsurancesContractingLPIStep3DownloadReturnHandlersType => ({
  handleDownloadLinkClick: () =>
    downloadLinkClickHandler({
      handleCloseDownloadToastTracking,
      handleDownloadLinkClickTracking,
      handleDownloadToastTracking,
      handleToggleDownloaded,
      plan,
      productId,
      productType,
      setDownloading,
      setToastConfiguration,
    }),
  handleTogglePostalDocumentation: () =>
    togglePostalDocumentationHandler({
      handleTogglePostalDocumentationTracking,
      setPostalDocumentation,
    }),
});
