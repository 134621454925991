// Contexts
import { ToastContextConsumerHook } from 'contexts/toast.context';
// Event handlers
import { FinancingPPIContractingStep2TermsLinkHandlers } from 'containers/financing/views/ppi/components/contracting/cpi/components/step2/components/terms-link/handlers/financing-ppi-contracting-step2-terms-link.handlers';
import { FinancingPPIContractingStep2TermsLinkTrackingHandlers } from 'containers/financing/views/ppi/components/contracting/cpi/components/step2/components/terms-link/handlers/financing-ppi-contracting-step2-terms-link.tracking.handlers';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { FinancingPPIContractingStepFeaturesSectionBuilderReturnHandlersType } from '../handlers/types/financing-ppi-contracting-step2-features-section.handlers.type';

const useFinancingPPIContractingStep2FeaturesSectionHook =
  (): FinancingPPIContractingStepFeaturesSectionBuilderReturnHandlersType => {
    const { setToastConfiguration } = ToastContextConsumerHook();

    return FinancingPPIContractingStep2TermsLinkHandlers({
      ...AppTrackingHook(FinancingPPIContractingStep2TermsLinkTrackingHandlers),
      setToastConfiguration,
    });
  };

export { useFinancingPPIContractingStep2FeaturesSectionHook };
