// Vendors
import { apiUtilsCb } from '@openbank/cf-ui-architecture-core';
// Constants
import { GET_COUNTRY_CONFIGURATION_SERVICE_CONFIGURATION } from './constants/country.services.constants';
// Types
import { CountryConfigurationResponseType } from 'types/country/country-configuration-response.type';

const getCountryConfigurationService = async (
  country: string
): Promise<[CountryConfigurationResponseType | Record<string, never>, Response]> =>
  apiUtilsCb.get({
    ...GET_COUNTRY_CONFIGURATION_SERVICE_CONFIGURATION,
    queryParams: { country },
  });

export { getCountryConfigurationService };
