const COMMON_FORM_DEFAULT_VALUES = {
  street: '',
  number: '',
  zip: '',
  city: '',
};

const ADDRESS_FORM_DEFAULT_VALUES = {
  ...COMMON_FORM_DEFAULT_VALUES,
  additional: '',
};

const AT_ADDRESS_FORM_DEFAULT_VALUES = {
  ...COMMON_FORM_DEFAULT_VALUES,
  staircase: '',
  doorNumber: '',
};

export { ADDRESS_FORM_DEFAULT_VALUES, AT_ADDRESS_FORM_DEFAULT_VALUES };
