const DOMAIN = 'cobranded.services.userSettings.privateArea.cbModalAddress.';
const DOMAIN_INPUTS = 'cobranded.services.userSettings.privateArea.cbModalAddress.inputs.';
const DOMAIN_ERROR_MESSAGES =
  'cobranded.services.userSettings.privateArea.cbModalAddress.errorMessages.';

const NO_RESULTS = {
  noResultsTitle: `${DOMAIN_INPUTS}noResults`,
  noResultsDescription: `${DOMAIN_INPUTS}noResultsDescription`,
};

const ADDITIONAL = {
  label: `${DOMAIN_INPUTS}additional.label`,
  deleteLabel: `${DOMAIN_INPUTS}additional.delete`,
};

const NUMBER = {
  label: `${DOMAIN_INPUTS}number.label`,
};

const ZIP = {
  label: `${DOMAIN_INPUTS}zip.label`,
};

const STAIRCASE = {
  label: `${DOMAIN_INPUTS}staircase.label`,
};

const DOOR_NUMBER = {
  label: `${DOMAIN_INPUTS}doorNumber.label`,
};

const CITY = {
  label: `${DOMAIN_INPUTS}city.label`,
};

const ERROR_MESSAGES = {
  FORMAT: `${DOMAIN_ERROR_MESSAGES}format`,
  NUMBER_MAX_LENGTH: `${DOMAIN_INPUTS}errorMessages.numberMaxLength`,
  REQUIRED: `${DOMAIN_INPUTS}errorMessages.required`,
  STREET_MAX_LENGTH: `${DOMAIN_INPUTS}errorMessages.streetMaxLength`,
  ZIP_FORMAT: `${DOMAIN_INPUTS}errorMessages.zipFormat`,
  ZIP_MAX_LENGTH: `${DOMAIN_INPUTS}errorMessages.zipMaxLength`,
  ZIP_NOT_MATCH: `${DOMAIN_INPUTS}errorMessages.zipNotMatch`,
  ZIP_MAX_LENGTH_AT: `${DOMAIN_ERROR_MESSAGES}zipMaxLengthAT`,
  STAIRCASE_MAX_LENGTH: `${DOMAIN_ERROR_MESSAGES}staircaseMaxLength`,
  DOOR_NUMBER_MAX_LENGTH: `${DOMAIN_ERROR_MESSAGES}doorNumberMaxLength`,
  CITY_MAX_LENGTH_AT: `${DOMAIN_ERROR_MESSAGES}cityMaxLengthAT`,
  STREET_MAX_LENGTH_AT: `${DOMAIN_ERROR_MESSAGES}streetMaxLengthAT`,
};

export { ADDITIONAL, NUMBER, ZIP, NO_RESULTS, ERROR_MESSAGES, STAIRCASE, DOOR_NUMBER, CITY };
