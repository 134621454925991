// Vendors
import React from 'react';
import { isEmpty } from '@openbank/web-ui-framework';
import { isIosWebView } from '@gruposantander/web-ui-framework';
import { useForm } from 'react-hook-form';
// Components
import { ModalAdressComponent } from '@openbank/cb-ui-commons';
import { AddressModalInputsATComponent } from './components/inputs-at/address-modal-inputs-at.component';
import { AddressModalInputsComponent } from './components/inputs/address-modal-inputs.component';
import ErrorWrapperComponent from 'components/error-wrapper/error-wrapper.component';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import {
  MODAL_BUTTON_PROPS,
  MODAL_LINK_PROPS,
  MODAL_PROPS,
} from './constants/address-modal.constants';
import { USE_FORM_OPTIONS } from './hooks/constants/address-modal.hook.constants';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
// Hooks
import { useAddressModalHook } from './hooks/address-modal.hook';
import useCbIntl from 'hooks/useCbIntl';
// Translations
import {
  ADDRESS_MODAL_CANCEL_BUTTON,
  ADDRESS_MODAL_SAVE_BUTTON,
  ADDRESS_MODAL_TITLE,
} from './translations/address-modal.translations';
// Types
import { AddressModalComponentType } from './types/address-modal.component.type';
// Utils
import { formatAddressFormValues } from './utils/address-modal.utils';
import { isAustrian } from 'utils/country.utils';

export const AddressModalComponent = ({
  defaultValues,
  isOpen,
  onCancel,
  onClose,
  onError,
  onSave,
  title = ADDRESS_MODAL_TITLE,
}: AddressModalComponentType): React.ReactElement => {
  const { formatMessage } = useCbIntl();
  const {
    profile: {
      userDetails: { countryOfResidence },
    },
  } = AppContextConsumerHook();

  const {
    control,
    formState: { errors, isValidating },
    handleSubmit,
    reset,
    setValue,
  } = useForm({
    ...USE_FORM_OPTIONS,
    defaultValues: formatAddressFormValues(defaultValues, countryOfResidence),
  });

  const { addressValidators, country, handleReset, handleSave, isLoading } = useAddressModalHook({
    countryOfResidence,
    defaultValues,
    isOpen,
    onError,
    onSave,
    reset,
  });

  const isIosDevice = isIosWebView();

  return (
    <ModalAdressComponent
      {...{ ...MODAL_PROPS, isIosDevice }}
      footerConfiguration={{
        buttonConfiguration: {
          ...MODAL_BUTTON_PROPS,
          children: <FormattedMessageComponent id={ADDRESS_MODAL_SAVE_BUTTON} />,
          disabled: !isEmpty(errors) || isValidating || isLoading,
          onClick: handleSubmit(handleSave),
        },
        linkConfiguration: {
          ...MODAL_LINK_PROPS,
          children: <FormattedMessageComponent id={ADDRESS_MODAL_CANCEL_BUTTON} />,
          onClick: handleReset(onCancel),
        },
      }}
      isOpen={isOpen}
      title={<FormattedMessageComponent id={title} />}
      onClose={handleReset(onClose)}
    >
      <ErrorWrapperComponent error={false} fetching={isLoading}>
        {isAustrian(country) ? (
          <AddressModalInputsATComponent
            addressValidators={addressValidators?.AT}
            control={control}
            country={country}
            errors={errors}
            formatMessage={formatMessage}
            isIosDevice={isIosDevice}
            onError={onError}
            setValue={setValue}
          />
        ) : (
          <AddressModalInputsComponent
            addressValidators={addressValidators?.DE}
            control={control}
            errors={errors}
            formatMessage={formatMessage}
            isIosDevice={isIosDevice}
            onError={onError}
            setValue={setValue}
          />
        )}
      </ErrorWrapperComponent>
    </ModalAdressComponent>
  );
};
