// Enumerations
import { ConsentPPIStep2IdsEnumeration } from 'containers/financing/views/ppi/components/contracting/cpi/components/step2/enumerations/consent-ppi-step2-ids.enumeration';
// Translations
import {
  CNSPI_CONSENT,
  TERMS_ACCEPTANCE_CONSENT,
  TSN_CONSENT,
} from './translations/financing-ppi-contracting-step2.handlers.utils.translations';
// Types
import { FinancingPPIContractingStep2ContextType } from '../../contexts/types/financing-ppi-contracting-step2.context.type';
import { FinancingPPIContractingStep2BuilderHandlersType } from '../types/financing-ppi-contracting-step2.handlers.type';
import { FinancingPPIContractingStepApiBuilderType } from '../../types/financing-ppi-contracting-step-api-builder.type';

const allMandatoryChecksAreChecked = (
  checksDone: FinancingPPIContractingStep2ContextType['checksDone']
): boolean =>
  [ConsentPPIStep2IdsEnumeration.TNC].every((check: ConsentPPIStep2IdsEnumeration) =>
    checksDone.includes(check)
  );

const allMandatoryActionsAreDone = ({
  checksDone,
  documentDownloaded,
}: Pick<
  FinancingPPIContractingStep2BuilderHandlersType,
  'checksDone' | 'documentDownloaded'
>): boolean => allMandatoryChecksAreChecked(checksDone) && documentDownloaded;

const getTermsAndConditionsParams = (
  formatMessage: FinancingPPIContractingStep2BuilderHandlersType['formatMessage']
): FinancingPPIContractingStepApiBuilderType => ({
  contractInsuranceAcceptance: formatMessage({ id: TERMS_ACCEPTANCE_CONSENT }),
  shareCnpsiAcceptance: formatMessage({ id: CNSPI_CONSENT }),
  tncAcceptance: formatMessage({ id: TSN_CONSENT }),
});

export { allMandatoryActionsAreDone, allMandatoryChecksAreChecked, getTermsAndConditionsParams };
