// Handlers
import { FinancingInsurancesContractingLPITrackingHandlers } from '../handlers/financing-insurances-contracting-lpi.handlers.tracking';
// Hooks
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { FinancingInsurancesContractingLPIReturnTrackingHandlersType } from '../handlers/types/financing-insurances-contracting-lpi-return.handlers.tracking.type';

export const useFinancingInsurancesContractingLPI =
  (): FinancingInsurancesContractingLPIReturnTrackingHandlersType =>
    AppTrackingHook(FinancingInsurancesContractingLPITrackingHandlers);
