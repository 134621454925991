// Translations
import { NO_RESULTS } from 'components/address-modal/translations/address-modal-inputs.translations';

const DOMAIN_INPUTS_STREET =
  'cobranded.services.userSettings.privateArea.cbModalAddress.inputs.street.';

const STREET = {
  ...NO_RESULTS,
  label: `${DOMAIN_INPUTS_STREET}label`,
  placeholder: `${DOMAIN_INPUTS_STREET}placeholder`,
};

export { STREET };
