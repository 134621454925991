// Enumerations
import { ValidateCodesEnumeration } from 'components/address-modal/enums/validate-codes.enumeration';
import { ValidateStatusEnumeration } from 'components/address-modal/enums/validate-status.enumeration';
// Resolvers
import { validateCityWithPostcodeResolver } from 'components/address-modal/resolvers/address-modal.resolvers';
// Types
import { ParseCityWithPostcodeType } from './types/parse-city-with-postcode.type';
import { ParseCityWithPostcodeReturnType } from './types/parse-city-with-postcode-return.type';
// Utils
import { isAustrian } from 'utils/country.utils';

const parseCityAndPostcode = async ({
  city,
  country,
  postCode,
}: ParseCityWithPostcodeType): ParseCityWithPostcodeReturnType => {
  const defaultValues = { cityValue: city, postCodeValue: postCode };

  if (country && isAustrian(country)) {
    return defaultValues;
  }

  const [response, error] = await validateCityWithPostcodeResolver({
    city,
    postCode,
  });

  if (error || response?.status === ValidateStatusEnumeration.FAILED) {
    if (response?.code === ValidateCodesEnumeration.E003) {
      return { cityValue: city, postCodeValue: '' };
    }

    return { cityValue: '', postCodeValue: '' };
  }

  return defaultValues;
};

export { parseCityAndPostcode };
