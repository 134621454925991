// Vendors
import { useState } from 'react';
import useCbIntl from 'hooks/useCbIntl';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
import { useFinancingPPIContractingContextConsumerHook } from 'containers/financing/views/ppi/components/contracting/cpi/contexts/financing-ppi-contracting.context';
import { useFinancingPPIContractingStep2ContextConsumerHook } from '../contexts/financing-ppi-contracting-step2.context';
import { useWizardFeedbackContextConsumerHook } from 'components/wizard-feedback/contexts/wizard-feedback.context';
import { useWizardContextConsumerHook } from 'components/wizard/contexts/wizard.context';
// Event handlers
import { FinancingPPIContractingStep2Handlers } from '../handlers/financing-ppi-contracting-step2.handlers';
// Types
import { FinancingPPIContractingStep2Type } from './types/financing-ppi-contracting-step2.type';

const useFinancingPPIContractingStep2Hook = (): FinancingPPIContractingStep2Type => {
  const [fetching, setFetching] = useState<boolean>(false);

  const { formatMessage } = useCbIntl();

  const { errorChecksDone, checksDone, setErrorChecksDone } =
      useFinancingPPIContractingStep2ContextConsumerHook(),
    { setErrorDocumentDownloaded, documentDownloaded } =
      useFinancingPPIContractingContextConsumerHook(),
    { setCurrentStep } = useWizardContextConsumerHook(),
    { setResult } = useWizardFeedbackContextConsumerHook(),
    { localization } = AppContextConsumerHook(),
    { cfg_public_url_privacy_policy_web: url } = localization?.config || {};

  return {
    ...FinancingPPIContractingStep2Handlers({
      checksDone,
      formatMessage,
      setCurrentStep,
      setErrorChecksDone,
      setErrorDocumentDownloaded,
      setFetching,
      setResult,
      documentDownloaded,
    }),
    fetching,
    errorChecksDone,
    url,
  };
};

export { useFinancingPPIContractingStep2Hook };
