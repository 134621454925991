// Vendors
import React from 'react';
// Components
import { ListComponent, ParagraphComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import {
  FINANCING_PPI_CONTRACTING_STEP_1_COVERAGE_LIMITATIONS_LIST_PROPS,
  FINANCING_PPI_CONTRACTING_STEP_1_COVERAGE_LIMITATIONS_LIST_ITEMS,
} from './constants/financing-insurances-contracting-lpi-additional-limitations.constants';
// Hooks
import useCbIntl from 'hooks/useCbIntl';
// Styles
import { FinancingInsurancesContractingLPIAdditionalGroupStyled } from '../../financing-insurances-contracting-lpi-additional.component.styled';
// Translations
import {
  DESCRIPTION,
  TITLE,
} from './translations/financing-insurances-contracting-lpi-additional-limitations.translations';
// Utils
import { mapAndTranslateSectionsListItems } from 'containers/financing/views/ppi/components/contracting/cpi/components/step2/components/expandable/utils/financing-ppi-contracting-step2-expandable.utils';

export const FinancingInsurancesContractingLPIAdditionalLimitationsComponent =
  (): React.ReactElement => {
    const { formatMessage } = useCbIntl();

    return (
      <FinancingInsurancesContractingLPIAdditionalGroupStyled>
        <ParagraphComponent bold>
          <FormattedMessageComponent id={TITLE} />
        </ParagraphComponent>
        <ParagraphComponent>
          <FormattedMessageComponent id={DESCRIPTION} />
        </ParagraphComponent>
        <ListComponent
          {...FINANCING_PPI_CONTRACTING_STEP_1_COVERAGE_LIMITATIONS_LIST_PROPS}
          items={mapAndTranslateSectionsListItems({
            ...FINANCING_PPI_CONTRACTING_STEP_1_COVERAGE_LIMITATIONS_LIST_ITEMS,
            formatMessage,
          })}
        />
      </FinancingInsurancesContractingLPIAdditionalGroupStyled>
    );
  };
