// Vendors
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// Constants
import { BANNER_PROPS_LOADING } from './constants/banner.component.constants';
// Handlers
import { BannerHandlers } from '../handlers/banner.handler';
// Hooks
import useCbIntl from 'hooks/useCbIntl';
import { DeviceTypeHook } from 'hooks/device-type.hook';
// Trackings
import AppTrackingHook from 'tracking/app.tracking.hook';
import { BannerTracking } from '../trackings/banner.tracking';
// Types
import {
  AppBannerComponentHookType,
  AppBannerPropsType,
} from './types/banner.component.hook.types';
import { BannerServiceType } from '../services/types/banner.service.type';

export const AppBannerComponentHook = (): AppBannerComponentHookType => {
  const navigate = useNavigate();
  const { formatMessage } = useCbIntl();
  const { isMobile, isTablet } = DeviceTypeHook();
  const [bannerProps, setBannerProps] = useState<AppBannerPropsType>(BANNER_PROPS_LOADING);
  const [bannerResponse, setBannerResponse] = useState<BannerServiceType>();

  const { handleBannerAppearTracking, handleClickBannerButtonTracking } =
    AppTrackingHook(BannerTracking);
  const { handleGetBanner, handleSetAppropiateBanner } = BannerHandlers({
    formatMessage,
    navigate,
    setBannerProps,
    setBannerResponse,
    handleClickBannerButtonTracking,
  });

  useEffect(() => {
    handleGetBanner();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    bannerResponse && handleBannerAppearTracking(bannerResponse);
  }, [bannerResponse]);

  useEffect(() => {
    handleSetAppropiateBanner({ bannerResponse, isMobile, isTablet });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bannerResponse, isMobile, isTablet]);

  return { bannerProps };
};
