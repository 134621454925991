// Enumerations
import { RoutesEnumeration } from 'enumerations/routes.enumeration';
// Utils
import { generateAbsoluteRoute } from 'utils/app-routing.utils';
// Types
import { ModalInterstitialDocExpiredHandlersType } from './types/modal-interstitial-doc-expired.handlers.type';
import { ModalInterstitialDocExpiredHandlersPropsType } from './types/modal-interstitial-doc-expired-props.handlers.type';

const navigateToPersonalInfoHandler = ({
  navigate,
  handleOnGoToPersonalDataButtonClickTracking,
}: Pick<
  ModalInterstitialDocExpiredHandlersPropsType,
  'handleOnGoToPersonalDataButtonClickTracking' | 'navigate'
>): void => {
  handleOnGoToPersonalDataButtonClickTracking();
  navigate(generateAbsoluteRoute(RoutesEnumeration.PERSONAL_INFORMATION));
};

const onCloseDocExpiredModalHandler = ({
  handleOnCloseDocExpiredModalTracking,
  onClose,
}: Pick<
  ModalInterstitialDocExpiredHandlersPropsType,
  'handleOnCloseDocExpiredModalTracking' | 'onClose'
>): void => {
  handleOnCloseDocExpiredModalTracking();
  onClose();
};

const onExitDocExpiredModalHandler = ({
  handleOnExitDocExpiredModalTracking,
  onClose,
}: Pick<
  ModalInterstitialDocExpiredHandlersPropsType,
  'handleOnExitDocExpiredModalTracking' | 'onClose'
>): void => {
  handleOnExitDocExpiredModalTracking();
  onClose();
};

export const InterstitialDocExpiredHandlers = ({
  handleOnCloseDocExpiredModalTracking,
  handleOnExitDocExpiredModalTracking,
  handleOnGoToPersonalDataButtonClickTracking,
  navigate,
  onClose,
}: ModalInterstitialDocExpiredHandlersPropsType): ModalInterstitialDocExpiredHandlersType => ({
  handleNavigateToPersonalInfo: () =>
    navigateToPersonalInfoHandler({ navigate, handleOnGoToPersonalDataButtonClickTracking }),
  handleCloseDocExpiredModal: () =>
    onCloseDocExpiredModalHandler({ handleOnCloseDocExpiredModalTracking, onClose }),
  handleExitDocExpiredModal: () =>
    onExitDocExpiredModalHandler({ handleOnExitDocExpiredModalTracking, onClose }),
});
