// Constants
import { NUMBER } from 'constants/number.constants';
// Enumerations
import { ActivityTypeDeclaredEnumeration } from 'containers/financing/views/credit-limit-increase/components/step2/sections/employment-data/enumerations/employment-status.enumeration';
import { UploadDocumentIdsEnumeration } from 'containers/financing/views/credit-limit-increase/components/step4/upload-documentation/components/upload-document/enums/upload-document-ids.enumeration';
// Types
import { getRejectedDocumentsMap } from 'containers/financing/views/credit-limit-increase/components/step4/upload-documentation/components/upload-documents-section/hooks/utils/upload-documents-section.utils';
import { RejectedDocumentType } from 'containers/financing/views/credit-limit-increase/contexts/types/document-rejected.type';
import { SelfEmployedSectionHookReturnPropsType } from './types/self-employed-section-return-props.type';
import { SelfEmployedSectionHookPropsType } from './types/self-employed-section-props.type';
// Utils
import { isUploadDocumentRejected } from 'containers/financing/views/credit-limit-increase/components/step4/upload-documentation/components/upload-documents-section/utils/upload-documents-section.utils';
import { isAustrian } from 'utils/country.utils';

const useSelfEmployedSectionHook = ({
  activityTypeDeclared,
  documentsRejected,
  countryOfResidence,
}: SelfEmployedSectionHookPropsType): SelfEmployedSectionHookReturnPropsType => {
  const rejectedDocumentsMap = getRejectedDocumentsMap(documentsRejected);
  const isRentierOrSelfEmployed =
    activityTypeDeclared === ActivityTypeDeclaredEnumeration.RENTIER ||
    activityTypeDeclared === ActivityTypeDeclaredEnumeration.SELF_EMPLOYED;
  const isSelfEmployed = activityTypeDeclared === ActivityTypeDeclaredEnumeration.SELF_EMPLOYED;
  const isSelfEmployedRejected: boolean = isUploadDocumentRejected({
    uploadDocumentId: UploadDocumentIdsEnumeration.LAST_BWA,
    rejectedDocumentsMap,
  });
  const isLastIncomeTaxRejected: boolean = isUploadDocumentRejected({
    uploadDocumentId: UploadDocumentIdsEnumeration.LAST_INCOME_TAX,
    rejectedDocumentsMap,
  });

  const hasRejectedDocument = (rejectDocuments: RejectedDocumentType[]): boolean => {
    return rejectDocuments.some(
      document =>
        document.documentDescription === UploadDocumentIdsEnumeration.LAST_BWA ||
        document.documentDescription === UploadDocumentIdsEnumeration.LAST_INCOME_TAX
    );
  };

  const shouldRenderSection = (): boolean => {
    if (!isRentierOrSelfEmployed) return false;
    if (!documentsRejected || documentsRejected.length === NUMBER.ZERO) return true;
    return documentsRejected.length > NUMBER.ZERO && hasRejectedDocument(documentsRejected);
  };

  return {
    showLastIncomeTax: !documentsRejected || isLastIncomeTaxRejected,
    showSelfEmployment: isSelfEmployed && (!documentsRejected || isSelfEmployedRejected),
    isLastIncomeTaxRejected,
    isSelfEmployedRejected,
    isAustrian: isAustrian(countryOfResidence),
    shouldRenderSection: shouldRenderSection(),
  };
};

export { useSelfEmployedSectionHook };
