// Vendors
import { useEffect } from 'react';
// Event Handlers
import { InterstitialCommercialTrackingHandlers } from '../handlers/tracking/modal-interstitial-commercial.tracking.handlers';
import { InterstitialCommercialHandlers } from '../handlers/modal-interstitial-commercial.handlers';
// Configurations
import { modalCommercialConfiguration } from '../configuration/modal-interstitial-commercial.configuration';
// Hooks
import AppTrackingHook from 'tracking/app.tracking.hook';
import useCbIntl from 'hooks/useCbIntl';
// Types
import { UseModalInterstitialCommercialHookType } from './types/modal-interstitial-commercial.hook.type';
import { UseModalInterstitialCommercialHookPropsType } from './types/modal-interstitial-commercial-props.hook.type';

const useModalInterstitialCommercialHook = ({
  content,
  feedback,
  onClose,
}: UseModalInterstitialCommercialHookPropsType): UseModalInterstitialCommercialHookType => {
  const { formatDate } = useCbIntl();

  const { handleInterstitialCommercialLoadedTracking, ...restTrackingHandlers } = AppTrackingHook(
    InterstitialCommercialTrackingHandlers({
      ...feedback,
      creativityId: content.creativityId,
    })
  );

  const { handleCloseButtonClick, handleClosePromotionLinkClick, handleKnownMoreButtonClick } =
    InterstitialCommercialHandlers({
      ...restTrackingHandlers,
      buttonUrl: content.button.buttonUrl,
      onClose,
    });

  useEffect(() => {
    handleInterstitialCommercialLoadedTracking();
  }, []);

  return {
    modalConfiguration: modalCommercialConfiguration({
      ...content,
      handleClose: handleCloseButtonClick,
      handleClosePromotion: handleClosePromotionLinkClick,
      handleKnowMore: handleKnownMoreButtonClick,
      formatDate,
    }),
  };
};

export { useModalInterstitialCommercialHook };
