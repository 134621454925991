// Types
import { FinancingInsurancesContractingLPIStep3DisclaimerHandlersType } from './types/financing-insurances-contracting-lpi-step3-disclaimer.handlers.type';
import { FinancingInsurancesContractingLPIStep3DisclaimerReturnHandlersType } from './types/financing-insurances-contracting-lpi-step3-disclaimer-return.handlers.type';

const privacyPolicyLinkClickHandler = ({
  cfg_public_url_privacy_policy_web,
  handlePrivacyPolicyLinkClickTracking,
}: FinancingInsurancesContractingLPIStep3DisclaimerHandlersType): void => {
  handlePrivacyPolicyLinkClickTracking();
  window.open(cfg_public_url_privacy_policy_web, '_blank');
};

export const FinancingInsurancesContractingLPIStep3DisclaimerHandlers = (
  props: FinancingInsurancesContractingLPIStep3DisclaimerHandlersType
): FinancingInsurancesContractingLPIStep3DisclaimerReturnHandlersType => ({
  handlePrivacyPolicyLinkClick: () => privacyPolicyLinkClickHandler(props),
});
