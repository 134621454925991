// Vendors
import { TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
// Constants
import { AMAZON_LINK_CLICK_TRACKING_PROPS } from './constants/financing-insurances-contracting-lpi-step3-additional.handlers.tracking.constants';
// Types
import { FinancingInsurancesContractingLPIStep3AdditionalReturnTrackingHandlersType } from './types/financing-insurances-contracting-lpi-step3-additional-return.handlers.tracking.type';
import { TrackBuilderType } from 'types/tracking/track-builder.type';

const amazonLinkClickTrackingHandler = (): TrackingLinkEventReturnType =>
  AMAZON_LINK_CLICK_TRACKING_PROPS;

export const FinancingInsurancesContractingLPIStep3AdditionalTrackingHandlers = (
  track: TrackBuilderType
): FinancingInsurancesContractingLPIStep3AdditionalReturnTrackingHandlersType => ({
  handleAmazonLinkClickTracking: () => track(amazonLinkClickTrackingHandler()),
});
