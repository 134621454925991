// Vendors
import React from 'react';
// Components
import { ParagraphComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Hooks
import useCbIntl from 'hooks/useCbIntl';
// Styles
import { FormattedAddressComponentStyled } from './formatted-address.component.styled';
// Translations
import { COUNTRIES_PREFFIX } from 'translations/locations.translations';
// Types
import { FormattedAddressComponentType } from './types/formatted-address.type';
// Utils
import { formatAddressAdditionalFields } from 'utils/addresses/addresses.utils';

export const FormattedAddressComponent = ({
  address: { additionalItems1, city, countryCode, department, floor, number, postalCode, street },
  paragraphProps,
  testId,
}: FormattedAddressComponentType): React.ReactElement => {
  const { formatMessage } = useCbIntl();

  return (
    <FormattedAddressComponentStyled>
      <ParagraphComponent {...paragraphProps} {...{ testId }}>
        {`${street} ${number},${formatAddressAdditionalFields({
          additionalItems1,
          department,
          floor,
          formatMessage,
        })}`}
      </ParagraphComponent>
      <ParagraphComponent {...paragraphProps}>
        {postalCode} {city},
      </ParagraphComponent>
      <ParagraphComponent {...paragraphProps}>
        <FormattedMessageComponent id={`${COUNTRIES_PREFFIX}${countryCode}`} />
      </ParagraphComponent>
    </FormattedAddressComponentStyled>
  );
};
