export const BANNER_APPEAR = {
  action: 'view',
  format: 'banner',
  component: 'banner',
  element: 'full',
  eventAction: 'viewBanner',
  eventLabel: 'full',
  tealium_event: 'product_impression',
};

export const CLICK_BANNER_BUTTON = {
  action: 'open',
  format: 'button',
  component: 'banner',
  element: 'knowMore',
  pathname: '/dashboard',
  eventAction: 'clickButton',
  eventLabel: 'knowMoreButton',
  tealium_event: 'product_click',
};

export const ARRAY_BANNERS_POSITION: string[] = [];
