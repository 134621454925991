// Vendors
import {
  TrackingEventsEnum,
  TrackingLinkEventReturnType,
  TrackingViewEventReturnType,
} from '@openbank/cf-ui-static-data';
// Constants
import {
  ON_CLOSE_PROMOTION_TRACKING_PAYLOAD,
  ON_CLOSE_TRACKING_PAYLOAD,
  ON_KNOW_MORE_TRACKING_PAYLOAD,
  ON_LOAD_TRACKING_PAYLOAD,
} from './constants/modal-interstitial-commercial.tracking.handlers.constants';
// Types
import { InterstitialCommercialCommonInfoTrackingType } from './types/modal-interstitial-commercial-common-info.tracking.handlers.type';
import { InterstitialCommercialPropsTrackingType } from './types/modal-interstitial-commercial-props.tracking.handlers.type';
import { InterstitialCommercialTrackingHandlersType } from './types/modal-interstitial-commercial.tracking.handlers.type';
import { TrackBuilderType } from 'types/tracking/track-builder.type';
// Utilities
import { getCommonTrackingPayload } from './utils/modal-interstitial-commercial.tracking.handlers.utils';

const interstitialCommercialLoadedTrackingHandler = (
  commonTrackingInfo: InterstitialCommercialCommonInfoTrackingType
): TrackingViewEventReturnType => ({
  payload: {
    ...getCommonTrackingPayload(commonTrackingInfo),
    ...ON_LOAD_TRACKING_PAYLOAD,
  },
  type: TrackingEventsEnum.VIEW,
});

const interstitialCommercialKnowMoreTrackingHandler = (
  commonTrackingInfo: InterstitialCommercialCommonInfoTrackingType
): TrackingLinkEventReturnType => ({
  payload: {
    ...getCommonTrackingPayload(commonTrackingInfo),
    ...ON_KNOW_MORE_TRACKING_PAYLOAD,
  },
  type: TrackingEventsEnum.LINK,
});

const interstitialCommercialCloseTrackingHandler = (
  commonTrackingInfo: InterstitialCommercialCommonInfoTrackingType
): TrackingLinkEventReturnType => ({
  payload: {
    ...getCommonTrackingPayload(commonTrackingInfo),
    ...ON_CLOSE_TRACKING_PAYLOAD,
  },
  type: TrackingEventsEnum.LINK,
});

const interstitialCommercialClosePromotionTrackingHandler = (
  commonTrackingInfo: InterstitialCommercialCommonInfoTrackingType
): TrackingLinkEventReturnType => ({
  payload: {
    ...getCommonTrackingPayload(commonTrackingInfo),
    ...ON_CLOSE_PROMOTION_TRACKING_PAYLOAD,
  },
  type: TrackingEventsEnum.LINK,
});

export const InterstitialCommercialTrackingHandlers =
  (
    commonTrackingInfo: InterstitialCommercialCommonInfoTrackingType
  ): ((track: TrackBuilderType) => InterstitialCommercialTrackingHandlersType) =>
  (track: TrackBuilderType): InterstitialCommercialTrackingHandlersType => ({
    handleInterstitialCommercialLoadedTracking: () =>
      track(interstitialCommercialLoadedTrackingHandler(commonTrackingInfo)),
    handlInterstitialCommercialKnowMoreTracking: () =>
      track(interstitialCommercialKnowMoreTrackingHandler(commonTrackingInfo)),
    handleInterstitialCommercialCloseTracking: () =>
      track(interstitialCommercialCloseTrackingHandler(commonTrackingInfo)),
    handleInterstitialCommercialClosePromotionTracking: () =>
      track(interstitialCommercialClosePromotionTrackingHandler(commonTrackingInfo)),
  });
