// Vendors
import React, { lazy } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
// Constants
import {
  CONTAINERS_ROUTE_PROPS,
  DEFAULT_ROOT_PROPS,
  LOGIN_LOGGED_ROUTE_PROPS,
  LOGIN_LOGGED_NAVIGATE_ROUTE_PROPS,
  NOT_FOUND_ROUTE_PROPS,
} from './constants/orchestrator-router.constants';
// Containers
import { NotFoundContainer } from 'containers/not-found/not-found.container';
const OrchestratorRouterContainersComponent = lazy(
  () => import('./components/containers/orchestrator-router-containers.component')
);

const OrchestratorRouterComponent = (): React.ReactElement => (
  <Routes>
    <Route {...CONTAINERS_ROUTE_PROPS} element={<OrchestratorRouterContainersComponent />} />
    <Route
      {...LOGIN_LOGGED_ROUTE_PROPS}
      element={<Navigate {...LOGIN_LOGGED_NAVIGATE_ROUTE_PROPS} />}
    />
    <Route {...DEFAULT_ROOT_PROPS} element={<Navigate {...LOGIN_LOGGED_NAVIGATE_ROUTE_PROPS} />} />
    <Route {...NOT_FOUND_ROUTE_PROPS} element={<NotFoundContainer />} />
  </Routes>
);

export default OrchestratorRouterComponent;
