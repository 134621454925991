const DOMAIN = 'cobranded.services.userSettings.privateArea.addresses.';

const LABELS = {
  ['province']: 'province_',
  ['countryCode']: 'country_',
};

const STAIRCASE_LABEL = `${DOMAIN}staircaseLabel`;

const DOOR_LABEL = `${DOMAIN}doorLabel`;

export { LABELS, STAIRCASE_LABEL, DOOR_LABEL };
