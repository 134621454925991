// Services
import { getCountryConfigurationService } from 'services/country/country.services';
// Types
import { CountryConfigurationResponseType } from 'types/country/country-configuration-response.type';
// Utils
import { requestHasSuccessStatus } from '@openbank/cf-ui-framework';

const getCountryConfigurationResolver = async (
  country: string
): Promise<[CountryConfigurationResponseType | Record<string, never>] | [null, boolean]> => {
  const [response, { status }] = await getCountryConfigurationService(country);

  return requestHasSuccessStatus(status) ? [response] : [null, true];
};

export { getCountryConfigurationResolver };
