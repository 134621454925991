// Vendors
import { TrackingEventsEnum, TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
// Constants
import { CHECKED_EVENT_LABEL, UNCHECKED_EVENT_LABEL } from 'constants/tracking.constants';
import {
  CLOSE_DOWNLOAD_TOAST_EVENT_LABEL,
  DOWNLOAD_TOAST_EVENT_LABEL,
  DOWNLOAD_TRACKING_PROPS,
  TOGGLE_POSTAL_DOCUMENTATION_EVENT_ACTION,
} from './constants/financing-insurances-contracting-lpi-step3-download.handlers.tracking.constants';
// Types
import { FinancingInsurancesContractingLPIStep3DownloadReturnTrackingHandlersType } from './types/financing-insurances-contracting-lpi-step3-download-return.handlers.tracking.type';
import { TrackBuilderType } from 'types/tracking/track-builder.type';

const downloadLinkClickTrackingHandler = (): TrackingLinkEventReturnType => DOWNLOAD_TRACKING_PROPS;

const togglePostalDocumentationTrackingHandler = (
  postalDocumentation: boolean
): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: TOGGLE_POSTAL_DOCUMENTATION_EVENT_ACTION,
    eventLabel: postalDocumentation ? CHECKED_EVENT_LABEL : UNCHECKED_EVENT_LABEL,
  },
  type: TrackingEventsEnum.LINK,
});

const downloadToastTrackingHandler = (
  eventAction: string,
  eventLabel: string
): TrackingLinkEventReturnType => ({
  payload: {
    eventAction,
    eventLabel,
  },
  type: TrackingEventsEnum.LINK,
});

export const FinancingInsurancesContractingLPIStep3DownloadTrackingHandlers = (
  track: TrackBuilderType
): FinancingInsurancesContractingLPIStep3DownloadReturnTrackingHandlersType => ({
  handleDownloadLinkClickTracking: () => track(downloadLinkClickTrackingHandler()),
  handleTogglePostalDocumentationTracking: (postalDocumentation: boolean) =>
    track(togglePostalDocumentationTrackingHandler(postalDocumentation)),
  handleDownloadToastTracking: (eventAction: string) =>
    track(downloadToastTrackingHandler(eventAction, DOWNLOAD_TOAST_EVENT_LABEL)),
  handleCloseDownloadToastTracking: (eventAction: string) =>
    track(downloadToastTrackingHandler(eventAction, CLOSE_DOWNLOAD_TOAST_EVENT_LABEL)),
});
