// Vendors
import { useEffect, useState } from 'react';
import { CountriesCodeEnum } from '@openbank/cf-ui-static-data';
// Handlers
import { AddressModalHandlers } from '../handlers/address-modal.handlers';
// Types
import { AddressModalHookReturnType } from './types/address-modal-return.hook.type';
import { AddressModalHookType } from './types/address-modal.hook.type';
import { AddressValidatorsType } from 'types/country/address-validators.type';
// Utils
import { formatAddressFormValues } from '../utils/address-modal.utils';

export const useAddressModalHook = ({
  countryOfResidence,
  defaultValues,
  isOpen,
  onError,
  onSave,
  reset,
}: AddressModalHookType): AddressModalHookReturnType => {
  const [addressValidators, setAddressValidators] = useState<Record<string, AddressValidatorsType>>(
    {}
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [country, setCountry] = useState<string>(CountriesCodeEnum.GERMANY);

  const { handleChangeCountry, ...handlers } = AddressModalHandlers({
    addressValidators,
    country,
    defaultValues,
    onError,
    onSave,
    reset,
    setAddressValidators,
    setCountry,
    setIsLoading,
  });

  useEffect(() => {
    reset(formatAddressFormValues(defaultValues, countryOfResidence));
  }, [countryOfResidence, defaultValues, reset]);

  useEffect(() => {
    const country = defaultValues?.countryCode ?? countryOfResidence;

    isOpen && handleChangeCountry(country);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return {
    ...handlers,
    addressValidators,
    country,
    handleChangeCountry,
    isLoading,
  };
};
