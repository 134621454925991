// Vendors
import React from 'react';
// Components
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import { ResumeItemComponent } from '@openbank/cb-ui-commons';
// Constants
import { MiscellaneousCurrencyConstants } from '@openbank/cf-ui-static-data';
import { NUMBER } from 'constants/number.constants';
import {
  FINANCING_PPI_CONTRACTING_RESUME_FEE_PROPS,
  FINANCING_PPI_CONTRACTING_RESUME_HOLDER_PROPS,
  FINANCING_PPI_CONTRACTING_RESUME_PRODUCT_PROPS,
} from './constants/financing-ppi-contracting-resume.constants';
// Hooks
import { useFinancingPPIContractingResumeHook } from './hooks/financing-ppi-contracting-resume.hook';
// Styles
import { FinancingPPIContractingComponentStyled } from './financing-ppi-contracting-resume.component.styled';
// Translations
import {
  FEE,
  POLICY_HOLDER,
  PRODUCT_NAME,
  VALUE_FEE,
  VALUE_POLICY_HOLDER,
} from './translations/financing-ppi-contracting-resume.translations';

const FinancingPPIContractingResumeComponent = (): React.ReactElement => {
  const { formatNumber, ppiPercentage, productName, userName } =
    useFinancingPPIContractingResumeHook();

  return (
    <FinancingPPIContractingComponentStyled>
      <ResumeItemComponent
        {...FINANCING_PPI_CONTRACTING_RESUME_PRODUCT_PROPS}
        label={<FormattedMessageComponent id={PRODUCT_NAME} />}
        value={productName}
      />
      <ResumeItemComponent
        {...FINANCING_PPI_CONTRACTING_RESUME_HOLDER_PROPS}
        label={<FormattedMessageComponent id={POLICY_HOLDER} />}
        value={
          <FormattedMessageComponent
            id={VALUE_POLICY_HOLDER}
            values={{
              userName,
            }}
          />
        }
      />
      {ppiPercentage && (
        <ResumeItemComponent
          {...FINANCING_PPI_CONTRACTING_RESUME_FEE_PROPS}
          label={<FormattedMessageComponent id={FEE} />}
          value={
            <FormattedMessageComponent
              id={VALUE_FEE}
              values={{
                ppiPercentage: formatNumber(ppiPercentage / 100, {
                  ...MiscellaneousCurrencyConstants.AMOUNT_PERCENTAGE_FORMAT_PROPS,
                  minimumFractionDigits: NUMBER.FOUR,
                }),
              }}
            />
          }
        />
      )}
    </FinancingPPIContractingComponentStyled>
  );
};

export { FinancingPPIContractingResumeComponent };
