// Constants
import {
  BUTTON_TEST_ID,
  LINK_TEST_ID,
} from './constants/modal-interstitial-doc-expired.configuration.constants';
// Translations
import {
  DOC_EXPIRED_TITLE,
  GOT_TO_PERSONAL_DATA,
  NO_CONTINUE,
} from '../translations/modal-interstitial-doc-expired.translations';
// Types
import { ModalExpiredDocConfigurationPropsType } from './types/modal-interstitial-doc-expired-props.configuration.type';
import { ModalExpiredDocConfigurationType } from './types/modal-interstitial-doc-expired.configuration.type';

const modalExpiredDocConfiguration = ({
  handleNavigateToPersonalInfo,
  handleCloseDocExpiredModal,
  handleExitDocExpiredModal,
  formatMessage,
  userName,
}: ModalExpiredDocConfigurationPropsType): ModalExpiredDocConfigurationType => ({
  title: formatMessage({ id: DOC_EXPIRED_TITLE }, { name: userName }),
  primaryButtonConfiguration: {
    children: formatMessage({ id: GOT_TO_PERSONAL_DATA }),
    onClick: handleNavigateToPersonalInfo,
    testId: BUTTON_TEST_ID,
  },
  linkConfiguration: {
    children: formatMessage({ id: NO_CONTINUE }),
    onClick: handleExitDocExpiredModal,
    testId: LINK_TEST_ID,
  },
  onHeaderClose: handleCloseDocExpiredModal,
});

export { modalExpiredDocConfiguration };
