//Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
// Types
import { UseAddressReturnType } from './types/use-address-return.hook.type';

export const useAddressHook = (): UseAddressReturnType => {
  const { profile } = AppContextConsumerHook(),
    addressDetails = profile?.addressDetails?.[0],
    countryOfResidence = profile?.userDetails?.countryOfResidence;

  const {
    additionalItems1 = '',
    addressTypeTCode,
    countryCode,
    department = '',
    enableConfirmation,
    floor = '',
    number,
    postCode,
    streetLine1,
    town,
  } = addressDetails || {};

  return {
    address: {
      additionalItems1,
      addressType: addressTypeTCode,
      city: town,
      countryCode: countryCode,
      department,
      enableConfirmation,
      floor,
      number,
      postalCode: postCode,
      street: streetLine1,
    },
    countryOfResidence,
  };
};
