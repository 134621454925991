// Constants
import {
  CONTAINERS_ROUTE_PROPS,
  DEFAULT_ROOT_PROPS,
  LOGIN_LOGGED_NAVIGATE_ROUTE_PROPS,
  LOGIN_LOGGED_ROUTE_PROPS,
  NOT_FOUND_ROUTE_PROPS,
} from './orchestrator-router-commons.constants';

export {
  CONTAINERS_ROUTE_PROPS,
  DEFAULT_ROOT_PROPS,
  LOGIN_LOGGED_ROUTE_PROPS,
  LOGIN_LOGGED_NAVIGATE_ROUTE_PROPS,
  NOT_FOUND_ROUTE_PROPS,
};
