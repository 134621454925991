// Vendors
import { TrackingEventsEnum, TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
// Constants
import { CHECKED_EVENT_LABEL, UNCHECKED_EVENT_LABEL } from 'constants/tracking.constants';
import {
  NEXT_BUTTON_CLICK_DOWNLOAD_ERROR_TRACKING_PROPS,
  TOGGLE_RECEIPT_EVENT_ACTION,
} from './constants/financing-insurances-contracting-lpi-step3.handlers.tracking.constants';
// Types
import { FinancingInsurancesContractingLPIStep3ReturnTrackingHandlersType } from './types/financing-insurances-contracting-lpi-step3-return.handlers.tracking.type';
import { TrackBuilderType } from 'types/tracking/track-builder.type';

const nextButtonClickDownloadErrorTrackingHandler = (): TrackingLinkEventReturnType =>
  NEXT_BUTTON_CLICK_DOWNLOAD_ERROR_TRACKING_PROPS;

const toggleReceiptTrackingHandler = (checked: boolean): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: TOGGLE_RECEIPT_EVENT_ACTION,
    eventLabel: checked ? CHECKED_EVENT_LABEL : UNCHECKED_EVENT_LABEL,
  },
  type: TrackingEventsEnum.LINK,
});

export const FinancingInsurancesContractingLPIStep3TrackingHandlers = (
  track: TrackBuilderType
): FinancingInsurancesContractingLPIStep3ReturnTrackingHandlersType => ({
  handleNextButtonClickDownloadErrorTracking: () =>
    track(nextButtonClickDownloadErrorTrackingHandler()),
  handleToggleReceiptTracking: (checked: boolean) => track(toggleReceiptTrackingHandler(checked)),
});
