export const ERROR_CONFIGURATION = {
  assetConfiguration: {
    id: 'Attention_Alternative',
    type: 'illustration',
    sizes: {
      width: 115,
      height: 115,
    },
  },
  textAlign: 'left',
};
