// Vendors
import { apiUtilsCb } from '@openbank/cf-ui-architecture-core';
// Constants
import {
  GET_USER_AVATAR_SERVICE_CONFIGURATION,
  UPDATE_PHOTO_SERVICE_CONFIGURATION,
} from './constants/personal-photo-navigation-footer.service.constants';
// Enumerations
import { AvatarIdsEnumeration } from 'containers/personal/views/photo/enumerations/avatar-ids.enumeration';

const updateUserPhotoService = async (avatar: string): Promise<[Record<string, any>, Response]> =>
  apiUtilsCb.patch({ ...UPDATE_PHOTO_SERVICE_CONFIGURATION, params: { avatar } });

const getUserAvatarService = async (
  avatar: AvatarIdsEnumeration | null
): Promise<[Blob | Record<string, any>, Response]> => {
  const response = await fetch(
    GET_USER_AVATAR_SERVICE_CONFIGURATION.replace('avatar', avatar || ''),
    { cache: 'no-cache' }
  );
  const blob = await response.blob();

  return Promise.resolve([blob || {}, response]);
};

export { getUserAvatarService, updateUserPhotoService };
