// Enumerations
import { ConsentPPIStep2IdsEnumeration } from 'containers/financing/views/ppi/components/contracting/cpi/components/step2/enumerations/consent-ppi-step2-ids.enumeration';
// Types
import { FinancingPPIContractingStep2ConsentHandlersUtilsType } from './types/financing-ppi-contracting-step2-consent.handlers.utils.type';

const addOrRemoveConsentAcceptance = ({
  consentId,
  prevConsents,
}: FinancingPPIContractingStep2ConsentHandlersUtilsType): ConsentPPIStep2IdsEnumeration[] =>
  !prevConsents.includes(consentId)
    ? [...prevConsents, consentId]
    : prevConsents.filter((consent: ConsentPPIStep2IdsEnumeration) => consent !== consentId);

export { addOrRemoveConsentAcceptance };
