// Constants
import { CARD_SETTINGS_SECTION_DIVIDER_PROPS } from 'containers/card-settings/views/landing/constants/card-settings.constants';
// Enumerations
import { QuickActionsActionsIdsEnumeration } from 'enumerations/quick-actions/quick-actions-actions-ids.enumeration';
import { RoutesEnumeration } from 'enumerations/routes.enumeration';
// Translations
import {
  BENEFITS_AND_INSURANCES,
  BENEFITS_AND_INSURANCES_LINK,
  REQUEST_NEW_CARD,
  REQUEST_NEW_CARD_LINK
} from './translations/card-settings-benefits.constants.translations';

const CARD_SETTINGS_BENEFITS_INSURANCES_PROPS = {
    label: BENEFITS_AND_INSURANCES,
    links: [
      {
        actionId: QuickActionsActionsIdsEnumeration.ADVANTAGES,
        label: BENEFITS_AND_INSURANCES_LINK,
        trackingLabel: 'viewMoreBenefitsAndInsurances',
        testId: 'card-settings-benefits-insurances-link',
        url: RoutesEnumeration.BENEFITS_INSURANCE,
      },
    ],
    testing: {
      labelId: 'card-settings-benefits-insurances-label',
      valueId: 'card-settings-benefits-insurances-value',
    },
  },
  CARD_SETTINGS_BENEFITS_NEW_PROPS = {
    label: REQUEST_NEW_CARD,
    links: [
      {
        actionId: QuickActionsActionsIdsEnumeration.REQUEST_NEW_CARD,
        label: REQUEST_NEW_CARD_LINK,
        trackingLabel: 'requestNewCard',
        testId: 'card-settings-benefits-new-card-link',
        url: RoutesEnumeration.REQUEST_NEW_CARD,
      },
    ],
    testing: {
      labelId: 'card-settings-benefits-new-card-label',
      valueId: 'card-settings-benefits-new-card-value',
    },
  },
  CARD_SETTINGS_DETAILS_DIVIDER_PROPS = {
    ...CARD_SETTINGS_SECTION_DIVIDER_PROPS,
    testing: {
      elementId: 'card-settings-benefits-divider',
      titleId: 'card-settings-benefits-divider-title',
    },
  },
  CARD_SETTINGS_BENEFITS_LAYOUT_PROPS = {
    layout: '1fr 1fr 2fr',
  };

export {
  CARD_SETTINGS_BENEFITS_INSURANCES_PROPS,
  CARD_SETTINGS_BENEFITS_NEW_PROPS,
  CARD_SETTINGS_BENEFITS_LAYOUT_PROPS,
  CARD_SETTINGS_DETAILS_DIVIDER_PROPS,
};
