// Contexts
import { useFinancingInsurancesContractingLPIContextConsumerHook } from 'containers/financing/views/ppi/components/contracting/lpi/contexts/financing-insurances-contracting-lpi.context';
// Handlers
import { FinancingInsurancesContractingLPIStep2PlansHandlers } from '../handlers/financing-insurances-contracting-lpi-step2-plans.handlers';
import { FinancingInsurancesContractingLPIStep2PlansTrackingHandlers } from '../handlers/financing-insurances-contracting-lpi-step2-plans.handlers.tracking';
// Hooks
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { FinancingInsurancesContractingLPIStep2PlansHookReturnType } from './types/financing-insurances-contracting-lpi-step2-plans-return.hook.type';

export const useFinancingInsurancesContractingLPIStep2Plans =
  (): FinancingInsurancesContractingLPIStep2PlansHookReturnType => {
    const { plan, setPlan } = useFinancingInsurancesContractingLPIContextConsumerHook();

    return {
      ...FinancingInsurancesContractingLPIStep2PlansHandlers({
        ...AppTrackingHook(FinancingInsurancesContractingLPIStep2PlansTrackingHandlers),
        setPlan,
      }),
      plan,
    };
  };
