// Enumerations
import { UploadDocumentIdsEnumeration } from 'containers/financing/views/credit-limit-increase/components/step4/upload-documentation/components/upload-document/enums/upload-document-ids.enumeration';
// Hooks
import { getRejectedDocumentsMap } from 'containers/financing/views/credit-limit-increase/components/step4/upload-documentation/components/upload-documents-section/hooks/utils/upload-documents-section.utils';
// Types
import { UsePensionerSectionHookReturnType } from './types/pensioner-section-return.hook.type';
import { PensionerSectionPropsType } from '../types/pensioner-section.type';
import { isAustrian } from 'utils/country.utils';

const usePensionerSectionHook = ({
  documentsRejected,
  countryOfResidence,
}: Pick<
  PensionerSectionPropsType,
  'documentsRejected' | 'countryOfResidence'
>): UsePensionerSectionHookReturnType => {
  const rejectedDocumentsMap = documentsRejected ? getRejectedDocumentsMap(documentsRejected) : [];
  const isAnnualPensionRejected: boolean =
    rejectedDocumentsMap[UploadDocumentIdsEnumeration.ANNUAL_PENSION];

  const hasDocumentsRejected = documentsRejected && documentsRejected.length > 0;

  return {
    hasDocumentsRejected,
    isAnnualPensionRejected,
    isAustrian: isAustrian(countryOfResidence),
  };
};

export { usePensionerSectionHook };
