// Types
import { AddressType } from 'types/address.type';
import { ChangeAddressBodyType } from 'containers/personal/views/address/types/change-address-body.type';
// Utils
import { isAustrian } from 'utils/country.utils';

const formatChangeAddressParams = (newAddress: AddressType): ChangeAddressBodyType => {
  const formattedAddress = {
    addressTypeTCode: newAddress.addressType,
    countryCode: newAddress.countryCode,
    enableConfirmation: newAddress.enableConfirmation,
    number: newAddress.number,
    postCode: newAddress.postalCode,
    streetLine1: newAddress.street,
    town: newAddress.city,
  };

  return isAustrian(newAddress.countryCode)
    ? {
        addressDetails: [
          {
            ...formattedAddress,
            department: newAddress.department || '',
            floor: newAddress.floor || '',
          },
        ],
      }
    : {
        addressDetails: [
          {
            ...formattedAddress,
            additionalItems1: newAddress.additionalItems1 || '',
          },
        ],
      };
};

export { formatChangeAddressParams };
