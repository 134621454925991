// Contexts
import { useInsurancesContextConsumerHook } from 'contexts/insurances/insurances.context';
import { useFinancingInsurancesContractingLPIContextConsumerHook } from 'containers/financing/views/ppi/components/contracting/lpi/contexts/financing-insurances-contracting-lpi.context';
// Types
import { UseFinancingInsurancesContractingLPIDetailConfirmationProps } from './types/financing-insurances-contracting-lpi-detail-confirmation-return.hook.type';
// Utils
import { getPlanDetail } from 'containers/financing/views/ppi/components/contracting/lpi/utils/financing-insurances-contracting-lpi.utils';
import { AppContextConsumerHook } from 'contexts/app.context';

const useFinancingInsurancesContractingLPIDetailConfirmation =
  (): UseFinancingInsurancesContractingLPIDetailConfirmationProps => {
    const { profile } = AppContextConsumerHook(),
      { userName } = profile?.userDetails || {};
    const { plan: planId } = useFinancingInsurancesContractingLPIContextConsumerHook();
    const { insurancesInfo } = useInsurancesContextConsumerHook();

    const { coverage, grossPremium } = getPlanDetail({ insurancesInfo, planId }) || {};

    return { coverage, grossPremium, userName };
  };

export { useFinancingInsurancesContractingLPIDetailConfirmation };
