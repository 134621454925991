// Vendors
import styled from 'styled-components';

const ModalInterstitialDocExpiredComponentStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingM};
`;
ModalInterstitialDocExpiredComponentStyled.displayName =
  'ModalInterstitialDocExpiredComponentStyled';

export { ModalInterstitialDocExpiredComponentStyled };
