// Vendors
import { requestHasSuccessStatus } from '@openbank/cf-ui-framework';
// Services
import { propositionAreasLateralBannerService } from '../services/banner.service';
// Types
import { BannerServiceType } from '../services/types/banner.service.type';

const propositionAreasLateralBannerResolver = async (): Promise<
  [BannerServiceType] | [null, true]
> => {
  const [response, { status }] = await propositionAreasLateralBannerService();

  return requestHasSuccessStatus(status) && response ? [response] : [null, true];
};

export { propositionAreasLateralBannerResolver };
