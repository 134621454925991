// Enumerations
import { AddressTypesEnum } from 'components/block-card/components/enumerations/address-types.enumeration';
// Types
import { BlockCardAddressHandlersType } from './types/block-card-address.handlers.type';
import { BlockCardAddressReturnHandlersType } from './types/block-card-address-return.handlers.type';
import { CancelDeleteAddressHandlerType } from './types/cancel-delete-address-handler.type';
import { DeleteAddressHandlerType } from './types/delete-address-handler.type';
import { CloseDeleteModalHandlerType } from './types/close-delete-modal-handler.type';
import { OpenDeleteModalHandlerType } from './types/open-delete-modal-handler.type';
import { SelectAddressHandlerType } from './types/select-address-handler.type';
import { SaveAddressButtonClickHandlerType } from './types/save-address-button-click-handler.type';
import { OpenEditAddressModalHandlerType } from './types/open-edit-address-modal-handler.type';
import { OpenAddressModalHandlerType } from './types/open-address-modal-handler.type';
import { CloseAddressModalHandlerType } from './types/close-address-modal-handler.type';

const closeAddressModalHandler = ({ setShowAddressModal }: CloseAddressModalHandlerType) => {
  setShowAddressModal(false);
};

const openAddressModalHandler = ({
  handleOpenAddressModalTracking,
  setShowAddressModal,
}: OpenAddressModalHandlerType) => {
  handleOpenAddressModalTracking();
  setShowAddressModal(true);
};

const openEditAddressModalHandler = ({
  handleOpenEditAddressModalTracking,
  setShowAddressModal,
}: OpenEditAddressModalHandlerType) => {
  handleOpenEditAddressModalTracking();
  setShowAddressModal(true);
};

const saveAddressButtonClickHandler = ({
  address,
  formValues,
  setSelectedAddress,
  setShowAddressModal,
  setTempAddress,
}: SaveAddressButtonClickHandlerType) => {
  const {
    additional: additionalItems1,
    zip: postalCode,
    doorNumber: department,
    staircase: floor,
    ...rest
  } = formValues;

  setTempAddress({
    ...address,
    ...rest,
    additionalItems1,
    addressType: AddressTypesEnum.TEMP,
    department,
    floor,
    postalCode,
  });
  setSelectedAddress(AddressTypesEnum.TEMP);
  setShowAddressModal(false);
};

const selectAddressHandler = ({
  handleSelectAddressTracking,
  selected,
  setSelectedAddress,
}: SelectAddressHandlerType) => {
  handleSelectAddressTracking();
  setSelectedAddress(selected);
};

const openDeleteModalHandler = ({
  handleOpenDeleteModalTracking,
  setShowDeleteModal,
}: OpenDeleteModalHandlerType) => {
  handleOpenDeleteModalTracking();
  setShowDeleteModal(true);
};

const closeDeleteModalHandler = ({
  handleCloseDeleteModalTracking,
  setShowDeleteModal,
}: CloseDeleteModalHandlerType) => {
  handleCloseDeleteModalTracking();
  setShowDeleteModal(false);
};

const deleteAddressHandler = ({
  handleDeleteAddressTracking,
  setSelectedAddress,
  setShowDeleteModal,
  setTempAddress,
}: DeleteAddressHandlerType) => {
  handleDeleteAddressTracking();
  setTempAddress(null);
  setSelectedAddress(AddressTypesEnum.HBTL);
  setShowDeleteModal(false);
};

const cancelDeleteAddressHandler = ({
  handleCancelDeleteAddressTracking,
  setShowDeleteModal,
}: CancelDeleteAddressHandlerType) => {
  handleCancelDeleteAddressTracking();
  setShowDeleteModal(false);
};

const BlockCardAddressHandlers = ({
  address,
  handleCancelDeleteAddressTracking,
  handleCloseDeleteModalTracking,
  handleDeleteAddressTracking,
  handleOpenAddressModalTracking,
  handleOpenDeleteModalTracking,
  handleOpenEditAddressModalTracking,
  handleSelectAddressTracking,
  setSelectedAddress,
  setShowAddressModal,
  setShowDeleteModal,
  setTempAddress,
}: BlockCardAddressHandlersType): BlockCardAddressReturnHandlersType => ({
  handleCancelDeleteAddress: () =>
    cancelDeleteAddressHandler({ handleCancelDeleteAddressTracking, setShowDeleteModal }),
  handleCloseAddressModal: () => closeAddressModalHandler({ setShowAddressModal }),
  handleCloseDeleteModal: () =>
    closeDeleteModalHandler({ handleCloseDeleteModalTracking, setShowDeleteModal }),
  handleDeleteAddress: () =>
    deleteAddressHandler({
      handleDeleteAddressTracking,
      setSelectedAddress,
      setShowDeleteModal,
      setTempAddress,
    }),
  handleSelectAddress: selected =>
    selectAddressHandler({ handleSelectAddressTracking, selected, setSelectedAddress }),
  handleOpenAddressModal: () =>
    openAddressModalHandler({ handleOpenAddressModalTracking, setShowAddressModal }),
  handleOpenEditAddressModal: () =>
    openEditAddressModalHandler({ handleOpenEditAddressModalTracking, setShowAddressModal }),
  handleOpenDeleteModal: () =>
    openDeleteModalHandler({ handleOpenDeleteModalTracking, setShowDeleteModal }),
  handleSaveAddressButtonClick: formValues =>
    saveAddressButtonClickHandler({
      address,
      formValues,
      setShowAddressModal,
      setTempAddress,
      setSelectedAddress,
    }),
});

export { BlockCardAddressHandlers };
