// Vendors
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// Contexts
import { ManagerContextConsumerHook } from 'contexts/manager.context';
import { PersonalPhotoContextConsumerHook } from 'containers/personal/views/photo/contexts/personal-photo.context';
import { ToastContextConsumerHook } from 'contexts/toast.context';
// Events handlers
import PersonalPhotoNavigationFooterUiHandlers from '../handlers/personal-photo-navigation-footer-ui.handlers';
import PersonalPhotoNavigationFooterApiHandlers from '../handlers/personal-photo-navigation-footer-api.handlers';
import PersonalPhotoNavigationFooterTrackingHandlers from '../handlers/personal-photo-navigation-footer.tracking.handlers';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { PersonalPhotoNavigationFooterHookType } from './types/personal-photo-navigation-footer.hook.type';

const usePersonalPhotoNavigationFooterHook = (): PersonalPhotoNavigationFooterHookType => {
  const [fetching, setFetching] = useState<boolean>(false);
  const navigate = useNavigate();

  const { avatar, cropCompleted, photo, photoModal, setPhotoModal } =
      PersonalPhotoContextConsumerHook(),
    { setToastConfiguration } = ToastContextConsumerHook(),
    { setMe } = ManagerContextConsumerHook();

  const { handleCancelUploadPhotoLinkClickTracking, handleSaveUploadPhotoButtonClickTracking } =
    AppTrackingHook(PersonalPhotoNavigationFooterTrackingHandlers);

  const { handleSaveUploadPhotoButtonClick } = PersonalPhotoNavigationFooterApiHandlers({
    avatar,
    handleSaveUploadPhotoButtonClickTracking,
    photo,
    setFetching,
    setMe,
    setToastConfiguration,
    navigate,
  });

  useEffect(() => {
    if (cropCompleted) {
      handleSaveUploadPhotoButtonClick();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cropCompleted]);

  return {
    ...PersonalPhotoNavigationFooterUiHandlers({
      handleCancelUploadPhotoLinkClickTracking,
      hasModifiedData: Boolean(avatar || photo),
      navigate,
      setPhotoModal,
    }),
    handleSaveUploadPhotoButtonClick,
    avatar,
    fetching,
    photo,
    photoModal,
  };
};

export default usePersonalPhotoNavigationFooterHook;
