// Vendors
import React from 'react';
// Components
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import RequestAccessStep1Descriptions from './components/descriptions/request-access-step1-descriptions.component';
import WizardStepFooterComponent from 'components/wizard-step-footer/wizard-step-footer.component';
import {
  ParagraphComponent,
  LineSeparatorComponent,
  TitleComponent,
} from '@openbank/cb-ui-commons';
// Constants
import constants from './constants/request-access-step1.constants';
import { FIRST_STEP_TARGETS } from 'constants/wizard/wizard.constants';
// Styles
import {
  RequestAccessStep1ContainerStyled,
  RequestAccessStep1ListItemStyled,
  RequestAccessStep1ListStyled,
} from './request-access-step1.component.styled';
// Translations
import translations from './translations/request-access-step1.translations';

const RequestAccessStep1Component = (): React.ReactElement => (
  <RequestAccessStep1ContainerStyled>
    <RequestAccessStep1Descriptions />
    <LineSeparatorComponent {...constants.LINE_SEPARATOR_PROPS} />
    <TitleComponent {...constants.TITLE_SECTION_PROPS}>
      <FormattedMessageComponent id={translations.TITLE} />
    </TitleComponent>
    <RequestAccessStep1ListStyled>
      {constants.REQUEST_ACCESS_STEP1_MESSAGES.map(config => (
        <RequestAccessStep1ListItemStyled key={config.id}>
          <ParagraphComponent {...config}>
            <FormattedMessageComponent id={config.text} />
          </ParagraphComponent>
        </RequestAccessStep1ListItemStyled>
      ))}
    </RequestAccessStep1ListStyled>
    <WizardStepFooterComponent {...constants.WIZARD_FOOTER_PROPS} targets={FIRST_STEP_TARGETS} />
  </RequestAccessStep1ContainerStyled>
);

export default RequestAccessStep1Component;
