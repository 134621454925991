// Vendors
import React from 'react';
// Components
import { ParagraphComponent, LinkComponent } from '@openbank/cb-ui-commons';
// Constants
import {
  MAP_INTERSTICIAL_TAG_SHAPES_TO_DOMAIN_SHAPES,
  MAP_INTERSTICIAL_TAG_TYPES_TO_DOMAIN_TYPES,
} from 'components/modal-interstitial/constants/modal-interstitial.constants';
import {
  BUTTON_TEST_ID,
  DATE_PLACEHOLDER,
  EMPHASIS,
  END_LINK,
  LINK_ID_TEXT_SEPARATOR,
  LINK_TEXT_SEPARATOR,
  START_LINK,
} from './constants/modal-interstitial-commercial.configuration.constants';
import { NUMBER } from 'constants/number.constants';
// Types
import { ModalCommercialConfigurationPropsType } from './types/modal-interstitial-commercial-props.configuration.type';
import { ModalCommercialConfigurationType } from './types/modal-interstitial-commercial.configuration.type';
import { FormatTextWithLinkPropsType } from './types/format-text-with-link-props.type';
// Utilities
import { getImageByChannel } from '../utils/modal-interstitial-commercial.utils';

const formatTextWithLink = ({ text, onClick }: FormatTextWithLinkPropsType): React.ReactNode => {
  if (text.includes(START_LINK)) {
    const indexStartLink = text.indexOf(START_LINK);
    const indexEndLink = text.indexOf(END_LINK);
    const textBeforeLink = text.slice(0, indexStartLink);
    const textAfterLink = text.slice(indexEndLink + NUMBER.SEVEN);
    const linkTextAndProps = text.slice(indexStartLink + NUMBER.FIVE, indexEndLink);
    const [linkIdText, linkText] = linkTextAndProps.split(LINK_TEXT_SEPARATOR);
    const linkId = linkIdText.split(LINK_ID_TEXT_SEPARATOR)[1].replaceAll('"', '');

    return (
      <ParagraphComponent>
        {textBeforeLink}
        <LinkComponent id={linkId} onClick={onClick}>
          {linkText}
        </LinkComponent>
        {textAfterLink ?? ''}
      </ParagraphComponent>
    );
  }

  return text;
};

const modalCommercialConfiguration = ({
  bottomText,
  button,
  handleClose,
  handleClosePromotion,
  handleKnowMore,
  images,
  label,
  layout,
  tag,
  validUntilDate,
  validUntilLabel,
  formatDate,
}: ModalCommercialConfigurationPropsType): ModalCommercialConfigurationType => ({
  buttonConfiguration: {
    children: button.text,
    onClick: handleKnowMore,
    rwdFull: false,
    testId: BUTTON_TEST_ID,
  },
  footerText: formatTextWithLink({ text: bottomText, onClick: handleClosePromotion }),
  onClose: handleClose,
  image: {
    id: getImageByChannel(images)?.imageUrl ?? '',
    position: layout.imagePosition,
  },
  tagConfiguration: {
    children: label,
    shape: MAP_INTERSTICIAL_TAG_SHAPES_TO_DOMAIN_SHAPES[tag.shape],
    type: MAP_INTERSTICIAL_TAG_TYPES_TO_DOMAIN_TYPES[tag.labelStyle],
    isEmphasized: tag.labelStyle.includes(EMPHASIS),
  },
  tagDescription: validUntilLabel.replace(DATE_PLACEHOLDER, formatDate(validUntilDate)),
});

export { modalCommercialConfiguration };
