// Vendors
import { useState, useEffect, useMemo, useRef, MutableRefObject } from 'react';
// Constants
import { INITIAL_CONFIG } from './constants/use-address-predictions.constants';
// Handlers
import { UseAddressPredictionsHandler } from './handlers/use-address-predictions.handler';
// Hooks
import { useDebounce } from 'hooks/useDebounce/useDebounce';
// Types
import { AutocompleteService, GeocoderService } from './types/google-api.type';
import {
  AddressPrediction,
  UseAddressPredictionsHookConfigType,
  UseAddressPredictionsHookReturnType,
} from './types/use-address-predictions.hook.type';
import { AddDetailsToPredictionsUtilClosure } from './utils/use-address-predictions.util';

export const useAddressPredictions = (
  query: string,
  {
    country = INITIAL_CONFIG.country,
    delay = INITIAL_CONFIG.delay,
    minTriggerLength = INITIAL_CONFIG.minTriggerLength,
  }: UseAddressPredictionsHookConfigType = INITIAL_CONFIG
): UseAddressPredictionsHookReturnType => {
  const [predictions, setPredictions] = useState<AddressPrediction[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const debouncedPredictionQuery = useDebounce(query, delay);

  const autocompleteService = useRef<AutocompleteService>();
  const geocoderService = useRef<GeocoderService>();

  const { handleGetPredictions, handleLoadGoogleServices } = useMemo(
    () =>
      UseAddressPredictionsHandler({
        autocompleteService: autocompleteService as MutableRefObject<AutocompleteService>,
        country,
        geocoderService: geocoderService as MutableRefObject<GeocoderService>,
        setPredictions,
        setIsLoading,
      }),
    []
  );

  const { addDetailsToPredictionUtil } = AddDetailsToPredictionsUtilClosure({
    country,
    geocoderService: geocoderService as MutableRefObject<GeocoderService>,
  });

  useEffect(() => {
    handleGetPredictions({ query: debouncedPredictionQuery, minTriggerLength });
  }, [debouncedPredictionQuery, handleGetPredictions, minTriggerLength]);

  useEffect(() => {
    handleLoadGoogleServices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return { predictions, isLoading, addDetailsToPredictionUtil };
};
