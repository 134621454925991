// Vendors
import React from 'react';
import { AmazonTheme } from '@openbank/cf-ui-static-data';
// Components
import {
  ExpandableRowComponent,
  LineSeparatorComponent,
  ParagraphComponent,
  paragraphConstants,
} from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import { EXPANDABLE_ROW_PROPS } from './constants/financing-insurances-contracting-lpi-step3-additional.constants';
// Hooks
import { useFinancingInsurancesContractingLPIStep3Additional } from './hooks/financing-insurances-contracting-lpi-step3-additional.hook';
// Styles
import { LayoutColumnFlexComponentStyled } from 'styles/app.styled';
// Translations
import {
  TITLE,
  TERMINATION,
  NOTIFY,
  PAYMENT,
} from './translations/financing-insurances-contracting-lpi-step3-additional.translations';
// Utils
import { makeChunkLinkIntlUtil } from 'hooks/useCbIntl/utils/useCbIntl.utils';

export const FinancingInsurancesContractingLPIStep3AdditionalComponent = (): React.ReactElement => {
  const { handleAmazonLinkClick } = useFinancingInsurancesContractingLPIStep3Additional();

  return (
    <ExpandableRowComponent
      {...EXPANDABLE_ROW_PROPS}
      title={
        <ParagraphComponent bold size={paragraphConstants.SIZES.M}>
          <FormattedMessageComponent id={TITLE} />
        </ParagraphComponent>
      }
    >
      <LayoutColumnFlexComponentStyled
        gap={AmazonTheme.default.spaces.spacingS}
        marginTop={AmazonTheme.default.spaces.spacingXxs}
      >
        <LayoutColumnFlexComponentStyled gap={AmazonTheme.default.spaces.spacingXxs}>
          <ParagraphComponent bold>
            <FormattedMessageComponent id={TERMINATION.title} />
          </ParagraphComponent>
          <ParagraphComponent>
            <FormattedMessageComponent
              id={TERMINATION.description}
              values={{
                terminationLink: chunk =>
                  makeChunkLinkIntlUtil({
                    chunk,
                    onClick: handleAmazonLinkClick,
                  }),
              }}
            />
          </ParagraphComponent>
        </LayoutColumnFlexComponentStyled>
        <LineSeparatorComponent />
        <LayoutColumnFlexComponentStyled gap={AmazonTheme.default.spaces.spacingXxs}>
          <ParagraphComponent bold>
            <FormattedMessageComponent id={NOTIFY.title} />
          </ParagraphComponent>
          <ParagraphComponent>
            <FormattedMessageComponent id={NOTIFY.description} />
          </ParagraphComponent>
        </LayoutColumnFlexComponentStyled>
        <LineSeparatorComponent />
        <LayoutColumnFlexComponentStyled gap={AmazonTheme.default.spaces.spacingXxs}>
          <ParagraphComponent bold>
            <FormattedMessageComponent id={PAYMENT.title} />
          </ParagraphComponent>
          <ParagraphComponent>
            <FormattedMessageComponent id={PAYMENT.description} />
          </ParagraphComponent>
        </LayoutColumnFlexComponentStyled>
      </LayoutColumnFlexComponentStyled>
    </ExpandableRowComponent>
  );
};
