// Constants
import { StepEnumToIndexMap } from '../constants/credit-limit-increase.constants';
import { WEB } from './constants/step-4-account-aggregation.handler.constants';
// Enumerations
import { CreditLimitIncreaseStepsEnumeration } from '../enums/credit-limit-increase.view.enumeration';
// Resolvers
import { backToPreviousStepResolver } from '../resolvers/step-0-init.resolvers';
import {
  uploadDocumentationGoNextResolver,
  uploadDocumentToGetDocumentUriResolver,
  uploadDocumentWithDocumentUriResolver,
} from '../resolvers/step-4-upload-documentation.resolvers';
import { rejectDocumentResolver } from 'resolvers/upload-documents/upload-documents.resolvers';
// Types
import { UploadDocumentationHandlersPropsType } from './types/step-4-upload-documentation-props.handler.type';
import { UploadDocumentationHandlersReturnType } from './types/step-4-upload-documentation-return.handler.type';
import { UploadDocumentHandlerType } from './types/upload-document.handler.type';
import { UploadDocumentationGoNextHandlerType } from './types/upload-documentation-go-next.handler.type';
import { UploadDocumentationBackHandlerPropsType } from './types/upload-documentation-props.handler.type';
// Utils
import {
  getMonthlyPaysheet,
  getUploadDocumentTypeProp,
  validateFile,
} from './utils/step-4-upload-documentation.utils';

const uploadDocumentationBackHandler = async ({
  cardId,
  creditLimitContext,
  setCurrentStep,
  handleClickPreviousButtonTracking,
}: UploadDocumentationBackHandlerPropsType) => {
  const { step4, setStep4, wizardStatus, setWizardStatus } = creditLimitContext;

  setWizardStatus({ ...wizardStatus, fetching: true, error: false });

  const [response, error] = await backToPreviousStepResolver({
    cardId,
    caseId: wizardStatus.caseId,
    currentStep: CreditLimitIncreaseStepsEnumeration.UPLOADING_DOCUMENT,
  });

  if (error) {
    setWizardStatus({ ...wizardStatus, fetching: false, error: true });
    return;
  }

  if (response) {
    const currentStep = StepEnumToIndexMap[response.nextStep];
    const currentStepInText = response.nextStep as CreditLimitIncreaseStepsEnumeration;

    setStep4({ ...step4, stepData: {} });
    setWizardStatus({
      ...wizardStatus,
      fetching: false,
      error: false,
      currentStep,
      currentStepInText,
    });
    setCurrentStep(currentStep);
    handleClickPreviousButtonTracking();
  }
};

const uploadDocumentationGoNextHandler = async ({
  creditLimitContext,
  documents,
  setCurrentStep,
}: UploadDocumentationGoNextHandlerType) => {
  const { step4, setStep4, step5, setStep5, wizardStatus, setWizardStatus } = creditLimitContext;

  setStep4({ ...step4, isLoadingNextRequest: true });

  const [response, error] = await uploadDocumentationGoNextResolver({
    caseId: wizardStatus.caseId,
    documents,
  });

  if (error) {
    setStep4({ ...step4, isLoadingNextRequest: false });
    setWizardStatus({ ...wizardStatus, fetching: false, error: true });
    return;
  }
  if (response) {
    const currentStep = StepEnumToIndexMap[response.nextStep];
    const currentStepInText = response.nextStep as CreditLimitIncreaseStepsEnumeration;

    setStep5({
      ...step5,
      ...response.stepData,
    });
    setWizardStatus({
      ...wizardStatus,
      fetching: false,
      error: false,
      currentStep,
      currentStepInText,
    });
    setCurrentStep(currentStep);
    setStep4({ ...step4, isLoadingNextRequest: false });
  }
};

const uploadDocumentHandler = async ({
  file,
  id,
  customerId,
  creditLimitContext,
  controller1,
  controller2,
  handleShowUploadErrorTracking,
}: UploadDocumentHandlerType) => {
  if (!customerId) return;

  const { step4, setStep4 } = creditLimitContext;

  setStep4({ ...step4, documentsInfo: { ...step4.documentsInfo, [id]: { isLoading: true } } });

  const [response, error] = await uploadDocumentToGetDocumentUriResolver({
    customerId,
    params: {
      documentType: getUploadDocumentTypeProp(id),
      monthlyPaysheet: getMonthlyPaysheet(id),
      sourceSystem: WEB,
    },
    signal: controller1.signal,
  });

  if (error) {
    handleShowUploadErrorTracking(id);
    setStep4({
      ...step4,
      documentsInfo: { ...step4.documentsInfo, [id]: { hasBackOfficeError: true } },
    });
    return;
  }
  if (response) {
    const { documentUri, documentId } = response;
    const [response2, error2] = await uploadDocumentWithDocumentUriResolver({
      customerId,
      documentUri,
      file,
      signal: controller2.signal,
    });

    if (error2) {
      handleShowUploadErrorTracking(id);
      setStep4({
        ...step4,
        documentsInfo: { ...step4.documentsInfo, [id]: { hasBackOfficeError: true } },
      });
      return;
    }
    if (response2) {
      const dataToUpload = {
        documentId,
        type: getUploadDocumentTypeProp(id),
      };
      setStep4({
        ...step4,
        documentsInfo: { ...step4.documentsInfo, [id]: { isUploaded: true, dataToUpload } },
      });
    }
  }
};

export const rejectDocumentHandler = async (id: string): Promise<void> => {
  await rejectDocumentResolver({ documentId: id });
};

const UploadDocumentationHandlers = ({
  cardId,
  creditLimitContext,
  setCurrentStep,
}: UploadDocumentationHandlersPropsType): UploadDocumentationHandlersReturnType => ({
  handleUploadDocumentationBack: handleClickPreviousButtonTracking =>
    uploadDocumentationBackHandler({
      cardId,
      creditLimitContext,
      setCurrentStep,
      handleClickPreviousButtonTracking,
    }),
  handleUploadDocumentationGoNext: documents =>
    uploadDocumentationGoNextHandler({ creditLimitContext, documents, setCurrentStep }),
  handleUploadDocument: props =>
    validateFile({ uploadDocumentHandler, creditLimitContext, ...props }),
  handleRejectDocument: (id: string) => rejectDocumentHandler(id),
});

export { UploadDocumentationHandlers };
