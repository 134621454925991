// Vendors
import React from 'react';
// Components
import WizardStepFooterComponent from 'components/wizard-step-footer/wizard-step-footer.component';
// Hooks
import { useRecoveryKeyStep1ActionsHook } from './hooks/recovery-key-step1-actions.hook';
// Utilities
import {
  hasSomeDataChanged,
  recoveryKeyStep1NextButtonIsDisabled,
} from './utils/recovery-key-step1-actions.utils';

const RecoveryKeyStep1ActionsComponent = (): any => {
  const {
    captcha,
    fetching,
    email,
    prefix,
    phoneNumber,
    handleInitRecoveryKeyProcessButtonClick: nextClick,
  } = useRecoveryKeyStep1ActionsHook();

  return (
    <WizardStepFooterComponent
      shouldShowModal={hasSomeDataChanged({ captcha, email: email ?? '', prefix, phoneNumber })}
      isFooterDisabled={fetching}
      rightButtonConfiguration={{
        onClick: nextClick,
        disabled: recoveryKeyStep1NextButtonIsDisabled({
          captcha,
          fetching,
          email: email ?? '',
          prefix,
          phoneNumber,
        }),
        loading: fetching,
      }}
    />
  );
};

export { RecoveryKeyStep1ActionsComponent };
