// Constants
import { messageConstants } from '@openbank/cb-ui-commons';

export default {
  INPUT_PREFIX_SUFFIX_PROPS: {
    placeholder: '00,00',
    type: 'currency',
    suffix: '€',
  },
  STEP_2_WIZARD_FOOTER_PROPS: {
    hasDefaultLeftButton: true,
  },
  SHUFA_URL: 'https://www.schufa.de/',
  KSV_URL: 'https://www.ksv.at/',
  MESSAGE_PROPS: {
    testId: 'employment-data-message-info',
    type: messageConstants.TYPES.INFORMATIVE,
  },
} as const;
