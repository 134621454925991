// Vendors
import { PayloadLinkEventType, TrackingEventsEnum } from '@openbank/cf-ui-static-data';
// Constants
import {
  ARRAY_BANNERS_POSITION,
  BANNER_APPEAR,
  CLICK_BANNER_BUTTON,
} from './constants/banner.tracking.constants';
// Types
import { BannerTrackingType } from './types/banner.tracking.type';
import { BannerServiceType } from '../services/types/banner.service.type';
import { CustomTrackingLinkEventReturnType } from './types/custom-tracking-link-event-return.type';
// Utils
import { valueOrEmptyString } from 'utils/value-or-empty-string.utils';

const getLinkTypeTrackingConfiguration = (
  payload: PayloadLinkEventType,
  bannerResponse: BannerServiceType
): CustomTrackingLinkEventReturnType => {
  const banner = bannerResponse.propositions[0];

  if (!ARRAY_BANNERS_POSITION.find(p => p === banner?.locationId)) {
    ARRAY_BANNERS_POSITION.push(banner?.locationId);
  }

  return {
    payload: {
      ...payload,
      eventCategory: valueOrEmptyString(banner?.content.type),
      atActivity: valueOrEmptyString(banner?.feedback.activityId),
      atOffer: valueOrEmptyString(banner?.feedback.offerId),
      atExperience: valueOrEmptyString(banner?.feedback.experienceId),
      atTrafficAllocation: valueOrEmptyString(banner?.feedback.trafficAllocationId),
      product_id: [valueOrEmptyString(banner?.content.creativityId)],
      product_name: [valueOrEmptyString(banner?.content.title)],
      product_variant: [valueOrEmptyString(banner?.content.type)],
      product_brand: [valueOrEmptyString(banner?.content.label)],
      product_position: [
        (ARRAY_BANNERS_POSITION.findIndex(p => p === banner?.locationId) + 1).toString(),
      ],
    },
    type: TrackingEventsEnum.LINK,
  };
};

const BannerTracking = (track: (param: unknown) => void): BannerTrackingType => ({
  handleBannerAppearTracking: (bannerResponse: BannerServiceType) =>
    track(getLinkTypeTrackingConfiguration(BANNER_APPEAR, bannerResponse)),
  handleClickBannerButtonTracking: (bannerResponse: BannerServiceType) =>
    track(getLinkTypeTrackingConfiguration(CLICK_BANNER_BUTTON, bannerResponse)),
});

export { BannerTracking };
