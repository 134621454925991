// Themes
import { AmazonTheme } from '@openbank/cf-ui-static-data';
// Constants
import { titleConstants } from '@openbank/cb-ui-commons';
// Translations
import translations from '../translations/request-access-step1.translations';

export default {
  LINE_SEPARATOR_PROPS: {
    color: AmazonTheme.default.colors.backgroundTertiaryC,
  },
  TITLE_SECTION_PROPS: {
    bold: true,
    tag: titleConstants.TAGS.H6,
    testId: 'access-portability-title-section',
  },
  REQUEST_ACCESS_STEP1_MESSAGES: [
    {
      id: 0,
      testId: 'personal-access-portability-request-message-01',
      text: translations.MESSAGE_1,
    },
    {
      id: 1,
      testId: 'personal-access-portability-request-message-02',
      text: translations.MESSAGE_2,
    },
    {
      id: 2,
      testId: 'personal-access-portability-request-message-03',
      text: translations.MESSAGE_3,
    },
    {
      id: 3,
      testId: 'personal-access-portability-request-message-04',
      text: translations.MESSAGE_4,
    },
    {
      id: 4,
      testId: 'personal-access-portability-request-message-05',
      text: translations.MESSAGE_5,
    },
  ],
  WIZARD_FOOTER_PROPS: {
    shouldShowModal: false,
  },
};
