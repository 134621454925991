//Constants
import { tagConstants } from '@openbank/cb-ui-commons';
// Enumerations
import { InterstitialTagTypesEnum } from 'enumerations/interstitials/interstitial-tag-types.enum';
import { InterstitialTagShapesEnum } from 'enumerations/interstitials/interstitial-tag-shapes.enum';

const MAP_INTERSTICIAL_TAG_TYPES_TO_DOMAIN_TYPES = {
  [InterstitialTagTypesEnum.TAG_TYPE_NORMAL_ACTION]: tagConstants.TYPES.ACTION,
  [InterstitialTagTypesEnum.TAG_TYPE_NORMAL_DISABLED]: tagConstants.TYPES.DISABLED,
  [InterstitialTagTypesEnum.TAG_TYPE_NORMAL_INFORMATIVE]: tagConstants.TYPES.INFORMATIVE,
  [InterstitialTagTypesEnum.TAG_TYPE_NORMAL_IN_PROGRESS]: tagConstants.TYPES.IN_PROCESS,
  [InterstitialTagTypesEnum.TAG_TYPE_NORMAL_PENDING]: tagConstants.TYPES.PENDING,
  [InterstitialTagTypesEnum.TAG_TYPE_NORMAL_SUCCESS]: tagConstants.TYPES.SUCCESS,
  [InterstitialTagTypesEnum.TAG_TYPE_NORMAL_WARNING]: tagConstants.TYPES.WARNING,
  [InterstitialTagTypesEnum.TAG_TYPE_EMPHASIS_ACTION]: tagConstants.TYPES.ACTION,
  [InterstitialTagTypesEnum.TAG_TYPE_EMPHASIS_DISABLED]: tagConstants.TYPES.DISABLED,
  [InterstitialTagTypesEnum.TAG_TYPE_EMPHASIS_INFORMATIVE]: tagConstants.TYPES.INFORMATIVE,
  [InterstitialTagTypesEnum.TAG_TYPE_EMPHASIS_IN_PROGRESS]: tagConstants.TYPES.IN_PROCESS,
  [InterstitialTagTypesEnum.TAG_TYPE_EMPHASIS_PENDING]: tagConstants.TYPES.PENDING,
  [InterstitialTagTypesEnum.TAG_TYPE_EMPHASIS_SUCCESS]: tagConstants.TYPES.SUCCESS,
  [InterstitialTagTypesEnum.TAG_TYPE_EMPHASIS_WARNING]: tagConstants.TYPES.WARNING,
  [InterstitialTagTypesEnum.TAG_TYPE_OTHER_NEW]: tagConstants.TYPES.NEW,
  [InterstitialTagTypesEnum.TAG_TYPE_OTHER_IDENTIFICATION]: tagConstants.TYPES.IDENTIFICATION,
  [InterstitialTagTypesEnum.TAG_TYPE_OTHER_DISCOUNT]: tagConstants.TYPES.PROMOTIONAL,
  [InterstitialTagTypesEnum.TAG_TYPE_OTHER_ADDITIONAL_INFO]: tagConstants.TYPES.BASIC,
};

const MAP_INTERSTICIAL_TAG_SHAPES_TO_DOMAIN_SHAPES = {
  [InterstitialTagShapesEnum.TAG_SHAPE_ARROW]: tagConstants.SHAPES.ARROW,
  [InterstitialTagShapesEnum.TAG_SHAPE_FLAP]: tagConstants.SHAPES.FLAP,
  [InterstitialTagShapesEnum.TAG_SHAPE_PILL]: tagConstants.SHAPES.PILL,
  [InterstitialTagShapesEnum.TAG_SHAPE_SQUARE]: tagConstants.SHAPES.SQUARE,
};

export { MAP_INTERSTICIAL_TAG_SHAPES_TO_DOMAIN_SHAPES, MAP_INTERSTICIAL_TAG_TYPES_TO_DOMAIN_TYPES };
