// Vendors
import { TrackingEventsEnum, TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';

const AMAZON_LINK_CLICK_TRACKING_PROPS: TrackingLinkEventReturnType = {
  payload: {
    eventAction: 'clickLink',
    eventLabel: 'amazonLink',
  },
  type: TrackingEventsEnum.LINK,
};

export { AMAZON_LINK_CLICK_TRACKING_PROPS };
