// Vendors
import { useEffect, useState } from 'react';
import { cookies } from '@openbank/cf-ui-architecture-core';
import { useSearchParams } from 'react-router-dom';
// Enums
import { StorageCookiesEnum } from '@openbank/cf-ui-static-data';
// Handlers
import LogoutHandlers from 'handlers/logout.handlers';
// Utils
import {
  changeAccessKeyFromMobile,
  isAccessKeyCookieExpired,
} from '../../authorization/utils/entrypoint-authorization.utils';
// Types
import { AppNoUserDataHookReturnType } from './types/app-no-user-data.hook.return.type';
// Contexts
import { MobileConnectorContextConsumerHook } from 'providers/mobile-connector/contexts/mobile-connector.provider.context';
import { DeviceTypeHook } from 'hooks/device-type.hook';

const AppNoUserDataHook = (): AppNoUserDataHookReturnType => {
  const accessKeyExpired = cookies.getCookie(StorageCookiesEnum.ACCESS_KEY_EXPIRED);
  const { isHybrid, onNavigateHybridFlow } = MobileConnectorContextConsumerHook();
  const [searchParams] = useSearchParams();
  const { handleMakeUserLogoutEvent } = LogoutHandlers();
  const { isMobileOrTablet } = DeviceTypeHook();
  const [showExpiredAccessKeyModal, setShowExpiredAccessKeyModal] = useState<boolean>(() =>
    isAccessKeyCookieExpired(accessKeyExpired)
  );

  useEffect(() => {
    setShowExpiredAccessKeyModal(!changeAccessKeyFromMobile({ isHybrid, searchParams }));
  }, []);

  return {
    isMobileOrTablet,
    showExpiredAccessKeyModal,
    leftArrowClick: isHybrid ? onNavigateHybridFlow : handleMakeUserLogoutEvent,
    onAcceptExpiredAccessKeyModal: () => setShowExpiredAccessKeyModal(false),
  };
};

export { AppNoUserDataHook };
