// Vendors
import { TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
// Constants
import { PRIVACY_POLICY_LINK_CLICK_TRACKING_PROPS } from './constants/financing-insurances-contracting-lpi-step3-disclaimer.handlers.tracking.constants';
// Types
import { FinancingInsurancesContractingLPIStep3DisclaimerReturnTrackingHandlersType } from './types/financing-insurances-contracting-lpi-step3-disclaimer-return.handlers.tracking.type';
import { TrackBuilderType } from 'types/tracking/track-builder.type';

const privacyPolicyLinkClickTrackingHandler = (): TrackingLinkEventReturnType =>
  PRIVACY_POLICY_LINK_CLICK_TRACKING_PROPS;

export const FinancingInsurancesContractingLPIStep3DisclaimerTrackingHandlers = (
  track: TrackBuilderType
): FinancingInsurancesContractingLPIStep3DisclaimerReturnTrackingHandlersType => ({
  handlePrivacyPolicyLinkClickTracking: () => track(privacyPolicyLinkClickTrackingHandler()),
});
