// Types
import { AddressModalHandlersReturnType } from './types/address-modal-return.handlers.type';
import { AddressModalHandlersType } from './types/address-modal.handlers.type';
import { ResetHandlerType } from './types/reset-handler.type';
import { SaveHandlerType } from './types/save-handler.type';
// Utils
import { formatAddressFormValues, trimValues } from '../utils/address-modal.utils';
import { ChangeCountryHandlerType } from './types/change-country-handler.type';
import { getCountryConfigurationResolver } from '@/resolvers/country/country.resolvers';

const resetHandler =
  ({ callback, country, defaultValues, reset }: ResetHandlerType): VoidFunction =>
  (): void => {
    reset(formatAddressFormValues(defaultValues, country));
    callback();
  };

const saveHandler = ({ formValues, onSave }: SaveHandlerType): void =>
  onSave(trimValues(formValues));

const changeCountryHandler = async ({
  country,
  setAddressValidators,
  setIsLoading,
  setCountry,
  addressValidators,
  onError,
}: ChangeCountryHandlerType): Promise<void> => {
  try {
    setIsLoading(true);

    if (!country) {
      throw new Error();
    }

    if (!addressValidators[country]) {
      const [response, error] = await getCountryConfigurationResolver(country);

      if (error || !response?.addressDetailsValidators) {
        throw new Error();
      }

      setAddressValidators({
        ...addressValidators,
        [country]: response.addressDetailsValidators,
      });
    }

    setCountry(country);
  } catch (_) {
    onError();
  } finally {
    setIsLoading(false);
  }
};

export const AddressModalHandlers = ({
  country,
  defaultValues,
  onSave,
  reset,
  ...rest
}: AddressModalHandlersType): AddressModalHandlersReturnType => ({
  handleReset: callback => resetHandler({ callback, country, defaultValues, reset }),
  handleSave: formValues => saveHandler({ formValues, onSave }),
  handleChangeCountry: country =>
    changeCountryHandler({
      country,
      ...rest,
    }),
});
