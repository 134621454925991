// Vendors
import { useCallback, useEffect } from 'react';
import { DeviceTypeHandlersType } from './types/device-type.handlers.type';
import { isMobileDevice, isTabletDevice } from '@openbank/cf-ui-framework';

export const useHandleResize = (
  setIsMobile: DeviceTypeHandlersType,
  setIsTablet: DeviceTypeHandlersType
): void => {
  const handleResize = useCallback(() => {
    setIsMobile(isMobileDevice());
    setIsTablet(isTabletDevice());
  }, [setIsMobile, setIsTablet]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);
};
