export enum InterstitialTagTypesEnum {
  TAG_TYPE_NORMAL_INFORMATIVE = 'NORMAL_INFORMATIVE',
  TAG_TYPE_NORMAL_ACTION = 'NORMAL_ACTION',
  TAG_TYPE_NORMAL_SUCCESS = 'NORMAL_SUCCESS',
  TAG_TYPE_NORMAL_IN_PROGRESS = 'NORMAL_IN_PROGRESS',
  TAG_TYPE_NORMAL_PENDING = 'NORMAL_PENDING',
  TAG_TYPE_NORMAL_WARNING = 'NORMAL_WARNING',
  TAG_TYPE_NORMAL_DISABLED = 'NORMAL_DISABLED',
  TAG_TYPE_EMPHASIS_INFORMATIVE = 'EMPHASIS_INFORMATIVE',
  TAG_TYPE_EMPHASIS_ACTION = 'EMPHASIS_ACTION',
  TAG_TYPE_EMPHASIS_SUCCESS = 'EMPHASIS_SUCCESS',
  TAG_TYPE_EMPHASIS_IN_PROGRESS = 'EMPHASIS_IN_PROGRESS',
  TAG_TYPE_EMPHASIS_PENDING = 'EMPHASIS_PENDING',
  TAG_TYPE_EMPHASIS_WARNING = 'EMPHASIS_WARNING',
  TAG_TYPE_EMPHASIS_DISABLED = 'EMPHASIS_DISABLED',
  TAG_TYPE_OTHER_NEW = 'OTHER_NEW',
  TAG_TYPE_OTHER_IDENTIFICATION = 'OTHER_IDENTIFICATION',
  TAG_TYPE_OTHER_DISCOUNT = 'OTHER_DISCOUNT',
  TAG_TYPE_OTHER_ADDITIONAL_INFO = 'OTHER_ADDITIONAL_INFO',
}
