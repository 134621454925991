// Enumerations
import { AccessKeyChangeSecondStepErrorIdEnumeration } from '../components/second-step/enumerations/security-keys-access-key-change-second-step.enumeration';
// Constants
import { VALIDATION_ID } from '../constants/security-keys-access-key-change.constants';
import {
  ACCESS_KEY_EXPIRED_QUERY_PARAM,
  CONSTRAINTS_LABELS,
  ONLY_4_NUMBERS,
} from './constants/security-keys-access-key-change-constraints.utils.constants';
import { SecurityKeysAccessKeyChangeConstraintsPasswordDontMatchPropsRuleType } from './types/security-keys-access-key-change-password-dont-match-props.constraints.util.type';
// Types
import { SecurityKeysAccessKeyChangeConstraintsUtilReturnType } from './types/security-keys-access-key-change-return.constraints.util.type';
import { FormatMessageType } from 'types/format-message.type';

const hasFailedValidation = (
  validationMap: AccessKeyChangeSecondStepErrorIdEnumeration[] | null,
  validationId: AccessKeyChangeSecondStepErrorIdEnumeration
): boolean => (validationMap ? validationMap.includes(validationId) : false);

const getAllValidationIds = () => {
  return Object.values(VALIDATION_ID) as AccessKeyChangeSecondStepErrorIdEnumeration[];
};

const showSidebarAccessKey = (isHybrid: boolean, isCC: boolean): boolean => {
  return isCC || !isHybrid;
};

const checkHasFourNumbersOnly = (value: string): boolean => Boolean(value.match(ONLY_4_NUMBERS));

const newAccessKeyAndRepeatedDontMatch = ({
  newAccessKey,
  repeatNewAccessKey,
}: SecurityKeysAccessKeyChangeConstraintsPasswordDontMatchPropsRuleType): boolean =>
  Boolean(newAccessKey && repeatNewAccessKey && newAccessKey !== repeatNewAccessKey);

const notInputError = (inputError: AccessKeyChangeSecondStepErrorIdEnumeration[] | null): boolean =>
  Boolean(inputError);

const getAccessKeyConstraints = (
  failedValidationStatus: AccessKeyChangeSecondStepErrorIdEnumeration[] | null,
  formatMessage: FormatMessageType
): SecurityKeysAccessKeyChangeConstraintsUtilReturnType[] =>
  getAllValidationIds().map(validationId => ({
    label: formatMessage({ id: CONSTRAINTS_LABELS[validationId] || '' }),
    check: () => !hasFailedValidation(failedValidationStatus, validationId),
  }));

const isAccessKeyExpiredHybrid = (searchParams: URLSearchParams): boolean =>
  searchParams.get(ACCESS_KEY_EXPIRED_QUERY_PARAM) === 'true';

export {
  isAccessKeyExpiredHybrid,
  showSidebarAccessKey,
  checkHasFourNumbersOnly,
  newAccessKeyAndRepeatedDontMatch,
  notInputError,
  getAccessKeyConstraints,
};
