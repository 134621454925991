// Vendors
import { useEffect, useState } from 'react';
import useCbIntl from 'hooks/useCbIntl';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
// Events handlers
import { TravelPlusLinksHandlers } from '../handlers/travel-plus-links.handlers';
import { TravelPlusLinksTrackingHandlers } from '../handlers/travel-plus-links.tracking.handlers';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { TravelPlusLinksHookType } from './types/travel-plus-links.hook.type';
import { TravelPlusLinksType } from './types/travel-plus-links.type';
// Utilities
import { getCurrentTravelPlusEnumerateValue } from './utils/travel-plus-links.hook.utils';


const useTravelPlusMessageHook = (): TravelPlusLinksHookType => {
  const { currentCard, localization, profile } = AppContextConsumerHook(),
    { countryOfResidence } = profile?.userDetails || {},
    { activated, expirationDate } = currentCard?.travelPlus || {},
    configuration = localization?.config || {};
  const [travelPlusLinks, setTravelPlusLinks] = useState<TravelPlusLinksType | null>(null);
  const { formatMessage } = useCbIntl();

  const { handleGetTravelPlusLinks, handleOpenTravelPlusModalLinkClick } = TravelPlusLinksHandlers({
    ...AppTrackingHook(TravelPlusLinksTrackingHandlers),
    configuration,
    countryOfResidence,
    formatMessage,
    setTravelPlusLinks,
    travelPlusLinks
  });

  useEffect(() => {
    handleGetTravelPlusLinks();
  }, []);

  return {
    handleOpenTravelPlusModalLinkClick,
    status: getCurrentTravelPlusEnumerateValue({ activated, expirationDate }),
  };
};

export { useTravelPlusMessageHook };
