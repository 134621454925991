// Vendors
import React from 'react';
// Components
import WizardStepFooterComponent from 'components/wizard-step-footer/wizard-step-footer.component';
// Constants
import { FIRST_STEP_TARGETS } from 'constants/wizard/wizard.constants';
// Hooks
import { ChangeIbanContextConsumerHook } from 'containers/iban-billings/views/change-iban/contexts/change-iban.context';
// Utils
import { isChangeIbanNextButtonDisabled } from './utils/change-iban-step1-actions.utils';

const ChangeIbanStep1ActionsComponent = (): React.ReactElement => {
  const { checkboxValue, ibanValue } = ChangeIbanContextConsumerHook();

  return (
    <WizardStepFooterComponent
      shouldShowModal={Boolean(ibanValue || checkboxValue)}
      rightButtonConfiguration={{
        disabled: isChangeIbanNextButtonDisabled({
          checkboxValue,
          ibanValue,
        }),
      }}
      targets={FIRST_STEP_TARGETS}
    />
  );
};

export { ChangeIbanStep1ActionsComponent };
