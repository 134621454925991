// Vendors
import { mobileMediaQuery } from '@openbank/cf-ui-framework';
import styled from 'styled-components';

const ModalInterstitialCommercialStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingM};
`;
ModalInterstitialCommercialStyled.displayName = 'ModalInterstitialCommercialStyled';

const ModalInterstitialCommercialHeaderStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingXxs};
  padding-bottom: ${({ theme }) => theme.spaces.spacingM};
`;
ModalInterstitialCommercialHeaderStyled.displayName = 'ModalInterstitialCommercialHeaderStyled';

const ModalInterstitialCommercialContentStyled = styled.div<{ position }>`
  display: flex;
  flex-direction: ${({ position }) => (position === 'TOP' ? 'row' : 'column')};
  gap: ${({ theme }) => theme.spaces.spacingXxs};

  ${mobileMediaQuery} {
    height: 100vh;
  }
`;
ModalInterstitialCommercialContentStyled.displayName = 'ModalInterstitialCommercialContentStyled';

const ModalInterstitialCommercialItemStyled = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spaces.spacingXxs};
`;
ModalInterstitialCommercialItemStyled.displayName = 'ModalInterstitialCommercialItemStyled';

const ModalInterstitialCommercialItemDescriptionStyled = styled.div<{ position }>`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingXxs};
`;
ModalInterstitialCommercialItemDescriptionStyled.displayName =
  'ModalInterstitialCommercialItemDescriptionStyled';

const ModalInterstitialCommercialIcon = styled.img.attrs(() => ({
  alt: 'default_image',
}))`
  width: 20px;
  height: 20px;
`;
ModalInterstitialCommercialIcon.displayName = 'ModalInterstitialCommercialIcon';

export {
  ModalInterstitialCommercialStyled,
  ModalInterstitialCommercialContentStyled,
  ModalInterstitialCommercialHeaderStyled,
  ModalInterstitialCommercialIcon,
  ModalInterstitialCommercialItemStyled,
};
