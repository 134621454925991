// Resolvers
import {
  webIdExtendTokenResolver,
  webIdSignatureResolver,
} from '../resolvers/step-6-signature.resolvers';
// Types
import { ContractSignatureHandlersReturnType } from './types/step-6-credit-limit-increase-return.handler.type';
import { WebIdSignatureHandlerPropsType } from './types/webId-signature-handler-props.type';
// Utils
import { isIosWebView } from '@gruposantander/web-ui-framework';

const webIdSignatureHandler = async ({ caseId, isSuccess }: WebIdSignatureHandlerPropsType) => {
  await webIdSignatureResolver({ caseId, isSuccess });
  // TODO: ask if we need control the errors
};

const onClickSignWithWebIDHandler = async ({
  handleOpenWebViewBrowser,
  isHybrid,
  webIdUrl,
}: any): Promise<void> => {
  if (webIdUrl) {
    await webIdExtendTokenResolver();
    if (isHybrid) {
      if (isIosWebView()) {
        handleOpenWebViewBrowser && handleOpenWebViewBrowser(webIdUrl);
      } else {
        window.location.href = webIdUrl;
      }
    } else {
      window.location.replace(webIdUrl);
    }
  }
};

const ContractSignatureHandlers = (props: any): ContractSignatureHandlersReturnType => ({
  handleOnClickSignWithWebID: (webIdUrl?: string) =>
    onClickSignWithWebIDHandler({ ...props, webIdUrl }),
  handleWebidSignature: ({ caseId, isSuccess }: WebIdSignatureHandlerPropsType) =>
    webIdSignatureHandler({ caseId, isSuccess }),
});

export { ContractSignatureHandlers };
