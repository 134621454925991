// Constants
import { PLACE_PREDICTIONS_REQUEST_PROPS } from './constants/use-address-predictions.handler.constants';
// Types
import {
  GetPredictionHandlerPropsType,
  GetPredictionHandlerReturnType,
} from './types/get-predictions.handler.type';
// Utils
import { filterPredictionsWithStreet } from './utils/use-address-predictions.handlers.utils';

export const getPredictionsHandler = async ({
  autocompleteService,
  country,
  minTriggerLength,
  query,
  setIsLoading,
  setPredictions,
}: GetPredictionHandlerPropsType): GetPredictionHandlerReturnType => {
  try {
    if (query.length < minTriggerLength) throw new Error();

    setIsLoading(true);

    const response = await autocompleteService.current.getPlacePredictions({
      componentRestrictions: { country },
      input: query,
      ...PLACE_PREDICTIONS_REQUEST_PROPS,
    });

    setPredictions(
      filterPredictionsWithStreet(response.predictions).map(prediction => prediction.description)
    );
  } catch (error) {
    setPredictions([]);
  } finally {
    setIsLoading(false);
  }
};
