// Constants
import { DISPUTE_PARAMS } from 'containers/transactions/views/report/components/fraudulent-movement/components/confirmation/constants/transactions-report-fraudulent-movement-confirmation.constants';
import {
  FILES_INITIAL_STATE,
  NOT_RECOGNIZED_DEFAULT_SELECTED_OPTIONS,
} from 'containers/transactions/views/report/components/fraudulent-movement/contexts/constants/transactions-report-fraudulent-movement.context.constants';
// Enumerations
import { NotRecognizeChargeMotiveFraudEnum } from 'containers/transactions/views/report/enumerations/not-recognize-charge-motive-fraud.enumeration';
// Resolvers
import { fetchReportProblemResolver } from 'containers/transactions/views/report/resolvers/transactions-report.resolvers';
// Types
import { AddressType } from 'types/address.type';
import { TransactionsReportFraudulentMovementConfirmationActionsHandlersType } from './types/transactions-report-fraudulent-movement-confirmation-actions.handlers.type';
import { TransactionsReportFraudulentMovementConfirmationActionsHandlersReturnType } from './types/transactions-report-fraudulent-movement-confirmation-actions-return.handlers.type';
import { ReportFraudulentDataType } from './types/transaction-report-fraudulent-data.type';
import { ReportFraudulentHandleResponsePropsType } from './types/transaction-report-fraudulent-handle-response.type';
// Utils
import { getDocumentIds } from 'containers/transactions/views/report/utils/transactions-report.utils';
import { getReasonType } from './utils/transactions-report-fraudulent-movement-confirmation-actions-handlers.utils';
import { isWrongOTPError } from 'utils/challenges/challenge.utils';
import { isStatusNotBlockedOrCanceled } from 'containers/transactions/views/report/components/fraudulent-movement/components/confirmation/utils/transactions-report-fraudulent-movement-confirmation.utils';
// Utils
import { formatAddressByCountry } from 'utils/addresses/addresses.utils';

const buildData = ({
  addresses,
  blockReason,
  files,
  selectedOptions,
  status,
}: ReportFraudulentDataType) => {
  const {
    notRecognizeChargeMotiveFraud,
    notRecognizeChargeHaveYourCard,
    notRecognizeChargeClickLink,
    extraInformation,
  } = selectedOptions;
  const claimReasonType = getReasonType(notRecognizeChargeMotiveFraud);
  return {
    ...DISPUTE_PARAMS,
    claimReasonType: claimReasonType,
    notAckChargeAndNotAuth: {
      notRecognizeChargeMotiveFraud:
        notRecognizeChargeMotiveFraud ?? NotRecognizeChargeMotiveFraudEnum.CARD_NOT_RECEIVED_FRAUD,
      notRecognizeChargeHaveYourCard: notRecognizeChargeHaveYourCard ?? false,
      notRecognizeChargeClickLink: notRecognizeChargeClickLink ?? false,
      notRecognizeChargeOtherInfo: extraInformation,
    },
    blockCard: isStatusNotBlockedOrCanceled(status),
    documents: getDocumentIds(files),
    ...(isStatusNotBlockedOrCanceled(status)
      ? {
          blockCardInfo: {
            reasonCode: blockReason,
            address: formatAddressByCountry(
              addresses[1]?.address ?? (addresses[0].address as AddressType)
            ),
          },
        }
      : {}),
  };
};

const handleResponse = ({
  error,
  response,
  setCard,
  setCaseId,
  setChallengeError,
  setFiles,
  setResult,
  setSelectedOptions,
}: ReportFraudulentHandleResponsePropsType) => {
  if (error || !response) {
    if (isWrongOTPError(error)) {
      setChallengeError(true);
    } else {
      setSelectedOptions(NOT_RECOGNIZED_DEFAULT_SELECTED_OPTIONS);
      setFiles(FILES_INITIAL_STATE);
      setResult(false);
    }
  } else {
    setResult(true);
    setCard(true);
    setCaseId(response?.caseId);
  }
};

const confirmButtonClickHandler = async ({
  addresses,
  blockReason,
  cardId,
  challenge,
  files,
  selectedOptions,
  setCard,
  setCaseId,
  setChallengeError,
  setError,
  setFiles,
  setResult,
  setSelectedOptions,
  status,
  transactionId,
}: TransactionsReportFraudulentMovementConfirmationActionsHandlersType): Promise<void> => {
  try {
    if (!cardId || !transactionId) throw new Error();
    const data = buildData({ addresses, blockReason, files, selectedOptions, status });
    const [response, error] = await fetchReportProblemResolver({
      cardId,
      transactionId,
      data,
      progressId: challenge?.progressId ?? '',
    });
    handleResponse({
      error,
      response,
      setCard,
      setChallengeError,
      setResult,
      setCaseId,
      setFiles,
      setSelectedOptions,
    });
  } catch (error) {
    setError(true);
  }
};

export const TransactionsReportFraudulentMovementConfirmationActionsHandlers = (
  props: TransactionsReportFraudulentMovementConfirmationActionsHandlersType
): TransactionsReportFraudulentMovementConfirmationActionsHandlersReturnType => ({
  handleConfirmButtonClick: () => confirmButtonClickHandler(props),
});
