// Handlers
import { FinancingInsurancesContractingLPIStep3AdditionalHandlers } from '../handlers/financing-insurances-contracting-lpi-step3-additional.handlers';
import { FinancingInsurancesContractingLPIStep3AdditionalTrackingHandlers } from '../handlers/financing-insurances-contracting-lpi-step3-additional.handlers.tracking';
// Hooks
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { FinancingInsurancesContractingLPIStep3AdditionalReturnHandlersType } from '../handlers/types/financing-insurances-contracting-lpi-step3-additional-return.handlers.type';

export const useFinancingInsurancesContractingLPIStep3Additional =
  (): FinancingInsurancesContractingLPIStep3AdditionalReturnHandlersType =>
    FinancingInsurancesContractingLPIStep3AdditionalHandlers({
      ...AppTrackingHook(FinancingInsurancesContractingLPIStep3AdditionalTrackingHandlers),
    });
