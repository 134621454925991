// Vendors
import { isMobileDevice, isTabletDevice } from '@openbank/cf-ui-framework';
// Enumerations
import { IntertitialImagesChannelsEnum } from 'enumerations/interstitials/intertitial-images-channels.enum';
// Types
import { InterstitialCommercialDataPropositionContentImage } from 'types/interstitials/commercial/proposition-content-image.type';

export const getImageByChannel = (
  images: InterstitialCommercialDataPropositionContentImage[]
): InterstitialCommercialDataPropositionContentImage | undefined => {
  if (isMobileDevice()) {
    return images.find(({ channel }) => channel === IntertitialImagesChannelsEnum.MOBILE);
  }

  if (isTabletDevice()) {
    return images.find(({ channel }) => channel === IntertitialImagesChannelsEnum.TABLET);
  }

  return images.find(({ channel }) => channel === IntertitialImagesChannelsEnum.WEB);
};
