// Types
import { InterstitialCommercialTrackingPropsHandlersType } from './types/modal-interstitial-commercial-props.handlers.type';
import { InterstitialCommercialHandlersType } from './types/modal-interstitial-commercial.handlers.type';

const onCloseButtonClickHandler = ({
  handleInterstitialCommercialCloseTracking,
  onClose,
}: Pick<
  InterstitialCommercialTrackingPropsHandlersType,
  'handleInterstitialCommercialCloseTracking' | 'onClose'
>) => {
  handleInterstitialCommercialCloseTracking();
  onClose();
};

const onClosePromotionLinkClickHandler = ({
  handleInterstitialCommercialClosePromotionTracking,
  onClose,
}: Pick<
  InterstitialCommercialTrackingPropsHandlersType,
  'handleInterstitialCommercialClosePromotionTracking' | 'onClose'
>) => {
  handleInterstitialCommercialClosePromotionTracking();
  onClose();
};

const onKnownMoreButtonClickHandler = ({
  buttonUrl,
  handlInterstitialCommercialKnowMoreTracking,
}: Pick<
  InterstitialCommercialTrackingPropsHandlersType,
  'buttonUrl' | 'handlInterstitialCommercialKnowMoreTracking'
>) => {
  handlInterstitialCommercialKnowMoreTracking();
  window.location.replace(buttonUrl);
};

export const InterstitialCommercialHandlers = ({
  buttonUrl,
  handlInterstitialCommercialKnowMoreTracking,
  handleInterstitialCommercialCloseTracking,
  handleInterstitialCommercialClosePromotionTracking,
  onClose,
}: InterstitialCommercialTrackingPropsHandlersType): InterstitialCommercialHandlersType => ({
  handleCloseButtonClick: () =>
    onCloseButtonClickHandler({ handleInterstitialCommercialCloseTracking, onClose }),
  handleClosePromotionLinkClick: () =>
    onClosePromotionLinkClickHandler({
      handleInterstitialCommercialClosePromotionTracking,
      onClose,
    }),
  handleKnownMoreButtonClick: () =>
    onKnownMoreButtonClickHandler({ buttonUrl, handlInterstitialCommercialKnowMoreTracking }),
});
