const ON_LOAD_TRACKING_PAYLOAD = {
    pageName: '/interstitialCommercial',
    processType: 'modal',
    processStep: 'modal/interstitialCommercial',
    processDetail: 'open',
    sectionFamily: 'banking',
    sectionCategory: 'services',
    sectionName: 'promotions',
    interactionType: 'page',
    serviceType: 'serviceInformation',
  },
  ON_KNOW_MORE_TRACKING_PAYLOAD = {
    action: 'click',
    component: 'modal/interstitialCommercial',
    format: 'button',
    element: 'knowMore',
    tealium_event: 'promo_click',
    eventAction: 'interstitialCommercial',
    eventLabel: 'knowMore',
    pageName: '/interstitialCommercial',
  },
  ON_CLOSE_TRACKING_PAYLOAD = {
    action: 'click',
    component: 'modal/interstitialCommercial',
    format: 'button',
    element: 'cross',
    tealium_event: 'promo_click',
    eventAction: 'interstitialCommercial',
    eventLabel: 'close',
    pageName: '/interstitialCommercial',
  },
  ON_CLOSE_PROMOTION_TRACKING_PAYLOAD = {
    action: 'click',
    format: 'link',
    component: 'modal/interstitialCommercial',
    element: 'closePromotion',
    tealium_event: 'promo_click',
    eventCategory: '/interstitialCommercial',
    eventAction: 'interstitialCommercial',
    eventLabel: 'closePromotion',
  };

export {
  ON_CLOSE_TRACKING_PAYLOAD,
  ON_CLOSE_PROMOTION_TRACKING_PAYLOAD,
  ON_KNOW_MORE_TRACKING_PAYLOAD,
  ON_LOAD_TRACKING_PAYLOAD,
};
