// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
// Handlers
import { FinancingInsurancesContractingLPIStep3DisclaimerHandlers } from '../handlers/financing-insurances-contracting-lpi-step3-disclaimer.handlers';
import { FinancingInsurancesContractingLPIStep3DisclaimerTrackingHandlers } from '../handlers/financing-insurances-contracting-lpi-step3-disclaimer.handlers.tracking';
// Hooks
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { FinancingInsurancesContractingLPIStep3DisclaimerReturnHandlersType } from '../handlers/types/financing-insurances-contracting-lpi-step3-disclaimer-return.handlers.type';

export const useFinancingInsurancesContractingLPIStep3Disclaimer =
  (): FinancingInsurancesContractingLPIStep3DisclaimerReturnHandlersType => {
    const {
      localization: {
        config: { cfg_public_url_privacy_policy_web },
      },
    } = AppContextConsumerHook();

    return FinancingInsurancesContractingLPIStep3DisclaimerHandlers({
      ...AppTrackingHook(FinancingInsurancesContractingLPIStep3DisclaimerTrackingHandlers),
      cfg_public_url_privacy_policy_web,
    });
  };
