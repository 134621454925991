// Vendors
import { paragraphConstants } from '@openbank/cb-ui-commons';

const EXPANDABLE_ROW_PROPS = {
  testing: { assetId: 'financing-insurances-contracting-lpi-additional-expandable-row-asset' },
  type: 'accordion',
};

const EXPANDABLE_ROW_TITLE_PROPS = {
  bold: true,
  size: paragraphConstants.SIZES.M,
};

export { EXPANDABLE_ROW_TITLE_PROPS, EXPANDABLE_ROW_PROPS };
