// Constants
import { assetConstants } from '@openbank/cb-ui-commons';

export const APP_NO_USER_DATA_LOGO_PROPS = {
  id: 'amazon-zinia-logo',
  sizes: {
    width: 272,
    height: 40,
  },
  type: assetConstants.TYPES.LOGO,
  testId: 'app-no-user-data-logo',
};
