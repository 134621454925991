// Vendors
import React from 'react';
// Components
import { FinancingInsurancesContractingLPIAdditionalComponent } from '../additional/financing-insurances-contracting-lpi-additional.component';
import { FinancingInsurancesHiringLegalComponent } from 'containers/financing/views/ppi/components/hiring-legal/financing-insurances-hiring-legal.component';
import { FinancingInsurancesContractingLPIBenefitsComponent } from '../benefits/financing-insurances-contracting-lpi-benefits.component';
import { ParagraphComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import WizardStepFooterComponent from 'components/wizard-step-footer/wizard-step-footer.component';
// Constants
import { FIRST_STEP_TARGETS } from 'constants/wizard/wizard.constants';
import { FINANCING_CONTRACTING_LPI_COMMON_CANCEL_PROPS } from '../../constants/financing-insurances-contracting-lpi.constants';
// Styles
import { LayoutColumnFlexComponentStyled } from 'styles/app.styled';
// Translations
import {
  CANCEL_LINK,
  DESCRIPTION,
  NEXT_BUTTON,
} from './translations/financing-insurances-contracting-lpi-step1.translations';

const FinancingInsurancesContractingLPIStep1Component = (): React.ReactElement => (
  <LayoutColumnFlexComponentStyled>
    <FinancingInsurancesContractingLPIBenefitsComponent />
    <FinancingInsurancesContractingLPIAdditionalComponent />
    <ParagraphComponent bold>
      <FormattedMessageComponent id={DESCRIPTION} />
    </ParagraphComponent>
    <FinancingInsurancesHiringLegalComponent />
    <WizardStepFooterComponent
      {...FINANCING_CONTRACTING_LPI_COMMON_CANCEL_PROPS}
      shouldShowModal={false}
      rightButtonConfiguration={{
        assetConfiguration: {},
        children: <FormattedMessageComponent id={NEXT_BUTTON} />,
      }}
      leftLinkConfiguration={{
        children: <FormattedMessageComponent id={CANCEL_LINK} />,
      }}
      targets={FIRST_STEP_TARGETS}
    />
  </LayoutColumnFlexComponentStyled>
);

export { FinancingInsurancesContractingLPIStep1Component };
