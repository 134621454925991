// Vendors
import React from 'react';
import { decodeAsciiCodes } from '@openbank/cf-ui-framework';
// Components
import {
  ModalInterstitialComponent,
  ParagraphComponent,
  TitleComponent,
} from '@openbank/cb-ui-commons';
// Configuration
import { modalCommercialConfiguration } from './configuration/modal-interstitial-commercial.configuration';
// Hooks
import { useModalInterstitialCommercialHook } from './hooks/modal-interstitial-commercial.hook';
// Styled
import {
  ModalInterstitialCommercialContentStyled,
  ModalInterstitialCommercialHeaderStyled,
  ModalInterstitialCommercialIcon,
  ModalInterstitialCommercialItemStyled,
  ModalInterstitialCommercialStyled,
} from './modal-interstitial-commercial.component.styled';
// Types
import { InterstitialCommercialComponentPropsType } from './types/modal-interstitial-commercial-props.component.type';

const InterstitialCommercialComponent = (
  props: InterstitialCommercialComponentPropsType
): React.ReactElement | null => {
  const { modalConfiguration } = useModalInterstitialCommercialHook(props);
  const { title, description, layout, infoElements } = props.content;

  return (
    <ModalInterstitialComponent {...modalConfiguration}>
      <ModalInterstitialCommercialStyled>
        <ModalInterstitialCommercialHeaderStyled>
          <TitleComponent>{title}</TitleComponent>
          <ParagraphComponent>{decodeAsciiCodes(description)}</ParagraphComponent>
        </ModalInterstitialCommercialHeaderStyled>
        <ModalInterstitialCommercialContentStyled position={layout.imagePosition}>
          {infoElements.map((element, index) => (
            <ModalInterstitialCommercialItemStyled key={`${element.title}-${index}`}>
              <ModalInterstitialCommercialIcon src={element.iconUrl} />
              <ParagraphComponent bold>{element.title}</ParagraphComponent>
              <ParagraphComponent>{element.text}</ParagraphComponent>
            </ModalInterstitialCommercialItemStyled>
          ))}
        </ModalInterstitialCommercialContentStyled>
      </ModalInterstitialCommercialStyled>
    </ModalInterstitialComponent>
  );
};

export { InterstitialCommercialComponent };
