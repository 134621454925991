const BUTTON_TEST_ID = 'modal-interstitial-commercial-button',
  EMPHASIS = 'EMPHASIS',
  DATE_PLACEHOLDER = '{date}',
  END_LINK = '#/link',
  LINK_TEXT_SEPARATOR = '#',
  LINK_ID_TEXT_SEPARATOR = '=',
  START_LINK = '#link';

export {
  BUTTON_TEST_ID,
  EMPHASIS,
  DATE_PLACEHOLDER,
  END_LINK,
  LINK_TEXT_SEPARATOR,
  LINK_ID_TEXT_SEPARATOR,
  START_LINK,
};
