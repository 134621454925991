// Vendors
import { TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
// Constants
import { NEXT_BUTTON_CLICK_DOWNLOAD_ERROR_TRACKING_PROPS } from './constants/financing-insurances-contracting-lpi-step4.handlers.tracking.constants';
// Types
import { FinancingInsurancesContractingLPIStep4ReturnTrackingHandlersType } from './types/financing-insurances-contracting-lpi-step4-return.handlers.tracking.type';
import { TrackBuilderType } from 'types/tracking/track-builder.type';

const nextButtonClickDownloadErrorTrackingHandler = (): TrackingLinkEventReturnType =>
  NEXT_BUTTON_CLICK_DOWNLOAD_ERROR_TRACKING_PROPS;

export const FinancingInsurancesContractingLPIStep4TrackingHandlers = (
  track: TrackBuilderType
): FinancingInsurancesContractingLPIStep4ReturnTrackingHandlersType => ({
  handleNextButtonClickDownloadErrorTracking: () =>
    track(nextButtonClickDownloadErrorTrackingHandler()),
});
