// Vendors
import { TrackingEventsEnum, TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';

const DOWNLOAD_CONTRACT_TRACKING_PROPS: TrackingLinkEventReturnType = {
  payload: {
    eventAction: 'clickLink',
    eventLabel: 'downloadContractLink',
  },
  type: TrackingEventsEnum.LINK,
};

const DOWNLOAD_CONTRACT_TOAST_EVENT_LABEL = 'view';

const CLOSE_DOWNLOAD_CONTRACT_TOAST_EVENT_LABEL = 'close';

const DOWNLOAD_CONTRACT_SUCCESS_EVENT_ACTION = 'successSnackbarContractDownloaded';

const DOWNLOAD_CONTRACT_ERROR_EVENT_ACTION = 'errorSnackbarDowloandingTheDocument';

export {
  CLOSE_DOWNLOAD_CONTRACT_TOAST_EVENT_LABEL,
  DOWNLOAD_CONTRACT_ERROR_EVENT_ACTION,
  DOWNLOAD_CONTRACT_SUCCESS_EVENT_ACTION,
  DOWNLOAD_CONTRACT_TOAST_EVENT_LABEL,
  DOWNLOAD_CONTRACT_TRACKING_PROPS,
};
