// Vendors
import React from 'react';
// Components
import { AssetComponent } from '@openbank/cb-ui-commons';
import { ExpiredAccessKeyModalComponent } from '../expired-access-key-modal/expired-access-key-modal.component';
import CancelModalComponent from 'components/cancel-modal/cancel-modal.component';
// Constants
import { ORCHESTRATOR_LOGOUT_MODAL_PROPS } from 'containers/orchestrator/components/header/constants/orchestrator-header.constants';
import { APP_NO_USER_DATA_LOGO_PROPS } from './constants/header.constants';
// Hooks
import { AppNoUserDataHeaderHook } from './hooks/header.hook';
// Styles
import { AppNoUserDataHeaderContainerStyled } from './header.component.styled';
// Types
import { AppNoUserDataHookReturnType } from '../../hooks/types/app-no-user-data.hook.return.type';
// Utils
import { isCC } from 'utils/app.utils';

const AppNoUserDataHeaderComponent = ({
  isMobileOrTablet,
  showExpiredAccessKeyModal,
  onAcceptExpiredAccessKeyModal,
}: Pick<
  AppNoUserDataHookReturnType,
  'isMobileOrTablet' | 'showExpiredAccessKeyModal' | 'onAcceptExpiredAccessKeyModal'
>): React.ReactElement => {
  const {
    isHybrid,
    showLogOutModal,
    formatMessage,
    handleMakeUserLogoutClick,
    handleMakeUserCancelClickHandler,
    handleMakeUserLogoutButtonClick,
  } = AppNoUserDataHeaderHook();

  const Modals = () => (
    <>
      <ExpiredAccessKeyModalComponent
        {...{
          formatMessage,
          onClose: handleMakeUserLogoutButtonClick,
          onAccept: onAcceptExpiredAccessKeyModal,
          show: !showLogOutModal && showExpiredAccessKeyModal,
        }}
      />
      <CancelModalComponent
        {...ORCHESTRATOR_LOGOUT_MODAL_PROPS}
        onClose={handleMakeUserCancelClickHandler}
        onConfirm={handleMakeUserLogoutClick}
        show={showLogOutModal}
      />
    </>
  );

  if (isMobileOrTablet && !isCC()) {
    return <Modals />;
  }

  return (
    <AppNoUserDataHeaderContainerStyled $isHybrid={isHybrid}>
      <Modals />
      <AssetComponent {...APP_NO_USER_DATA_LOGO_PROPS} />
    </AppNoUserDataHeaderContainerStyled>
  );
};

export { AppNoUserDataHeaderComponent };
