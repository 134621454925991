// Vendors
import { TrackingEventsEnum, TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
// Constants
import {
  CLOSE_DOWNLOAD_CONTRACT_TOAST_EVENT_LABEL,
  DOWNLOAD_CONTRACT_TOAST_EVENT_LABEL,
  DOWNLOAD_CONTRACT_TRACKING_PROPS,
} from './constants/financing-insurances-contracting-lpi-step4-download.handlers.tracking.constants';
// Types
import { FinancingInsurancesContractingLPIStep4DownloadReturnTrackingHandlersType } from './types/financing-insurances-contracting-lpi-step4-download-return.handlers.tracking.type';
import { TrackBuilderType } from 'types/tracking/track-builder.type';

const downloadLinkClickTrackingHandler = (): TrackingLinkEventReturnType =>
  DOWNLOAD_CONTRACT_TRACKING_PROPS;

const downloadToastTrackingHandler = (
  eventAction: string,
  eventLabel: string
): TrackingLinkEventReturnType => ({
  payload: {
    eventAction,
    eventLabel,
  },
  type: TrackingEventsEnum.LINK,
});

export const FinancingInsurancesContractingLPIStep4DownloadTrackingHandlers = (
  track: TrackBuilderType
): FinancingInsurancesContractingLPIStep4DownloadReturnTrackingHandlersType => ({
  handleDownloadLinkClickTracking: () => track(downloadLinkClickTrackingHandler()),
  handleDownloadToastTracking: (eventAction: string) =>
    track(downloadToastTrackingHandler(eventAction, DOWNLOAD_CONTRACT_TOAST_EVENT_LABEL)),
  handleCloseDownloadToastTracking: (eventAction: string) =>
    track(downloadToastTrackingHandler(eventAction, CLOSE_DOWNLOAD_CONTRACT_TOAST_EVENT_LABEL)),
});
