// Constants
import { SHOW_MODAL_DEFAULT_VALUE } from '../constants/wizard-step-footer.constants';
// Contexts
import { MobileConnectorContextConsumerHook } from 'providers/mobile-connector/contexts/mobile-connector.provider.context';
// Hooks
import { WizardStepFooterCommonHook } from './wizard-step-footer.common.hook';
// Types
import { WizardStepFooterHookReturnType } from './types/wizard-step-footer-return.hook.type';
import { WizardStepFooterHookType } from './types/wizard-step-footer.hook.type';

const WizardStepFooterHook = ({
  cancelUrl,
  setShowModal,
  showModal = SHOW_MODAL_DEFAULT_VALUE,
  targets,
}: WizardStepFooterHookType): WizardStepFooterHookReturnType => {
  const { onNavigateHybridFlow } = MobileConnectorContextConsumerHook();

  return {
    ...WizardStepFooterCommonHook({
      setShowModal,
      showModal,
      targets,
    }),
    cancelFlow: () => onNavigateHybridFlow(cancelUrl),
  };
};

export default WizardStepFooterHook;
