// Vendors
import useCbIntl from 'hooks/useCbIntl';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
import { useFinancingPPIContractingContextConsumerHook } from 'containers/financing/views/ppi/components/contracting/cpi/contexts/financing-ppi-contracting.context';
// Event handlers
import { FinancingPpiContractingStep2AddressHandlers } from '../handlers/financing-ppi-contracting-step2-address.handlers';
import { FinancingPpiContractingStep2AddressTrackingHandlers } from '../handlers/financing-ppi-contracting-step2-address.tracking.handlers';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { FinancingPPIContractingStep2AddressHookType } from './types/financing-ppi-contracting-step2-address.hook.type';
// Utilities
import { prettyRawCustomerAddress } from 'utils/addresses/addresses.utils';

const useFinancingPPIContractingStep2AddressHook =
  (): FinancingPPIContractingStep2AddressHookType => {
    const { formatMessage } = useCbIntl();

    const { postalDocumentation, setPostalDocumentation } =
      useFinancingPPIContractingContextConsumerHook();

    const { profile } = AppContextConsumerHook(),
      customerAddress = profile?.addressDetails?.[0] || {};

    return {
      ...FinancingPpiContractingStep2AddressHandlers({
        ...AppTrackingHook(FinancingPpiContractingStep2AddressTrackingHandlers),
        setPostalDocumentation,
      }),
      address: prettyRawCustomerAddress({ address: customerAddress, formatMessage }),
      postalDocumentation,
    };
  };

export { useFinancingPPIContractingStep2AddressHook };
