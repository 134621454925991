// Vendors
import { apiUtilsCb } from '@openbank/cf-ui-architecture-core';
// Constants
import { FINANCING_PPI_TERMS_SERVICE_CONFIGURATION } from 'containers/financing/views/ppi/components/contracting/cpi/components/step2/services/constants/financing-ppi-contracting-step2.service.constants';
// Types
import { FinancingPPIContractingStepApiBuilderType } from '../types/financing-ppi-contracting-step-api-builder.type';

const savePPITermsAndConditionsService = async (
  params: FinancingPPIContractingStepApiBuilderType
): Promise<[Record<string, never>, Response]> =>
  apiUtilsCb.post({ ...FINANCING_PPI_TERMS_SERVICE_CONFIGURATION, params });

export { savePPITermsAndConditionsService };
