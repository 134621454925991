// Constants
import { BANNER_PROPS_LOADING } from '../hooks/constants/banner.component.constants';
// Enumerations
import { ImageChannelEnumeration } from '../services/enumerations/image-channel.enumeration';
// Resolvers
import { propositionAreasLateralBannerResolver } from '../resolvers/banner.resolver';
// Types
import { BannerHandlersPropsType } from './types/banner-props.handler.type';
import { BannerHandlersReturnType } from './types/banner.handler.type';
import { SetAppropiateBannerHandlerType } from './types/set-appropiate-banner-handler.type';
// Utils
import {
  getBannerPropsFromResponse,
  getDefaultBannerProps,
} from './utils/banner.component.handler.utils';

const getBannerHandler = async ({
  formatMessage,
  navigate,
  setBannerProps,
  setBannerResponse,
}: Omit<BannerHandlersPropsType, 'handleClickBannerButtonTracking'>): Promise<void> => {
  setBannerProps(BANNER_PROPS_LOADING);

  const [response, status] = await propositionAreasLateralBannerResolver();

  if (!response || status) {
    setBannerProps(getDefaultBannerProps({ formatMessage, navigate }));
    return;
  }

  setBannerResponse(response);
};

const setAppropiateBannerHandler = ({
  bannerResponse,
  isMobile,
  isTablet,
  formatMessage,
  navigate,
  setBannerProps,
  handleClickBannerButtonTracking,
}: SetAppropiateBannerHandlerType) => {
  if (!bannerResponse) return;

  let channel = ImageChannelEnumeration.WEB;
  if (isMobile) {
    channel = ImageChannelEnumeration.MOBILE;
  } else if (isTablet) {
    channel = ImageChannelEnumeration.TABLET;
  }

  setBannerProps(
    getBannerPropsFromResponse({
      response: bannerResponse,
      channel,
      formatMessage,
      navigate,
      handleClickBannerButtonTracking,
    })
  );
};

export const BannerHandlers = ({
  formatMessage,
  navigate,
  setBannerProps,
  setBannerResponse,
  handleClickBannerButtonTracking,
}: BannerHandlersPropsType): BannerHandlersReturnType => ({
  handleGetBanner: () =>
    getBannerHandler({
      formatMessage,
      navigate,
      setBannerProps,
      setBannerResponse,
    }),
  handleSetAppropiateBanner: ({ bannerResponse, isMobile, isTablet }) =>
    setAppropiateBannerHandler({
      bannerResponse,
      isMobile,
      isTablet,
      formatMessage,
      navigate,
      setBannerProps,
      handleClickBannerButtonTracking,
    }),
});
