// Vendors
import React from 'react';
// Components
import ChallengeWrapperComponent from 'components/challenge-wrapper/challenge-wrapper.component';
// Constants
import { CHALLENGE_PROPS } from './constants/financing-insurances-contracting-lpi-step4-challenge.constants';
import { INSURANCE_TYPE } from 'containers/financing/views/ppi/components/contracting/lpi/constants/financing-insurances-contracting-lpi.constants';
// Hooks
import { useFinancingInsurancesContractingLPIStep4Challenge } from './hooks/financing-insurances-contracting-lpi-step4-challenge.hook';
// Types
import { FinancingInsurancesContractingLPIStep4ChallengeComponentPropsType } from './types/financing-insurances-contracting-lpi-step4-challenge.component.type';

const FinancingInsurancesContractingLPIStep4ChallengeComponent = ({
  setChallenge,
  setChallengeError,
  challengeError,
  postalDocumentation,
}: FinancingInsurancesContractingLPIStep4ChallengeComponentPropsType): React.ReactElement => {
  const { plan, productId, productType, handleChallengeSuccess, handleChallengeRetryButtonClick } =
    useFinancingInsurancesContractingLPIStep4Challenge({
      setChallenge,
      setChallengeError,
    });

  return (
    <ChallengeWrapperComponent
      {...CHALLENGE_PROPS}
      baseServiceBuilder={{
        params: {
          insuranceType: INSURANCE_TYPE,
          planCode: plan,
          productContractId: productId,
          productContractType: productType,
          postalDocumentation,
        },
      }}
      challengeError={challengeError}
      onRetry={handleChallengeRetryButtonClick}
      onError={handleChallengeRetryButtonClick}
      onSuccess={handleChallengeSuccess}
    />
  );
};

export { FinancingInsurancesContractingLPIStep4ChallengeComponent };
