// Vendors
import React from 'react';
// Components
import { InterstitialDocExpiredComponent } from './components/doc-expired/modal-interstitial-doc-expired.component';
import { InterstitialCommercialComponent } from './components/commercial/modal-interstitial-commercial.component';
// Constants
import { NUMBER } from 'constants/number.constants';
// Enumerations
import { InterstitialsTypesEnum } from 'enumerations/interstitials/interstitials-types.enum';
// Hooks
import { useInterstitialHook } from './hooks/modal-intersticial.hook';
// Types
import { InterstitialCommercialData } from 'interfaces/interstitials/commercial/interstitial-commercial-data.interface';
import { InterstitialComponentPropsType } from './types/modal-interstitial.component.type';
import { InterstitialCommercialDataProposition } from 'interfaces/interstitials/commercial/interstitial-commercial-proposition.interface';

const InterstitialComponent = ({
  interstitial,
}: InterstitialComponentPropsType): React.ReactElement | null => {
  const { handleClose, open } = useInterstitialHook(interstitial?.type);

  if (!open) {
    return null;
  }

  if (interstitial.type !== InterstitialsTypesEnum.COMMERCIAL) {
    return <InterstitialDocExpiredComponent onClose={handleClose} />;
  }

  const interstitialCommercialProposition = (interstitial.data as InterstitialCommercialData)
    .commercial.propositions[NUMBER.ZERO];

  return (
    <InterstitialCommercialComponent
      content={interstitialCommercialProposition.content}
      feedback={interstitialCommercialProposition.feedback}
      onClose={handleClose}
    />
  );
};

export { InterstitialComponent };
