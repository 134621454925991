// Vendors
import React from 'react';
// Components
import { TitleHeaderComponent } from '@openbank/cb-ui-commons';
import { FinancingInsurancesContractingLPIStep3ReceiptComponent } from './components/receipt/financing-insurances-contracting-lpi-step3-receipt.component';
import { FinancingInsurancesContractingLPIStep3DisclaimerComponent } from './components/disclaimer/financing-insurances-contracting-lpi-step3-disclaimer.component';
import { FinancingInsurancesContractingLPIStep3AdditionalComponent } from './components/additional/financing-insurances-contracting-lpi-step3-additional.component';
import { FinancingInsurancesContractingLPIStep3DownloadComponent } from './components/download/financing-insurances-contracting-lpi-step3-download.component';
import { FinancingInsurancesContractingLPIDetailConfirmationComponent } from '../detail-confirmation/financing-insurances-contracting-lpi-detail-confirmation.component';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import WizardStepFooterComponent from 'components/wizard-step-footer/wizard-step-footer.component';
// Constants
import { FINANCING_CONTRACTING_LPI_COMMON_CANCEL_PROPS } from '../../constants/financing-insurances-contracting-lpi.constants';
import { RECAP_TITLE_PROPS } from './constants/financing-insurances-contracting-lpi-step3.constants';
import { THIRD_STEP_TARGETS } from 'constants/wizard/wizard.constants';
// Hooks
import { useFinancingInsurancesContractingLPIStep3 } from './hooks/financing-insurances-contracting-lpi-step3.hook';
// Styles
import { LayoutColumnFlexComponentStyled } from 'styles/app.styled';
// Translations
import { RECAP_TITLE } from './translations/financing-insurances-contracting-lpi-step3.translations';

const FinancingInsurancesContractingLPIStep3Component = (): React.ReactElement => {
  const {
    docsReceived,
    docsReceivedError,
    downloadedError,
    handleNextButtonClick,
    handleToggleDownloaded,
    handleToggleReceipt,
  } = useFinancingInsurancesContractingLPIStep3();

  return (
    <LayoutColumnFlexComponentStyled>
      <FinancingInsurancesContractingLPIStep3DownloadComponent
        downloadedError={downloadedError}
        handleToggleDownloaded={handleToggleDownloaded}
      />
      <TitleHeaderComponent {...RECAP_TITLE_PROPS}>
        <FormattedMessageComponent id={RECAP_TITLE} />
      </TitleHeaderComponent>
      <FinancingInsurancesContractingLPIDetailConfirmationComponent />
      <FinancingInsurancesContractingLPIStep3ReceiptComponent
        docsReceived={docsReceived}
        docsReceivedError={docsReceivedError}
        handleToggleReceipt={handleToggleReceipt}
      />
      <FinancingInsurancesContractingLPIStep3DisclaimerComponent />
      <FinancingInsurancesContractingLPIStep3AdditionalComponent />
      <WizardStepFooterComponent
        {...FINANCING_CONTRACTING_LPI_COMMON_CANCEL_PROPS}
        rightButtonConfiguration={{ onClick: handleNextButtonClick }}
        targets={THIRD_STEP_TARGETS}
      />
    </LayoutColumnFlexComponentStyled>
  );
};

export { FinancingInsurancesContractingLPIStep3Component };
