// Enumerations
import { InputNamesEnumeration } from 'components/address-modal/enums/input-names.enumeration';
// Translations
import {
  STAIRCASE,
  DOOR_NUMBER,
  CITY,
} from 'components/address-modal/translations/address-modal-inputs.translations';

const STAIRCASE_PROPS = {
  id: 'address-modal-input-staircase',
  inputProps: {},
  name: InputNamesEnumeration.STAIRCASE,
  translations: STAIRCASE,
};

const DOOR_NUMBER_PROPS = {
  id: 'address-modal-input-door-number',
  inputProps: {},
  name: InputNamesEnumeration.DOOR_NUMBER,
  translations: DOOR_NUMBER,
};

const CITY_PROPS = {
  id: 'address-modal-input-city',
  inputProps: {},
  name: InputNamesEnumeration.CITY,
  translations: CITY,
};

export { CITY_PROPS, STAIRCASE_PROPS, DOOR_NUMBER_PROPS };
