// Vendors
import styled from 'styled-components';
// Utils
import { convertPxsToRems, mobileMediaQuery } from '@openbank/cf-ui-framework';

const AppNoUserDataContainerStyled = styled.main`
  background-color: ${({ theme }) => theme.colors.backgroundTertiaryE};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingM};
  height: 100vh;

  ${mobileMediaQuery} {
    height: auto;
  }
`;
AppNoUserDataContainerStyled.displayName = 'AppNoUserDataContainerStyled';

const AppNoUserDataContentContainerStyled = styled.div<{ isMobileOrTablet: boolean }>`
  display: flex;
  flex-direction: column;
  gap: ${({ isMobileOrTablet, theme }) => (isMobileOrTablet ? 0 : theme.spaces.spacingXxxl)};
  max-width: ${convertPxsToRems({ pixels: 1200, base: 14 })};
  height: 100%;
  padding: 0 ${({ theme }) => theme.spaces.spacingM};
  margin: 0 auto ${({ theme }) => theme.spaces.spacingXxxl};
  width: 100%;

  ${mobileMediaQuery} {
    padding: 0;
  }
`;
AppNoUserDataContentContainerStyled.displayName = 'AppNoUserDataContentContainerStyled';

export { AppNoUserDataContainerStyled, AppNoUserDataContentContainerStyled };
