const DOMAIN = 'cobranded.services.financing.creditLimitIncrease.step2.';

export default {
  TITLE: `${DOMAIN}title`,
  DESCRIPTION: `${DOMAIN}description`,
  EDIT: `${DOMAIN}edit`,
  SAVE_AND_CONTINUE: `${DOMAIN}saveAndContinue`,
  SCHUFA_TEXT: `${DOMAIN}schufaText`,
  KSV_TEXT: `${DOMAIN}ksvText`,
};
