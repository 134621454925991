// Vendors
import { useState } from 'react';
// Enumerations
import { InterstitialsTypesEnum } from 'enumerations/interstitials/interstitials-types.enum';
// Types
import { UseInterstitialHookType } from './types/modal-intersticial.hook.type';

const useInterstitialHook = (
  interstitialType?: InterstitialsTypesEnum
): UseInterstitialHookType => {
  const [open, setOpen] = useState(Boolean(interstitialType));

  return {
    handleClose: () => setOpen(false),
    open,
  };
};

export { useInterstitialHook };
