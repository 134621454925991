// Types
import { CountdownHandlersReturnPropsType } from './types/countdown-return.handlers.type';
import { CountdownHandlersPropsType } from './types/countdown.handlers.type';

const handleCountdown = ({
  fetching,
  countdown,
  setCountdownIsCompleted,
}: CountdownHandlersPropsType): VoidFunction => {
  let timer: NodeJS.Timeout | null = null;

  if (fetching) {
    setCountdownIsCompleted(false);
    timer = setTimeout(() => setCountdownIsCompleted(true), countdown);
  } else if (timer) {
    setCountdownIsCompleted(false);
    clearInterval(timer);
  }

  return () => {
    if (timer) {
      clearInterval(timer);
    }
  };
};

const CountdownHandlers = ({
  fetching,
  countdown,
  setCountdownIsCompleted,
}: CountdownHandlersPropsType): CountdownHandlersReturnPropsType => ({
  CountdownHandler: () => handleCountdown({ fetching, countdown, setCountdownIsCompleted }),
});
export { CountdownHandlers };
