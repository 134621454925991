// Enumerations
import { UploadDocumentIdsEnumeration } from '../../components/step4/upload-documentation/components/upload-document/enums/upload-document-ids.enumeration';
import { DocumentToUploadTypePropEnumeration } from 'containers/financing/views/credit-limit-increase/handlers/enums/document-to-upload-type-prop.enumeration';
import { MonthlyPaysheetEnumerations } from '../enums/monthly-paysheet.enumeration';
// Translations
import { uploadDocumentTranlations } from 'translations/upload-document.translations';
// Types
import { ValidateFileType } from './types/validate-file.utils.type';

const isFileBiggerThan10MB = (file: File): boolean => file.size > 10 * 1024 * 1024;

const isFileWeirdFormat = (file: File): boolean => {
  const fileType = file.type;

  if (
    fileType === 'application/pdf' ||
    fileType === 'image/jpeg' ||
    fileType === 'image/bmp' ||
    fileType === 'image/png'
  ) {
    return false;
  }

  return true;
};

// eslint-disable-next-line complexity
export const getUploadDocumentTypeProp = (
  id: UploadDocumentIdsEnumeration
): DocumentToUploadTypePropEnumeration => {
  switch (id) {
    case UploadDocumentIdsEnumeration.LAST_INCOME_TAX:
      return DocumentToUploadTypePropEnumeration.CO028;
    case UploadDocumentIdsEnumeration.ANNUAL_PENSION:
      return DocumentToUploadTypePropEnumeration.CO027;
    case UploadDocumentIdsEnumeration.LAST_SALARY:
    case UploadDocumentIdsEnumeration.PREVIOUS_SALARY:
    case UploadDocumentIdsEnumeration.BEFORE_PREVIOUS_SALARY:
      return DocumentToUploadTypePropEnumeration.CO057;
    case UploadDocumentIdsEnumeration.LAST_BWA:
      return DocumentToUploadTypePropEnumeration.CO025;
    case UploadDocumentIdsEnumeration.MAIN_BANK_ACCOUNT:
    default:
      return DocumentToUploadTypePropEnumeration.CO058;
  }
};

export const validateFile = async ({
  file,
  creditLimitContext,
  uploadDocumentHandler,
  handleShowInvalidFormatErrorTracking,
  handleShowOversizedErrorTracking,
  ...rest
}: ValidateFileType): Promise<void> => {
  if (!file) return;

  const { step4, setStep4 } = creditLimitContext;
  const wrongSize = isFileBiggerThan10MB(file);
  const wrongFormat = isFileWeirdFormat(file);

  if (wrongSize || wrongFormat) {
    let errorKeyTitle: string;
    let errorKeyMessage: string;

    if (wrongSize) {
      handleShowOversizedErrorTracking(rest.id);
      errorKeyTitle = uploadDocumentTranlations.OVERSIZED;
      errorKeyMessage = uploadDocumentTranlations.OVERSIZED_MESSAGE;
    } else {
      handleShowInvalidFormatErrorTracking(rest.id);
      errorKeyTitle = uploadDocumentTranlations.INVALID_FORMAT;
      errorKeyMessage = uploadDocumentTranlations.INVALID_FORMAT_MESSAGE;
    }

    setStep4({
      ...step4,
      documentsInfo: {
        ...step4.documentsInfo,
        [rest.id]: {
          ...step4.documentsInfo?.[rest.id],
          hasSystemError: true,
          errorKeyTitle,
          errorKeyMessage,
        },
      },
    });
    return;
  }

  return uploadDocumentHandler({ file, creditLimitContext, ...rest });
};

export const getMonthlyPaysheet = (
  id: UploadDocumentIdsEnumeration
): MonthlyPaysheetEnumerations | undefined => {
  switch (id) {
    case UploadDocumentIdsEnumeration.LAST_SALARY:
      return MonthlyPaysheetEnumerations.N1;
    case UploadDocumentIdsEnumeration.PREVIOUS_SALARY:
      return MonthlyPaysheetEnumerations.N2;
    case UploadDocumentIdsEnumeration.BEFORE_PREVIOUS_SALARY:
      return MonthlyPaysheetEnumerations.N3;
    default:
      return undefined;
  }
};
