// Vendors
import React from 'react';
// Components
import { ListComponent, ParagraphComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Hooks
import { useFinancingInsurancesContractingLPIAdditionalCovered } from './hooks/financing-insurances-contracting-lpi-additional-covered.hook';
import useCbIntl from 'hooks/useCbIntl';
// Styles
import {
  FinancingInsurancesContractingLPIAdditionalGroupStyled,
  FinancingInsurancesContractingLPIAdditionalListStyled,
} from '../../financing-insurances-contracting-lpi-additional.component.styled';
// Translations
import {
  TITLE,
  DESCRIPTION,
  COVERED_LIST_FIRST,
  COVERED_LIST_SECOND,
  COVERED_LIST_THIRD,
  SECOND_DESCRIPTION,
} from './translations/financing-insurances-contracting-lpi-additional-covered.translations';
// Utils
import { makeChunkLinkIntlUtil } from 'hooks/useCbIntl/utils/useCbIntl.utils';

export const FinancingInsurancesContractingLPIAdditionalCoveredComponent =
  (): React.ReactElement => {
    const { formatMessage } = useCbIntl();
    const { handleLeafletLinkClick } = useFinancingInsurancesContractingLPIAdditionalCovered();

    return (
      <FinancingInsurancesContractingLPIAdditionalGroupStyled>
        <ParagraphComponent bold>
          <FormattedMessageComponent id={TITLE} />
        </ParagraphComponent>
        <FinancingInsurancesContractingLPIAdditionalListStyled>
          <ParagraphComponent>
            <FormattedMessageComponent id={DESCRIPTION} />
          </ParagraphComponent>
          <ListComponent
            items={[
              { label: formatMessage({ id: COVERED_LIST_FIRST }) },
              { label: formatMessage({ id: COVERED_LIST_SECOND }) },
              { label: formatMessage({ id: COVERED_LIST_THIRD }) },
            ]}
          />
        </FinancingInsurancesContractingLPIAdditionalListStyled>
        <ParagraphComponent>
          <FormattedMessageComponent
            id={SECOND_DESCRIPTION}
            values={{
              br: <br />,
              leafletLink: chunk =>
                makeChunkLinkIntlUtil({
                  chunk,
                  onClick: handleLeafletLinkClick,
                }),
            }}
          />
        </ParagraphComponent>
      </FinancingInsurancesContractingLPIAdditionalGroupStyled>
    );
  };
