// Vendors
import { useState } from 'react';
// Contexts
import { useWizardContextConsumerHook } from 'components/wizard/contexts/wizard.context';
// Handlers
import { FinancingInsurancesContractingLPIStep3Handlers } from '../handlers/financing-insurances-contracting-lpi-step3.handlers';
import { FinancingInsurancesContractingLPIStep3TrackingHandlers } from '../handlers/financing-insurances-contracting-lpi-step3.handlers.tracking';
// Hooks
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { FinancingInsurancesContractingLPIStep3ReturnHookType } from './types/financing-insurances-contracting-lpi-step3-return.hook.type';

export const useFinancingInsurancesContractingLPIStep3 =
  (): FinancingInsurancesContractingLPIStep3ReturnHookType => {
    const [downloaded, setDownloaded] = useState<boolean>(false);
    const [downloadedError, setDownloadedError] = useState<boolean>(false);
    const [docsReceived, setDocsReceived] = useState<boolean>(false);
    const [docsReceivedError, setDocsReceivedError] = useState<boolean>(false);

    const { setCurrentStep } = useWizardContextConsumerHook();

    return {
      ...FinancingInsurancesContractingLPIStep3Handlers({
        ...AppTrackingHook(FinancingInsurancesContractingLPIStep3TrackingHandlers),
        docsReceived,
        setCurrentStep,
        setDocsReceived,
        setDocsReceivedError,
        setDownloaded,
        downloaded,
        setDownloadedError,
      }),
      docsReceived,
      docsReceivedError,
      downloadedError,
    };
  };
