const ENTER_STEP_DEFAULT_PAYLOAD = {
  sectionFamily: 'banking',
  sectionCategory: 'services',
  sectionName: 'insurance',
  interactionType: 'funnel',
  serviceType: 'serviceSettings',
  processType: 'LPIContracting',
  processDetail: 'insuranceInformation',
};

export { ENTER_STEP_DEFAULT_PAYLOAD };
