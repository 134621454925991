// Vendors
import useCbIntl from 'hooks/useCbIntl';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
import { ManagerContextConsumerHook } from 'contexts/manager.context';
// Enumerations
import { TransactionsFetchErrorEnumeration } from 'enumerations/transactions-fetch-error.enumeration';
// Event handlers
import { TransactionsTableHandlers } from '../handlers/transactions-table.handlers';
// Hooks
import useErrorWrapperHook from 'components/error-wrapper/hooks/error-wrapper.hook';
import useFinancingErrorButtonHook from './financing-error-button.hook';
import { useFiltersContextConsumerHook } from 'components/filters/context/filters.context';
// Types
import { TransactionsTableBuilderHookType } from 'organisms/table-organism/components/transactions-table/hooks/types/transactions-table-builder.hook.type';
import { TransactionsTableHookType } from 'organisms/table-organism/components/transactions-table/hooks/types/transactions-table.hook.type';
import { CardTransactionType } from 'types/card-transaction.type';
import { TransactionsTableMetadataComponentType } from 'types/card-transactions-metadata.type';
import { ChallengeType } from 'types/challenge.type';
// Utils
import {
  hasTransactionsChallengeSuccess,
  isNotDashboardRoute,
} from '../utils/transactions-table.utils';

export const TransactionsTableHook = ({
  error: wrapperError = null,
  resolver,
  transactions,
  idName,
}: TransactionsTableBuilderHookType): TransactionsTableHookType => {
  const { currentCard } = AppContextConsumerHook(),
    { id: cardId = '' } = currentCard || {};

  const { formatMessage } = useCbIntl();
  const { setError: setErrorManager } = ManagerContextConsumerHook();
  const location = useLocation();
  const navigate = useNavigate();

  const { elements: tElements = [], metadata: tMetadata = {} } = transactions || {};
  const [fetching, setFetching] = useState<boolean>(false);
  const [fetchingDownload, setFetchingDownload] = useState<boolean>(false);
  const [error, setError] = useState<TransactionsFetchErrorEnumeration | null>(wrapperError);
  const [elements, setElements] = useState<CardTransactionType[] | []>(tElements as any);
  const [metadata, setMetadata] = useState<
    TransactionsTableMetadataComponentType | Record<string, any>
  >(tMetadata);
  const [challenge, setChallenge] = useState<ChallengeType | null>(null);
  const [challenged, setChallenged] = useState<boolean>(false);
  const [challengeError, setChallengeError] = useState<boolean>(false);
  const [showSecurityCheckModal, setShowSecurityCheckModal] = useState<boolean>(false);
  const [showDownloadSecurityCheckModal, setShowDownloadSecurityCheckModal] =
    useState<boolean>(false);
  const [isDownloadAvailable, setIsDownloadAvailable] = useState<boolean>(false);
  const [otpId, setOtpId] = useState<string>(challenge?.otpId || '');
  const [otpValue, setOtpValue] = useState<string>('');
  const [downloadDocumentType, setDownloadDocumentType] = useState<string>('');

  const { handleNavigateToTransactionsViewButtonClick } = useFinancingErrorButtonHook();
  const { formValuesParams, formValues, setFormValuesParams, setFormValues, setCopyOfFormValues } =
    useFiltersContextConsumerHook();
  const { handleTryAgainButtonClick } = useErrorWrapperHook();

  const {
    handleDownloadTransactions,
    handleFetchCardTransactions,
    handleHideSecurityCheckModal,
    handleResetTransactions,
    handleSetOutProvidingErrorEvent,
    handleShowSecurityCheckModal,
  } = TransactionsTableHandlers({
    cardId,
    challenge,
    formValuesParams,
    idName,
    metadata,
    resolver,
    setChallenge,
    setChallengeError,
    setElements,
    setError,
    setErrorManager,
    setFetching,
    setIsDownloadAvailable,
    setMetadata,
    setShowDownloadSecurityCheckModal,
    setShowSecurityCheckModal,
    wrapperError,
  });

  useEffect(() => {
    resolver && handleResetTransactions();
    if (location.state?.fetchTransactions) {
      setShowSecurityCheckModal(true);
      navigate(location.pathname, { replace: true });
    }
  }, [formValuesParams, showDownloadSecurityCheckModal]);

  useEffect(() => {
    handleSetOutProvidingErrorEvent();
  }, [wrapperError]);

  useEffect(() => {
    if (isNotDashboardRoute() && !showSecurityCheckModal && hasTransactionsChallengeSuccess()) {
      handleResetTransactions();
    }
  }, [showSecurityCheckModal, hasTransactionsChallengeSuccess]);

  useEffect(() => {
    if (otpId !== challenge?.otpId) {
      setOtpId(challenge?.otpId || '');
    }
  }, [challenge]);

  return {
    cardId,
    challenge,
    challenged,
    challengeError,
    downloadDocumentType,
    elements,
    error,
    fetching,
    fetchingDownload,
    formatMessage,
    formValues,
    formValuesParams,
    handleDownloadTransactions,
    handleFetchCardTransactions,
    handleHideSecurityCheckModal,
    handleNavigateToTransactionsViewButtonClick,
    handleShowSecurityCheckModal,
    handleTryAgainButtonClick,
    isDownloadAvailable,
    metadata,
    navigate,
    otpId,
    otpValue,
    progressId: challenge?.progressId || '',
    setChallenge,
    setChallenged,
    setChallengeError,
    setCopyOfFormValues,
    setDownloadDocumentType,
    setError,
    setFetchingDownload,
    setFormValues,
    setFormValuesParams,
    setIsDownloadAvailable,
    setOtpId,
    setOtpValue,
    setShowDownloadSecurityCheckModal,
    setShowSecurityCheckModal,
    showDownloadSecurityCheckModal,
    showSecurityCheckModal,
  };
};
