// Vendors
import React from 'react';
// Components
import WizardStepFooterComponent from 'components/wizard-step-footer/wizard-step-footer.component';
// Constants
import { FIRST_STEP_TARGETS } from 'constants/wizard/wizard.constants';
// Hooks
import { AccessPortabilityContextConsumerHook } from 'containers/personal/views/access-portability/contexts/access-portability.context';
// Utils
import { isPortabilityNextButtonDisabled } from './utils/request-portability-step1-actions.utils';

const RequestPortabilityStep1ActionsComponent = (): React.ReactElement => {
  const { portabilityParams } = AccessPortabilityContextConsumerHook();

  const hasDataModified = !isPortabilityNextButtonDisabled({
    portabilityParams,
  });

  return (
    <WizardStepFooterComponent
      shouldShowModal={hasDataModified}
      rightButtonConfiguration={{
        disabled: !hasDataModified,
      }}
      targets={FIRST_STEP_TARGETS}
    />
  );
};

export default RequestPortabilityStep1ActionsComponent;
