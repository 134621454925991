// Enumerations
import { RoutesEnumeration } from 'enumerations/routes.enumeration';
// Types
import { FinancingInsurancesContractingLPIStep2PlansErrorHandlersType } from './types/financing-insurances-contracting-lpi-step2-plans-error.handlers.type';
import { FinancingInsurancesContractingLPIStep2PlansErrorReturnHandlersType } from './types/financing-insurances-contracting-lpi-step2-plans-error-return.handlers.type';
// Utils
import { generateAbsoluteRoute, generatePathRoute } from 'utils/app-routing.utils';

const creditLimitIncreaseLinkHandler = ({
  handleCreditLimitIncreaseLinkClickTracking,
  navigate,
}: FinancingInsurancesContractingLPIStep2PlansErrorHandlersType): void => {
  handleCreditLimitIncreaseLinkClickTracking();
  navigate(
    generateAbsoluteRoute(
      generatePathRoute([RoutesEnumeration.FINANCING, RoutesEnumeration.CREDIT_LIMIT_INCREASE])
    )
  );
};

export const FinancingInsurancesContractingLPIStep2PlansErrorHandlers = (
  props: FinancingInsurancesContractingLPIStep2PlansErrorHandlersType
): FinancingInsurancesContractingLPIStep2PlansErrorReturnHandlersType => ({
  handleCreditLimitIncreaseLinkClick: () => creditLimitIncreaseLinkHandler(props),
});
